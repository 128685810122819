import axios from 'axios';
import { PublicClientApplication, SilentRequest } from '@azure/msal-browser';
import { getToken } from 'utils/getToken';

const configureAxios = (msalInstance: PublicClientApplication) => {
  axios.interceptors.request.use((request) => {
    const token = getToken(request.url);

    if (token) {
      request.headers['Authorization'] = `Bearer ${token}`;
    }
    return request;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        // If the token is invalid, then refresh it silently
        const silentRequest: SilentRequest = {
          scopes: ['user.read'],
          account: msalInstance.getActiveAccount() || undefined,
        };
        msalInstance.acquireTokenSilent(silentRequest).then((res) => {
          if (res && res.account) {
            sessionStorage.setItem('idToken', res.idToken);
            msalInstance.setActiveAccount(res.account);
          }
        });
        return axios(error.config);
      } else {
        throw error;
      }
    }
  );
};

export default configureAxios;
