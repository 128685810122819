import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import LABELS from 'app/labels';
import { ProjectManagementModes } from './Constants/ProjectManagementModes';
import { ProjectData } from './Types/ProjectManagementTypes';
import UserProfile from 'components/common/UserProfile';
import Button from 'components/common/Button';
import AddProject from 'components/ProjectManagement/ProjectComponents/AddProject';
import Unauthorized from 'components/common/Unauthorized';
import ViewOrEditProject from 'components/ProjectManagement/ProjectComponents/ViewOrEditProject';
import AddSkills from 'components/ProjectManagement/ProjectComponents/AddSkills';
import ViewOrEditSkill from 'components/ProjectManagement/ProjectComponents/ViewOrEditSkill';
import { fetchUserInfo } from 'redux/services/employeeDirectory/employeeDirectoryService';
import {
  getProjectData,
  getSkillData,
} from 'redux/services/projectManagement/projectManagementService';
import { enableFeature, isAdmin } from 'utils/fetchSessionInfo';
import './ProjectManagement.scss';
import { SkillDetails } from './Types/SkillManagementTypes';

const ProjectsManagement = () => {
  const {
    PROJECT_MANAGEMENT: { PROJECTS_LABEL, ADD_PROJECT_LABEL },
  } = LABELS;
  const { instance } = useMsal();
  const { VIEW, ADD, ADD_SKILLS, VIEW_OR_EDIT_SKILLS } = ProjectManagementModes;
  const userName = instance.getActiveAccount()?.name;
  const [blobUrl, setBlobUrl] = useState('');
  const [projectMode, setProjectMode] = useState(VIEW);
  const [projectData, setProjectData] = useState<ProjectData[]>([]);
  const [skillData, setSkillData] = useState<SkillDetails[]>([]);

  const fetchUserInformation = async () => {
    const blobObj = await fetchUserInfo();
    if (blobObj) {
      const url = URL.createObjectURL(blobObj);
      setBlobUrl(url);
    }
  };

  const loadProjects = () => {
    getProjectData().then((projectResponse) =>
      setProjectData([...projectResponse])
    );
  };

  const loadSkills = () => {
    getSkillData().then((skillResponse) => setSkillData([...skillResponse]));
  };

  useEffect(() => {
    fetchUserInformation();
    if (enableFeature(process.env.REACT_APP_ENV) || isAdmin()) {
      loadProjects();
    }
  }, []);

  const handleAddProject = () => {
    setProjectMode(ADD);
  };

  const handleAddSkills = () => {
    setProjectMode(ADD_SKILLS);
  };

  const handleViewSkills = () => {
    loadSkills();
    setProjectMode(VIEW_OR_EDIT_SKILLS);
  };

  const handleRenderViewPage = () => {
    setProjectMode(VIEW);
  };

  const handleReload = () => {
    loadProjects();
    setProjectMode(VIEW);
  };

  const handleSkillsReload = () => {
    loadProjects();
    setProjectMode(VIEW_OR_EDIT_SKILLS);
  };

  const renderProjectComponent = (projectMode: string) => {
    switch (projectMode) {
      case ADD:
        return (
          <AddProject
            projectData={projectData}
            renderViewPage={handleRenderViewPage}
            onReload={handleReload}
          />
        );
      case ADD_SKILLS:
        return (
          <AddSkills
            skillData={skillData}
            onReload={handleSkillsReload}
            renderViewPage={handleViewSkills}
          />
        );
      case VIEW_OR_EDIT_SKILLS:
        return (
          <ViewOrEditSkill
            skillData={skillData}
            onReload={handleSkillsReload}
          />
        );
      default:
        return (
          <ViewOrEditProject
            projectData={projectData}
            onReload={handleReload}
          />
        );
    }
  };

  const renderHeaderText = (projectMode: string) => {
    switch (projectMode) {
      case ADD:
        return ADD_PROJECT_LABEL;
      case ADD_SKILLS:
        return 'Add Skills';
      case VIEW_OR_EDIT_SKILLS:
        return 'Skills';
      default:
        return PROJECTS_LABEL;
    }
  };

  return (
    <div className="projectManagementContainer">
      <div className="projectManagementContainer__header">
        <div className="projectManagementContainer__header__leftblock">
          <div className="projectManagementContainer__header-title">
            {renderHeaderText(projectMode)}
          </div>
          {(enableFeature(process.env.REACT_APP_ENV) || isAdmin()) &&
            projectMode === VIEW && (
              <>
                <Button
                  className="projectManagementContainer__header__leftblock__add-project"
                  value="Add Project"
                  isShowIcon
                  onClick={handleAddProject}
                />
                <Button
                  className="projectManagementContainer__header__leftblock__add-skills"
                  value="View Skills"
                  isSecondary
                  onClick={handleViewSkills}
                />
              </>
            )}
          {(enableFeature(process.env.REACT_APP_ENV) || isAdmin()) &&
            projectMode === VIEW_OR_EDIT_SKILLS && (
              <>
                <Button
                  className="projectManagementContainer__header__leftblock__add-skills"
                  value="Add Skills"
                  isShowIcon
                  onClick={handleAddSkills}
                />
                <Button
                  className="projectManagementContainer__header__leftblock__add-skills"
                  value="View Projects"
                  isSecondary
                  onClick={handleRenderViewPage}
                />
              </>
            )}
        </div>
        <UserProfile blobUrl={blobUrl} userName={userName} />
      </div>

      {enableFeature(process.env.REACT_APP_ENV) || isAdmin() ? (
        renderProjectComponent(projectMode)
      ) : (
        <Unauthorized />
      )}
    </div>
  );
};

export default ProjectsManagement;
