/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import { AddSkillProps } from 'components/ProjectManagement/Types/SkillManagementProps';
import InputBox from 'components/common/InputBox';
import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import PageHeader from 'components/AddOrEditEmployee/TabPages/PageHeader';
import LABELS from 'app/labels';
import Loader from 'components/common/Loader';
import { SkillDetails } from 'components/ProjectManagement/Types/SkillManagementTypes';
import { InitialSelectedDropDownvalue } from 'components/ProjectManagement/Constants/ProjectManagementConstants';
import { Filter } from 'components/common/Dropdown/DropdownType';
import { addSkill } from 'redux/services/projectManagement/projectManagementService';
import './AddSkills.scss';

const AddSkills: FC<AddSkillProps> = ({
  skillData,
  onReload,
  renderViewPage,
}) => {
  const [skillCategory, setSkillCategory] = useState<Filter>({} as Filter);
  const [skillCategoryList, setSkillCategoryList] = useState<Filter[]>([]);
  const [skillName, setSkillName] = useState('');
  const [skillList, setSkillList] = useState<SkillDetails[]>([]);

  useEffect(() => {
    const category = skillData
      .filter(
        (skill, index, self) =>
          index ===
          self.findIndex((item) => item.skillCategory === skill.skillCategory)
      )
      .map((filteredProject) => ({
        id: filteredProject.id,
        name: filteredProject.skillCategory,
      }));
    setSkillCategoryList(category);
  }, [skillData]);

  const {
    PROJECT_MANAGEMENT: {
      SUBMIT_BTN_LABEL,
      CANCEL_BTN_LABEL,
      ADD_SKILL_LABEL,
      CATEGORY,
      CATEGORY_PLACEHOLDER,
      SKILL,
      SKILL_PLACEHOLDER,
    },
  } = LABELS;

  const titleCase = (keyName: string) =>
    keyName
      .toLowerCase()
      .split(' ')
      .map((word, index) => {
        if (index === 0) return word;

        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');

  const handleRemoveSkill = (id: string) => {
    const skillsList = skillList;
    const updatedSkillList = skillsList?.filter((skill) => skill.id !== id);
    setSkillList(updatedSkillList);
  };

  const handleAddSkill = () => {
    const newSkillList = [...skillList];
    newSkillList.push({
      id: `skill-${skillData.length + skillList.length + 1}`,
      name: skillName,
      skillCategory: skillCategory.name,
      skillKey: titleCase(skillName),
    });

    setSkillList(newSkillList);
  };

  const renderSkillDetails = () => (
    <div className="addSkillContainer__body__skill-section">
      <Dropdown
        id="category-dropdown"
        inputName="category-dropdown"
        disabledDropdown={false}
        showLabel
        showSelectedOptionsCount={false}
        useFormStyles
        labelText={CATEGORY}
        placeholder={CATEGORY_PLACEHOLDER}
        list={skillCategoryList}
        filterLabel={skillCategory?.name ?? ''}
        prefillData={[skillCategory]}
        onSelect={(category) => setSkillCategory(category[0])}
      />
      <InputBox
        disabled={false}
        type="text"
        id="skillName"
        name="skillName"
        showLabel={true}
        labelText={SKILL + '*'}
        placeholder={SKILL_PLACEHOLDER}
        showIcon
        iconClassName="user-circular"
        inputVal={skillName}
        onChange={(val) => {
          setSkillName(val.toString());
        }}
      />
    </div>
  );

  const handleSubmit = () => {
    const skillDataBlob = new Blob([JSON.stringify(skillList)], {
      type: 'application/json',
    });

    addSkill(skillDataBlob).then(() => {
      onReload();
    });
  };

  return (
    <div className="addSkillContainer">
      {false && <Loader />}
      <div className="addSkillContainer__body">{renderSkillDetails()}</div>
      <div className="addSkillContainer__footer">
        <Button
          className="addSkillContainer__footer__btn-add-skill"
          isShowIcon
          iconClassName="icon-plus"
          onClick={handleAddSkill}
          value={ADD_SKILL_LABEL}
          isSecondary
          data-testid="add-skills-button"
        />
      </div>

      <div className="addSkillContainer__projectList">
        <PageHeader
          title="Selected Skills"
          subtitle="All selected skills should have unique Skill Name"
        />
        <div className="addSkillContainer__projectList__table">
          <table>
            <thead>
              <tr>
                <th>S No.</th>
                <th>Skill Name</th>
                <th>Skill Category</th>
                <th>Skill Icon</th>
                <th>Skill Key</th>
                <th>Action</th>
              </tr>
            </thead>
            {skillList && skillList.length > 0 && (
              <tbody>
                {skillList.map((skill) => (
                  <tr key={skill.id}>
                    <td>{skill.id}</td>
                    <td>{skill.name}</td>
                    <td>{skill.skillCategory}</td>
                    <td>{skill.skillIconImage}</td>
                    <td>{skill.skillKey}</td>
                    <td>
                      <Button
                        className="addSkillContainer__projectList__table-botton"
                        isShowIcon
                        iconClassName="icon-cross"
                        onClick={() => handleRemoveSkill(skill.id)}
                        value="Remove"
                        isSecondary
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {skillList.length < 1 && (
            <div className="addSkillContainer__projectList__no-skills-selected">
              No Skills Added
            </div>
          )}
        </div>
      </div>
      <div className="addSkillContainer__footer">
        <Button
          className="addSkillContainer__footer__btn-cancel"
          value={CANCEL_BTN_LABEL}
          onClick={renderViewPage}
          isSecondary
          data-testid="card-body-buttons-cancel"
        />
        <Button
          className="addSkillContainer__footer__btn-next"
          value={SUBMIT_BTN_LABEL}
          onClick={handleSubmit}
          data-testid="card-body-buttons-submit"
          disabled={!(skillList.length > 0)}
        />
      </div>
    </div>
  );
};

export default AddSkills;
