import { FC, useState } from 'react';
import Modal from 'react-modal';
import { useAppDispatch } from 'types/hooks';
import { BulkUploaderProps } from './BulkUploaderType';
import FileUploader from 'components/common/FileUploader';
import Button from 'components/common/Button';
import {
  getEmployeesExcelTemplate,
  postEmployeeExcelSheet,
} from 'redux/services/employeeDirectory/employeeDirectoryService';
import LABELS from 'app/labels';
import './BulkUploader.scss';

const BulkUploader: FC<BulkUploaderProps> = ({ isOpen, closeModal }) => {
  const {
    ADD_OR_EDIT_EMPLOYEE: { DEFAULT_ERROR_MESSAGE },
  } = LABELS;
  const dispatch = useAppDispatch();
  const [uploadData, setUploadData] = useState<File[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [uploadStatus, setUploadStatus] = useState<
    'initial' | 'loading' | 'success' | 'failed'
  >('initial');

  const handleFileSelection = (file: File | null) => {
    if (file) {
      setUploadData([file]);
    } else {
      setUploadData([]);
      setUploadStatus('initial');
    }
  };

  const handleUpload = async () => {
    try {
      if (uploadData.length > 0) {
        setUploadStatus('loading');
        const formData = new FormData();

        uploadData.forEach((file) => {
          formData.append(`file`, file);
        });

        await postEmployeeExcelSheet(formData);
        setUploadStatus('success');
      }
    } catch (err: any) {
      setUploadStatus('failed');
      const errorMessage =
        err?.response?.data?.message || DEFAULT_ERROR_MESSAGE;
      setErrorMessage(errorMessage);
    }
  };

  const handleDownloadTemplate = () => {
    dispatch(getEmployeesExcelTemplate());
  };

  const goBackToUpload = () => {
    setUploadStatus('initial');
  };

  const redirectToEmpDetails = () => {
    window.location.reload();
  };

  const Header: FC<{
    title: string;
    closeModal: () => void;
    subtitle?: string;
  }> = ({ title, closeModal, subtitle }) => (
    <div className="bulkUploaderContainer__initial__header">
      <div className="bulkUploaderContainer__initial__header__title">
        {title}
        {subtitle && (
          <div className="bulkUploaderContainer__initial__header__subtitle">
            {subtitle}
          </div>
        )}
      </div>
      <span
        className="bulkUploaderContainer__initial__header__icon icon-cross"
        onClick={closeModal}
      />
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      className="bulkUploaderContainer"
      overlayClassName="bulkUploaderOverlay"
    >
      {uploadStatus === 'initial' && (
        <div className="bulkUploaderContainer__initial">
          <Header
            title="Bulk Employees Upload"
            closeModal={closeModal}
            subtitle="Attach and Upload Employee Data"
          />
          <FileUploader
            className="bulkUploaderContainer__initial__fileUploader"
            handleFileSelection={(file) =>
              handleFileSelection(file ? file : null)
            }
            id="bulkUploader"
            description="Select a File"
            labelTitleText=""
            labelFileText="xlsx,csv (1 File)"
            iconClassName="icon-excel"
          />
          <div className="bulkUploaderContainer__initial__steps">
            Step 1: Download the&nbsp;
            <Button
              className="bulkUploaderContainer__initial__steps__link"
              value="Sample Excel Template"
              onClick={handleDownloadTemplate}
              isButtonLink
            />
            {'\n'}
            Step 2: Select the updated file on the upload area above.
            {'\n'}
            Step 3: Submit and wait for upload confirmation
          </div>
          <Button
            className="bulkUploaderContainer__initial__button"
            onClick={handleUpload}
            value="Submit"
            disabled={!uploadData?.length}
          />
        </div>
      )}
      {uploadStatus === 'loading' && (
        <div className="bulkUploaderContainer__loading">
          <Header
            title="Bulk Employees Upload"
            closeModal={closeModal}
            subtitle="File Processing"
          />
          <div className="bulkUploaderContainer__loading__loader icon-loader data-testid='loader'" />
          <div className="bulkUploaderContainer__loading__description">
            Your File is processing.
            <br />
            Please do not close this window.
          </div>
        </div>
      )}
      {uploadStatus === 'success' && (
        <div className="bulkUploaderContainer__success">
          <Header title="Bulk Employees Upload" closeModal={closeModal} />
          <div className="bulkUploaderContainer__success__checkmark icon-checkmark" />
          <div className="bulkUploaderContainer__success__description">
            <span className="bulkUploaderContainer__success__description__success-text">
              Success!
            </span>
            <br />
            Your file has been proccessed.
            <br />
            You can review the data in the Employee details screen.
          </div>
          <div className="bulkUploaderContainer__success__button">
            <Button
              className="bulkUploaderContainer__success__button__close"
              onClick={closeModal}
              value="Close"
            />
            <Button
              className="bulkUploaderContainer__success__button__go-to-employee"
              onClick={redirectToEmpDetails}
              value="Go to Employee Details"
            />
          </div>
        </div>
      )}
      {uploadStatus === 'failed' && (
        <div className="bulkUploaderContainer__failed">
          <Header title="Bulk Employees Upload" closeModal={closeModal} />
          <div className="bulkUploaderContainer__failed__cross icon-cross_with_circle" />
          <div className="bulkUploaderContainer__failed__description">
            <span className="bulkUploaderContainer__failed__description__failed-text">
              Failed!
              <br />
              Your file could not be processed.
            </span>
            <br />
            <div className="bulkUploaderContainer__failed__description__error-text">
              Errors:
              <div>{errorMessage}</div>
            </div>
            You can try uploading a new file with corrections and formatting.
          </div>
          <div className="bulkUploaderContainer__failed__button">
            <Button
              className="bulkUploaderContainer__failed__button__close"
              onClick={closeModal}
              value="Close"
            />
            <Button
              className="bulkUploaderContainer__failed__button__go-to-employee"
              onClick={goBackToUpload}
              value="Go back to Upload Section"
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default BulkUploader;
