import { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'types/hooks';
import { selectFilterData } from 'redux/slices/employeeDirectory/employeeDirectorySlice';
import LABELS from 'app/labels';
import Dropdown from 'components/common/Dropdown';
import DatePickerForm from 'components/common/DatePicker';
import InputBox from 'components/common/InputBox';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import { ViewOrEditProjectProps } from 'components/ProjectManagement/Types/ProjectManagementProps';
import { InitialSelectedDropDownvalue } from 'components/ProjectManagement/Constants/ProjectManagementConstants';
import { editProject } from 'redux/services/projectManagement/projectManagementService';
import { Filter } from 'components/common/Dropdown/DropdownType';
import './ViewOrEditProject.scss';

const ViewOrEditProject: FC<ViewOrEditProjectProps> = ({
  projectData,
  onReload,
}) => {
  const {
    PROJECT_MANAGEMENT: {
      SUBMIT_BTN_LABEL,
      EDIT_PROJECT_BTN_LABEL,
      CANCEL_BTN_LABEL,
      OZ_LINK,
      INDUSTRY,
      PROJECT,
      PROJECT_MANAGER,
      START_DATE,
      END_DATE,
      CLIENT,
      CLIENT_PLACEHOLDER,
      PROJECT_PLACEHOLDER,
      INDUSTRY_PLACEHOLDER,
      OZ_LINK_PLACEHOLDER,
      PROJECT_MANAGER_PLACEHOLDER,
    },
  } = LABELS;
  const [clientNames, setClientNames] = useState<Filter[]>();
  const [selectedClientName, setSelectedClientName] = useState<Filter>(
    InitialSelectedDropDownvalue
  );
  const [projectNames, setProjectNames] = useState<Filter[]>();
  const [selectedProjectName, setSelectedProjectName] = useState<Filter>(
    InitialSelectedDropDownvalue
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date>();
  const [selectedEndDate, setSelectedEndDate] = useState<Date>();
  const [selectedOZLink, setSelectedOZLink] = useState<string>();
  const [selectedProjectManager, setSelectedProjectManager] =
    useState<string>();
  const { industries } = useAppSelector(selectFilterData) || {};
  const [selectedIndustry, setSelectedIndustry] = useState<Filter>(
    InitialSelectedDropDownvalue
  );
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const clientName = projectData
      .filter(
        (project, index, self) =>
          index ===
          self.findIndex((item) => item.clientName === project.clientName)
      )
      .map((filteredProject) => ({
        id: filteredProject.clientName,
        name: filteredProject.clientName,
      }));
    setClientNames(clientName);
  }, [projectData]);

  const handleSelectClient = (clientName: Filter) => {
    setSelectedClientName(clientName);
    clearSelections();

    const projects = projectData
      .filter((project) => project.clientName === clientName.name)
      .map((filteredProject) => ({
        id: filteredProject.projectName,
        name: filteredProject.projectName,
      }));
    setProjectNames([...projects]);
  };

  const handleSelectProject = (projectName: Filter) => {
    setSelectedProjectName(projectName);
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setSelectedOZLink('');
    setSelectedProjectManager('');
    setSelectedIndustry(InitialSelectedDropDownvalue);
    projectData
      .filter((project) => project.projectName === projectName.name)
      .forEach((filteredProject) => {
        setSelectedStartDate(new Date(filteredProject.startDate));
        setSelectedEndDate(new Date(filteredProject.endDate));
        setSelectedProjectManager(filteredProject.projectManager);
        setSelectedOZLink(filteredProject.ozLink);
        setSelectedIndustry({
          id: filteredProject.industry,
          name: filteredProject.industry,
        });
      });
  };

  const handleSelectIndustry = (industry: Filter) => {
    setSelectedIndustry({
      id: industry.id,
      name: industry.name,
    });
  };

  const clearSelections = () => {
    setSelectedStartDate(undefined);
    setSelectedEndDate(undefined);
    setSelectedOZLink('');
    setSelectedProjectManager('');
    setSelectedIndustry(InitialSelectedDropDownvalue);
    setSelectedProjectName(InitialSelectedDropDownvalue);
    setProjectNames([]);
  };

  const handleCancel = () => {
    clearSelections();
    setSelectedClientName(InitialSelectedDropDownvalue);
    setIsEditModeOn(false);
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const requestPayload = {
      clientName: selectedClientName.name,
      projectName: selectedProjectName.name,
      projectManager: selectedProjectManager ?? '',
      ozLink: selectedOZLink ?? '',
      startDate: selectedStartDate?.toLocaleDateString('en-GB') ?? '',
      endDate: selectedEndDate?.toLocaleDateString('en-GB') ?? '',
      industry: selectedIndustry.name,
    };
    const projectDataBlob = new Blob([JSON.stringify(requestPayload)], {
      type: 'application/json',
    });
    editProject(projectDataBlob).then(() => {
      setIsSubmitted(false);
      handleCancel();
      onReload();
    });
  };

  const renderClientAndProject = () => (
    <div className="viewOrEditProjectContainer__body__client-section">
      <Dropdown
        id="client-dropdown"
        inputName="client-dropdown"
        disabledDropdown={false}
        showLabel
        showSelectedOptionsCount={false}
        useFormStyles
        labelText={CLIENT}
        placeholder={CLIENT_PLACEHOLDER}
        list={clientNames}
        filterLabel={selectedClientName?.name ?? ''}
        prefillData={[selectedClientName]}
        onSelect={(clientName) => handleSelectClient(clientName[0])}
      />
      <div className="viewOrEditProjectContainer__body__client-section__date-picker">
        <DatePickerForm
          id="startDate"
          name="startDate"
          disabled={!isEditModeOn}
          className="remove-margin"
          showLabel
          labelText={START_DATE}
          placeholder={START_DATE}
          value={selectedStartDate}
          onChange={(startDate) => startDate && setSelectedStartDate(startDate)}
        />
        <DatePickerForm
          id="leavingDate"
          name="leavingDate"
          disabled={!isEditModeOn}
          className="remove-margin"
          showLabel
          labelText={END_DATE}
          placeholder={END_DATE}
          value={selectedEndDate}
          onChange={(endDate) => endDate && setSelectedEndDate(endDate)}
        />
      </div>
    </div>
  );

  const renderProjectDetails = () => (
    <div className="viewOrEditProjectContainer__body__project-section">
      <Dropdown
        id="project-dropdown"
        inputName="project-dropdown"
        disabledDropdown={selectedClientName.name === ''}
        showLabel
        showSelectedOptionsCount={false}
        useFormStyles
        labelText={PROJECT}
        placeholder={PROJECT_PLACEHOLDER}
        list={[...(projectNames ?? [])]}
        filterLabel={selectedProjectName?.name ?? ''}
        prefillData={[selectedProjectName]}
        onSelect={(projectName) => handleSelectProject(projectName[0])}
      />
      <div className="viewOrEditProjectContainer__body__project-section__input">
        <InputBox
          disabled={selectedProjectName.name !== '' || !isEditModeOn}
          type="text"
          id="managerName"
          name="managerName"
          className="remove-margin"
          showLabel
          labelText={PROJECT_MANAGER}
          placeholder={PROJECT_MANAGER_PLACEHOLDER}
          showIcon
          iconClassName="user-circular"
          inputVal={selectedProjectManager}
          onChange={(managerName) => setSelectedProjectManager(managerName)}
        />

        <InputBox
          disabled={selectedProjectName.name !== '' || !isEditModeOn}
          type="text"
          id="ozLink"
          name="ozLink"
          className="remove-margin"
          showLabel
          labelText={OZ_LINK}
          placeholder={OZ_LINK_PLACEHOLDER}
          showIcon
          iconClassName="link"
          inputVal={selectedOZLink}
          onChange={(ozLink) => setSelectedOZLink(ozLink)}
        />
        <Dropdown
          id="industry-drop"
          inputName="industry-drop"
          disabledDropdown={!isEditModeOn}
          showLabel
          showSelectedOptionsCount={false}
          useFormStyles
          labelText={INDUSTRY}
          placeholder={INDUSTRY_PLACEHOLDER}
          list={[...(industries ?? [])]}
          filterLabel={selectedIndustry?.name ?? ''}
          prefillData={[selectedIndustry]}
          onSelect={(industry) => handleSelectIndustry(industry[0])}
        />
      </div>
    </div>
  );

  return (
    <div className="viewOrEditProjectContainer">
      {isSubmitted && <Loader />}
      <div className="viewOrEditProjectContainer__body">
        {renderClientAndProject()}
        {renderProjectDetails()}
      </div>

      <div className="viewOrEditProjectContainer__footer">
        {!isEditModeOn ? (
          <Button
            disabled={selectedProjectName.name === ''}
            className="viewOrEditProjectContainer__footer__btn-next"
            value={EDIT_PROJECT_BTN_LABEL}
            onClick={() => setIsEditModeOn(true)}
            data-testid="card-body-buttons-submit"
          />
        ) : (
          <>
            <Button
              className="viewOrEditProjectContainer__footer__btn-cancel"
              value={CANCEL_BTN_LABEL}
              onClick={handleCancel}
              isSecondary
              data-testid="card-body-buttons-cancel"
            />
            <Button
              className="viewOrEditProjectContainer__footer__btn-next"
              disabled={isSubmitted}
              value={SUBMIT_BTN_LABEL}
              onClick={handleSubmit}
              data-testid="card-body-buttons-submit"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ViewOrEditProject;
