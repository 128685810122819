import { FC, useState } from 'react';
import { AddOrEditHeaderProps } from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import UserProfile from 'components/common/UserProfile';
import BulkUploader from 'components/BulkUploader';
import Button from 'components/common/Button';
import EmployeeStatus from './EmployeeStatus';
import LABELS from 'app/labels';
import './AddOrEditHeader.scss';

const AddOrEditHeader: FC<AddOrEditHeaderProps> = ({
  blobUrl,
  userName,
  isEditMode,
  employeeStatusData,
  setEmployeeStatusData,
}) => {
  const {
    ADD_OR_EDIT_EMPLOYEE: { HEADER, EDIT_HEADER, SUBHEADER, EDIT_SUBHEADER },
  } = LABELS;
  const [isBulkUploadOpen, setIsBulkUploadOpen] = useState(false);

  const handleBulkUploadClose = () => {
    setIsBulkUploadOpen(false);
  };

  return (
    <>
      <div className="directory__header">
        <div className="directory__header__leftblock">
          <div className="directory__header-title">
            {isEditMode ? EDIT_HEADER : HEADER}
          </div>
        </div>
        <UserProfile blobUrl={blobUrl} userName={userName} />
      </div>
      <div className="addoredit-header__subheader">
        {isEditMode ? EDIT_SUBHEADER : SUBHEADER}
        {!isEditMode ? (
          <div style={{ position: 'absolute', right: 20, width: '220px' }}>
            <Button
              className="addoredit-header__bulk-uploader"
              value="Add Bulk Employees"
              onClick={() => setIsBulkUploadOpen(true)}
              isShowIcon
              iconClassName="icon-plus"
              isSecondary
            />
            {isBulkUploadOpen && (
              <BulkUploader
                isOpen={isBulkUploadOpen}
                closeModal={handleBulkUploadClose}
              />
            )}
          </div>
        ) : (
          <EmployeeStatus
            employeeStatusData={employeeStatusData}
            setEmployeeStatusData={setEmployeeStatusData}
          />
        )}
      </div>
    </>
  );
};

export default AddOrEditHeader;
