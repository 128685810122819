import { SkillsProps } from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import {
  EmployeeProjectDetails,
  EmployeeStatusData,
} from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import { IndustryExperience } from 'components/AddOrEditEmployee/Types/SelectedEmployeeDataType';

const DATE_LOCALE = 'en-GB';
const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

export const formatSkillsData = (skillsList: SkillsProps[]) =>
  skillsList.map(({ id, name, level, category }) => ({
    id,
    name,
    skillLevel: level,
    skillType: category,
  }));

export const formatExperience = (years: string, months: string) => {
  let combinedExperience = '';
  if (years) {
    combinedExperience = combinedExperience + `${years}y`;
  } else {
    combinedExperience = combinedExperience + '0y';
  }
  if (months) {
    combinedExperience = combinedExperience + `${months}m`;
  } else {
    combinedExperience = combinedExperience + '0m';
  }
  return combinedExperience;
};

const createEmployeePayload = (
  employeeDetails: any,
  skills: SkillsProps[],
  industryExperience: IndustryExperience[],
  projects?: EmployeeProjectDetails[],
  employeeStatusData?: EmployeeStatusData,
  isEditMode?: boolean
) => ({
  employee: JSON.stringify({
    name: employeeDetails.fullName,
    email: employeeDetails.email,
    phoneNumber: employeeDetails.phoneNumber || '',
    residentialAddress: employeeDetails.homeAddress || '',
    practice: employeeDetails.practice[0]?.name,
    joiningDate: new Date(employeeDetails.joinDate).toLocaleDateString(
      DATE_LOCALE,
      DATE_FORMAT
    ),
    ohrId: employeeDetails.ohrId,
    rpId: employeeDetails.employeeId,
    designation: employeeDetails.employeeDesignation,
    level: employeeDetails.level[0]?.name,
    workMode: employeeDetails.workMode[0]?.name,
    manager: employeeDetails.reportingManager,
    managerRpId: employeeDetails.managerId,
    baseLocation: employeeDetails.location[0]?.name,
    qualification: employeeDetails.education[0]?.name,
    latticeLink: employeeDetails.latticeLink,
    remarks: employeeDetails?.remark || '',
    previousExperience: formatExperience(
      employeeDetails.experienceInYears,
      employeeDetails.experienceInMonths
    ),
    skills: formatSkillsData(skills),
    employeeIndustry: industryExperience,
    ...(isEditMode && {
      employeeProject: projects?.map((project) => ({
        ...project,
        joiningDate:
          project.joiningDate != ''
            ? new Date(project.joiningDate).toLocaleDateString(
                DATE_LOCALE,
                DATE_FORMAT
              )
            : project.joiningDate,
        leavingDate:
          project.leavingDate != ''
            ? new Date(project.leavingDate).toLocaleDateString(
                DATE_LOCALE,
                DATE_FORMAT
              )
            : project.leavingDate,
      })),
      status: employeeStatusData?.inActive ? 'InActive' : 'Active',
      ...(employeeStatusData?.inActive && {
        lastWorkingDate: employeeStatusData.lastWorkingDay?.toLocaleDateString(
          DATE_LOCALE,
          DATE_FORMAT
        ),
      }),
    }),
  }),
  employeeResume: employeeDetails?.resumeRegular || null,
  employeeImage: employeeDetails?.profilePhoto || null,
});

export const buildEmployeeDetailsPayload = (
  employeeDetailsFormData: any,
  skillsList: SkillsProps[],
  industryExperience: IndustryExperience[],
  projectDetailsList?: EmployeeProjectDetails[],
  employeeStatusData?: EmployeeStatusData,
  isEditMode = false
) => {
  const requestPayload = createEmployeePayload(
    employeeDetailsFormData,
    skillsList,
    industryExperience,
    projectDetailsList,
    employeeStatusData,
    isEditMode
  );
  const formDataPayload = new FormData();
  const employeeDetailsBlob = new Blob([requestPayload.employee], {
    type: 'application/json',
  });
  formDataPayload.append('employee', employeeDetailsBlob);
  formDataPayload.append('employeeResume', requestPayload.employeeResume);
  formDataPayload.append('employeeImage', requestPayload.employeeImage);

  return formDataPayload;
};

export const findSkillCategory = (skillsList: SkillsProps[], id: string) =>
  skillsList.filter((skill) => skill.id === id);
