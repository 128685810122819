import React, { FC, useRef, useState, ChangeEvent, useEffect } from 'react';
import { FileUploaderProps } from './FileUploaderType';
import Button from 'components/common/Button';
import { validateFileExtensions } from 'utils/validateFileExtensions';
import './FileUploader.scss';

const FileUploader: FC<FileUploaderProps> = ({
  className = '',
  id,
  showIcon = true,
  iconClassName = '',
  showLabel = true,
  labelTitleText,
  labelFileText,
  description,
  buttonLabel = 'Browse',
  multiFile = false,
  handleFileSelection,
  showError = false,
  errorText,
  alreadySelectedFile = null,
  disabledFileUploader = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [validationError, setValidationError] = useState('');
  const [isMounted, setIsMounted] = useState(false);

  const getFileExtensions = (labelFileText: string): string[] =>
    labelFileText
      .toUpperCase()
      .split(',')
      .map((type) => type.trim().split(' ')[0]);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    if (!isMounted && alreadySelectedFile?.name) {
      setSelectedFiles([alreadySelectedFile]);
    }
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    handleFileSelection(selectedFiles.length > 0 ? selectedFiles[0] : null);
  }, [selectedFiles]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const uploadedFiles = Array.from(fileList);
      const allowedExtensions = getFileExtensions(labelFileText);

      const invalidFiles = uploadedFiles.filter((file) => {
        const extension = file.name.split('.').pop()?.toUpperCase() as string;
        return !validateFileExtensions(extension, allowedExtensions);
      });

      if (invalidFiles.length > 0) {
        setValidationError(`Invalid File, Please choose another file.`);
      } else {
        if (multiFile) {
          setSelectedFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
        } else {
          setSelectedFiles(uploadedFiles);
        }
        setValidationError('');
        handleFileSelection(uploadedFiles[uploadedFiles.length - 1]);
      }
    }
  };

  const handleRemoveFile = (index: number) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);

      if (updatedFiles.length === 0) {
        handleFileSelection(null);
      }

      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }

      return updatedFiles;
    });
  };

  return (
    <div id={id} className={`fileUploaderContainer ${className}`}>
      {showLabel && (
        <div className="fileUploaderContainer__label">{labelTitleText}</div>
      )}
      <div
        className={`fileUploaderContainer__input-wrapper ${
          disabledFileUploader ? 'dropdown__disabled' : ''
        }`}
      >
        <div
          className="fileUploaderContainer__input-wrapper__input"
          data-testid="input"
        >
          {showIcon && (
            <span
              className={`fileUploaderContainer__input-wrapper__icon ${iconClassName}`}
            />
          )}
          {description && (
            <div className="fileUploaderContainer__input-wrapper__description">
              {description}
            </div>
          )}
          <Button
            type="button"
            className="fileUploaderContainer__input-wrapper__button_browse"
            onClick={handleClick}
            value={buttonLabel}
          />
          <input
            key={selectedFiles.length}
            className="fileUploaderContainer__input-wrapper__button_browse__input"
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple={multiFile}
          />
        </div>
      </div>
      {validationError && (
        <div className="fileUploaderContainer__validation-error">
          {validationError}
        </div>
      )}
      {showError && (
        <div className="fileUploaderContainer__error-text">{errorText}</div>
      )}
      {selectedFiles.length > 0 && (
        <div className="fileUploaderContainer__selected-files">
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className="fileUploaderContainer__selected-files__selected-file"
            >
              {file.name}
              <span
                className="fileUploaderContainer__selected-files__remove-icon icon-cross_with_circle"
                onClick={() => handleRemoveFile(index)}
              />
            </div>
          ))}
        </div>
      )}
      {showLabel && (
        <div className="fileUploaderContainer__label">{labelFileText}</div>
      )}
    </div>
  );
};

export default FileUploader;
