export const isObjectPresentInArray = <T>(
  array: T[],
  objectKey: keyof T,
  objectValue: string
) => {
  if (array?.length > 0) {
    return (
      array.findIndex(({ [objectKey]: key }: T) => key === objectValue) >= 0
    );
  }
  return false;
};
