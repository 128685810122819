import { FC, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'types/hooks';
import Modal from 'react-modal';
import { EmployeeDetailsProps } from './EmployeeDetailsType';
import Avatar from 'components/common/Avatar';
import Skill from 'components/Skill';
import Staffing from 'components/Staffing';
import TabNav from 'components/common/TabNav';
import Button from 'components/common/Button';
import Teams from 'assets/images/teams.svg';
import Oz from 'assets/images/oz-logo.svg';
import Lattice from 'assets/images/lattice.svg';
import { Tab, TabPanel } from 'react-tabs';
import LABELS from 'app/labels';
import Resume from 'components/Resume';
import { UpdatedResumeData } from 'components/Resume/ResumeType';
import {
  fetchEmployeeResumeData,
  postEmployeeResume,
} from 'redux/services/employeeDirectory/employeeDirectoryService';
import { getEmployeeResumeData } from 'redux/slices/employeeDirectory/employeeDirectorySlice';
import { enableFeature, isAdmin } from 'utils/fetchSessionInfo';
import './EmployeeDetails.scss';
import { getImages } from 'utils/getImages';

const EmployeeDetails: FC<EmployeeDetailsProps> = ({
  data,
  fetchEmployeeBios,
  onEditEmployeeProfile,
}) => {
  const {
    name,
    designation,
    email,
    phoneNumber,
    timeZone,
    joiningDate,
    ohrId,
    rpId,
    level,
    experience,
    practice,
    manager,
    photo,
    baseLocation,
    employeeOzLink,
    // country,
    teamsLink,
    outlookLink,
    latticeLink,
    employeeSkill,
    employeeProject,
    employeeIndustry,
    resume,
    tsgResume,
    tsgResumeDocx,
    status,
    lastWorkingDate,
    workMode,
  } = data;

  const {
    PROFILE: {
      EMAIL_ADDRESS,
      PHONE_NUMBER,
      TIME_ZONE,
      JOINING_DATE,
      OFFICE_LOCATION,
      OHR_ID,
      EMPLOYEE_ID,
      // LEVEL,
      WORK_MODE,
      EXPERIENCE,
      STATUS,
      LAST_WORKING_DAY,
      PRACTICE,
      MANAGER,
      OZ_LINK,
      TEAMS_LINK,
      OUTLOOK_LINK,
      LATTICE_LINK,
      DOWNLOAD_PROFILE,
      DOWNLOAD_RESUME,
      SKILLS_AND_INDUSTRY_TAB_LABEL,
      STAFFING_TAB_LABEL,
      RESUME_TAB_LABEL,
      TSG_PDF_URL,
      TSG_DOCX_URL,
      RESUME_PDF_URL,
      ACTION,
      EDIT_PROFILE,
    },
  } = LABELS;

  const resumeData = useAppSelector(getEmployeeResumeData);
  const dispatch = useAppDispatch();
  const [isResumeModalOpen, setIsResumeModalOpen] = useState(false);

  useEffect(() => {
    if (ohrId) {
      dispatch(fetchEmployeeResumeData({ ohrId }));
    }
  }, [ohrId, dispatch]);

  const handleSubmit = async (updatedResumeData: UpdatedResumeData) => {
    updatedResumeData.ohrId = ohrId;
    await postEmployeeResume(updatedResumeData);
    dispatch(fetchEmployeeResumeData({ ohrId }));
  };

  const renderTabList = () => (
    <>
      {(employeeSkill || employeeIndustry) && (
        <Tab>{SKILLS_AND_INDUSTRY_TAB_LABEL}</Tab>
      )}
      <Tab>{STAFFING_TAB_LABEL}</Tab>
      <Tab>{RESUME_TAB_LABEL}</Tab>
    </>
  );

  const renderTabPanel = () => (
    <>
      {employeeSkill && (
        <TabPanel>
          <Skill data={employeeSkill} industries={employeeIndustry} />
        </TabPanel>
      )}
      <TabPanel>
        <Staffing data={employeeProject || []} />
      </TabPanel>
      {resumeData && (
        <TabPanel>
          <Resume
            data={resumeData || {}}
            shouldEditEnabled={
              enableFeature(process.env.REACT_APP_ENV) || isAdmin()
            }
            onSubmit={handleSubmit}
          />
        </TabPanel>
      )}
    </>
  );

  const renderResumeDownloadModal = () => (
    <Modal
      isOpen={isResumeModalOpen}
      onRequestClose={() => setIsResumeModalOpen(false)}
      className="resumeDownloadContainer"
      overlayClassName="resumeDownloadOverlay"
    >
      <div className="resumeDownloadContainer__download-header">
        <div className="resumeDownloadContainer__download-header__title">
          {DOWNLOAD_PROFILE}
        </div>
        <div
          className="resumeDownloadContainer__download-header__icon icon-cross"
          onClick={() => setIsResumeModalOpen(false)}
        />
      </div>
      <div className="resumeDownloadContainer__download-buttons">
        <button
          className={`resumeDownloadContainer__download-buttons__pdf-button ${
            !tsgResume ? 'disabled-download-buttons' : ''
          }`}
          disabled={!tsgResume}
          onClick={() => {
            fetchEmployeeBios(TSG_PDF_URL, `${name}`.replaceAll(' ', '_'));
            setIsResumeModalOpen(false);
          }}
        >
          <img src={getImages('PDF')} alt={'pdf'} />
        </button>
        <button
          disabled={!tsgResumeDocx}
          className={`resumeDownloadContainer__download-buttons__word-button ${
            !tsgResumeDocx ? 'disabled-download-buttons' : ''
          }`}
          onClick={() => {
            fetchEmployeeBios(TSG_DOCX_URL, `${name}`.replaceAll(' ', '_'));
            setIsResumeModalOpen(false);
          }}
        >
          <img src={getImages('WORD')} alt={'pdf'} />
        </button>
      </div>
    </Modal>
  );

  return (
    <div className="employee__detail__page" data-testid="employee-details">
      <div className="employee__detail__page__details">
        <div className="employee__detail__page__details__avatar">
          <Avatar src={photo} alt="as" />
        </div>
        <div className="employee__detail__page__details__layout">
          <div className="employee__detail__page__details__column">
            <span className="employee__detail__page__details__column__name">
              {name}
            </span>
            <span className="employee__detail__page__details__column__label">
              {designation} | {level}
            </span>
            <div className="spacing_12">
              <span className="employee__detail__page__details__column__label">
                {`${EMAIL_ADDRESS} : `}
              </span>
              <a
                className="employee__detail__page__details__column__value employee__detail__page__details__column__email"
                target="_blank"
                href={`mailto:${email}`}
                rel="noreferrer"
              >
                {email}
              </a>
            </div>
            <div>
              <span className="employee__detail__page__details__column__timezone">
                {`${PHONE_NUMBER} : `}
              </span>
              <span className="employee__detail__page__details__column__value">
                {phoneNumber}
              </span>
            </div>
            <div>
              <span className="employee__detail__page__details__column__timezone">
                {`${TIME_ZONE} : `}
              </span>
              <span className="employee__detail__page__details__column__value">
                {timeZone}
              </span>
            </div>
          </div>
          <div className="employee__detail__page__details__column">
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {EMPLOYEE_ID}
              </span>
              <span className="employee__detail__page__details__column__value">
                {enableFeature(process.env.REACT_APP_ENV) || isAdmin()
                  ? rpId
                  : '****'}
              </span>
            </div>
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {OHR_ID}
              </span>
              <span className="employee__detail__page__details__column__value">
                {enableFeature(process.env.REACT_APP_ENV) || isAdmin()
                  ? ohrId
                  : '*********'}
              </span>
            </div>
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {EXPERIENCE}
              </span>
              <span className="employee__detail__page__details__column__value">
                {experience}
              </span>
            </div>
          </div>
          <div className="employee__detail__page__details__column">
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {JOINING_DATE}
              </span>
              <span className="employee__detail__page__details__column__value">
                {joiningDate}
              </span>
            </div>
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {OFFICE_LOCATION}
              </span>
              <span className="employee__detail__page__details__column__value">
                {baseLocation}
              </span>
            </div>
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {WORK_MODE}
              </span>
              <span className="employee__detail__page__details__column__value">
                {workMode ?? '-'}
              </span>
            </div>
          </div>
          <div className="employee__detail__page__detail__page__details__column">
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {MANAGER}
              </span>
              <span className="employee__detail__page__details__column__value">
                {manager}
              </span>
            </div>
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {PRACTICE}
              </span>
              <span className="employee__detail__page__details__column__value">
                {practice}
              </span>
            </div>
            <div className="employee__detail__page__details__column__two">
              <span className="employee__detail__page__details__column__label">
                {status?.toLowerCase() === 'inactive'
                  ? LAST_WORKING_DAY
                  : STATUS}
              </span>
              <span className="employee__detail__page__details__column__value">
                {status?.toLowerCase() === 'inactive'
                  ? lastWorkingDate
                  : status}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="employee__detail__page__body">
        <div className="employee__detail__page__body__sidebar">
          <div className="employee__detail__page__body__sidebar__block">
            <a
              href={teamsLink}
              target="_blank"
              rel="noopener noreferrer"
              className="employee__detail__page__body__sidebar__item"
            >
              <img src={Teams} alt="teams" className="sidebar__icon" />
              {TEAMS_LINK}
            </a>
            <a
              href={`mailto:${outlookLink}`}
              className="employee__detail__page__body__sidebar__item"
            >
              <span className="icon-outlook icon sidebar__icon" />
              {OUTLOOK_LINK}
            </a>
            <a
              href={latticeLink}
              target="_blank"
              rel="noopener noreferrer"
              className="employee__detail__page__body__sidebar__item"
            >
              <img src={Lattice} alt="Lattice" className="sidebar__icon" />
              <div className="employee__detail__page__body__sidebar__item__flex">
                <span>{LATTICE_LINK}</span>
                <span className="icon-link icon icon__align__right icon-small" />
              </div>
            </a>
            <a
              href={employeeOzLink}
              target="_blank"
              rel="noopener noreferrer"
              className="employee__detail__page__body__sidebar__item"
            >
              <img src={Oz} alt="Oz" className="sidebar__icon" />
              <div className="employee__detail__page__body__sidebar__item__flex">
                <span>{OZ_LINK}</span>
                <span className="icon-link icon icon__align__right icon-small" />
              </div>
            </a>
            <>
              <div
                className="employee__detail__page__body__sidebar__item"
                onClick={() => setIsResumeModalOpen(true)}
                data-testid="download-tsg-profile"
              >
                <span className="icon-pdf-file icon sidebar__icon" />
                {DOWNLOAD_PROFILE}
              </div>
              <div
                className={`employee__detail__page__body__sidebar__item ${
                  !resume ? 'disabled' : ''
                }`}
                onClick={() =>
                  fetchEmployeeBios(
                    RESUME_PDF_URL,
                    `${name}`.replaceAll(' ', '_')
                  )
                }
                data-testid="download-tsg-Resume"
              >
                <span className="icon-resume icon sidebar__icon" />
                {DOWNLOAD_RESUME}
              </div>
            </>
          </div>
          {(enableFeature(process.env.REACT_APP_ENV) || isAdmin()) && (
            <div className="employee__detail__page__actions__section">
              <div className="employee__detail__page__actions__section__label">
                {ACTION}
              </div>
              <Button
                className="employee__detail__page__actions__section__button"
                onClick={onEditEmployeeProfile}
                value={EDIT_PROFILE}
                isSecondary
                isShowIcon
                iconClassName="icon-edit sidebar__icon"
              />
            </div>
          )}
        </div>
        <TabNav
          tabListChildren={renderTabList()}
          tabPanelChildren={renderTabPanel()}
        />
        {isResumeModalOpen && renderResumeDownloadModal()}
      </div>
    </div>
  );
};

export default EmployeeDetails;
