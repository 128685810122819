import { FC, useState, useEffect } from 'react';
import { AddProjectProps } from 'components/ProjectManagement/Types/ProjectManagementProps';
import InputBox from 'components/common/InputBox';
import DatePickerForm from 'components/common/DatePicker';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import { Filter } from 'components/common/Dropdown/DropdownType';
import { InitialSelectedDropDownvalue } from 'components/ProjectManagement/Constants/ProjectManagementConstants';
import Loader from 'components/common/Loader';
import { selectFilterData } from 'redux/slices/employeeDirectory/employeeDirectorySlice';
import { useAppSelector } from 'types/hooks';
import PageHeader from 'components/AddOrEditEmployee/TabPages/PageHeader';
import { ProjectDetails } from './AddProjectType';
import { isObjectPresentInArray } from 'utils/isObjectPresentInArray';
import { toast } from 'react-toastify';
import { addProject } from 'redux/services/projectManagement/projectManagementService';
import LABELS from 'app/labels';
import './AddProject.scss';

const AddProject: FC<AddProjectProps> = ({
  projectData,
  renderViewPage,
  onReload,
}) => {
  const [clientName, setClientName] = useState('');
  const [projectName, setProjectName] = useState('');
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [projectManager, setProjectManager] = useState('');
  const [ozLink, setOzLink] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState<Filter>(
    InitialSelectedDropDownvalue
  );
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [projectList, setProjectList] = useState<ProjectDetails[]>([]);
  const [disableAddProject, setDisableAddProject] = useState(true);
  const [isProjectNameError, setIsProjectNameError] = useState(false);
  const [showExistingClient, setShowExistingClient] = useState(true);
  const [clientNames, setClientNames] = useState<Filter[]>();
  const [selectedClientName, setSelectedClientName] = useState<Filter>(
    InitialSelectedDropDownvalue
  );
  const { industries } = useAppSelector(selectFilterData) || {};

  useEffect(() => {
    const clientName = projectData
      .filter(
        (project, index, self) =>
          index ===
          self.findIndex((item) => item.clientName === project.clientName)
      )
      .map((filteredProject) => ({
        id: filteredProject.clientName,
        name: filteredProject.clientName,
      }));
    setClientNames(clientName);
  }, [projectData]);

  useEffect(() => {
    if (
      (clientName !== '' || selectedClientName.name !== '') &&
      projectName !== '' &&
      projectManager !== '' &&
      ozLink !== '' &&
      selectedIndustry.name !== '' &&
      startDate !== undefined &&
      endDate !== undefined
    ) {
      setDisableAddProject(false);
    } else {
      setDisableAddProject(true);
    }
  }, [
    clientName,
    selectedClientName.name,
    projectName,
    projectManager,
    ozLink,
    selectedIndustry.name,
    startDate,
    endDate,
  ]);

  const {
    PROJECT_MANAGEMENT: {
      SUBMIT_BTN_LABEL,
      ADD_PROJECT_LABEL,
      CANCEL_BTN_LABEL,
      OZ_LINK,
      INDUSTRY,
      PROJECT,
      PROJECT_MANAGER,
      START_DATE,
      END_DATE,
      CLIENT,
      CLIENT_PLACEHOLDER,
      CLIENT_ENTER_PLACEHOLDER,
      PROJECT_ENTER_PLACEHOLDER,
      INDUSTRY_PLACEHOLDER,
      OZ_LINK_PLACEHOLDER,
      PROJECT_MANAGER_PLACEHOLDER,
    },
  } = LABELS;

  const handleStartDateChange = (val: Date) => {
    setStartDate(val);
  };

  const handleEndDateChange = (val: Date) => {
    setEndDate(val);
  };

  const handleSelectIndustry = (industry: Filter) => {
    setSelectedIndustry({
      id: industry.id,
      name: industry.name,
    });
  };

  const handleSubmit = () => {
    setIsSubmitted(true);
    const projectDataBlob = new Blob(
      [
        JSON.stringify(
          projectList.map((project) => ({
            ...project,
            startDate: project?.startDate?.toLocaleDateString('en-GB'),
            endDate: project?.endDate?.toLocaleDateString('en-GB'),
          }))
        ),
      ],
      {
        type: 'application/json',
      }
    );
    addProject(projectDataBlob).then(() => {
      setIsSubmitted(false);
      onReload();
      renderViewPage();
    });
  };

  const handleRemoveProject = (id: number) => {
    const projectsList = projectList;
    const updatedProjectList = projectsList?.filter(
      (project) => project.id !== id
    );
    setProjectList(updatedProjectList);
  };

  const handleShowExistingClient = () => {
    setShowExistingClient(!showExistingClient);
    setSelectedClientName(InitialSelectedDropDownvalue);
    setClientName('');
  };

  const handleAddProject = () => {
    const newProject: ProjectDetails = {
      id: projectList && projectList?.length + 1,
      clientName: showExistingClient ? selectedClientName.name : clientName,
      projectName: projectName,
      projectManager: projectManager,
      ozLink: ozLink,
      startDate: startDate,
      endDate: endDate,
      industry: selectedIndustry.name,
    };

    if (
      !isObjectPresentInArray(
        projectData,
        'projectName',
        newProject.projectName
      )
    ) {
      if (
        !isObjectPresentInArray(
          projectList,
          'projectName',
          newProject.projectName
        )
      ) {
        setProjectList([...projectList, newProject]);
      } else {
        setDisableAddProject(true);
        setIsProjectNameError(true);
        toast.error(
          <>
            <div>{`${newProject.projectName} is already added.`}</div>
            <div>{`If you want to update it, please remove it from the Selected Projects list then update it.`}</div>
          </>
        );
        return;
      }
    } else {
      setDisableAddProject(true);
      setIsProjectNameError(true);
      toast.error(
        <>
          <div>{`${newProject.projectName} is already present in database.`}</div>
          <div>{`Please enter unique Project Name`}</div>
        </>
      );
      return;
    }
    setClientName('');
    setProjectName('');
    setStartDate(undefined);
    setEndDate(undefined);
    setProjectManager('');
    setOzLink('');
    setSelectedClientName(InitialSelectedDropDownvalue);
    setSelectedIndustry(InitialSelectedDropDownvalue);
  };

  const renderClientAndProject = () => (
    <div className="addProjectContainer__body__client-section">
      <div>
        {showExistingClient ? (
          <Dropdown
            id="client-dropdown"
            inputName="client-dropdown"
            disabledDropdown={false}
            showLabel
            showSelectedOptionsCount={false}
            useFormStyles
            labelText={CLIENT + '*'}
            placeholder={CLIENT_PLACEHOLDER}
            list={clientNames}
            filterLabel={selectedClientName?.name ?? ''}
            prefillData={[selectedClientName]}
            onSelect={(clientName) => setSelectedClientName(clientName[0])}
          />
        ) : (
          <InputBox
            disabled={false}
            type="text"
            id="clientName"
            name="clientName"
            showLabel={true}
            labelText={CLIENT + '*'}
            placeholder={CLIENT_ENTER_PLACEHOLDER}
            showIcon
            iconClassName="user-circular"
            inputVal={clientName}
            onChange={(val) => setClientName(val)}
          />
        )}
        <div
          className="addProjectContainer__body__client-section__new-client-button"
          onClick={() => handleShowExistingClient()}
        >
          {showExistingClient ? 'New Client' : 'Use Existing Client'}
        </div>
      </div>
      <div className="addProjectContainer__body__client-section__date-picker">
        <DatePickerForm
          id="startDate"
          name="startDate"
          showLabel={true}
          labelText={START_DATE + '*'}
          placeholder={START_DATE}
          value={startDate}
          onChange={(startDate) =>
            startDate && handleStartDateChange(startDate)
          }
        />
        <DatePickerForm
          id="endDate"
          name="endDate"
          showLabel={true}
          labelText={END_DATE + '*'}
          placeholder={END_DATE}
          value={endDate}
          onChange={(endDate) => endDate && handleEndDateChange(endDate)}
        />
      </div>
    </div>
  );

  const renderProjectDetails = () => (
    <div className="addProjectContainer__body__project-section">
      <div className="addProjectContainer__body__project-section__name">
        <InputBox
          disabled={false}
          type="text"
          id="projectName"
          name="projectName"
          showLabel={true}
          labelText={PROJECT + '*'}
          placeholder={PROJECT_ENTER_PLACEHOLDER}
          showIcon
          iconClassName="user-circular"
          inputVal={projectName}
          onChange={(val) => {
            setProjectName(val.toString());
            setIsProjectNameError(false);
          }}
          showError={isProjectNameError}
        />
      </div>

      <div className="addProjectContainer__body__project-section__details">
        <InputBox
          disabled={false}
          type="text"
          id="managerName"
          name="managerName"
          showLabel={true}
          labelText={PROJECT_MANAGER + '*'}
          placeholder={PROJECT_MANAGER_PLACEHOLDER}
          showIcon
          iconClassName="user-circular"
          inputVal={projectManager}
          onChange={(val) => setProjectManager(val)}
        />

        <InputBox
          disabled={false}
          type="text"
          id="ozLink"
          name="ozLink"
          showLabel={true}
          labelText={OZ_LINK + '*'}
          placeholder={OZ_LINK_PLACEHOLDER}
          showIcon
          iconClassName="link"
          inputVal={ozLink}
          onChange={(val) => setOzLink(val)}
        />
        <Dropdown
          id="industryDetails"
          inputName="industryDetails"
          showLabel={true}
          showSelectedOptionsCount={false}
          useFormStyles
          labelText={INDUSTRY + '*'}
          placeholder={INDUSTRY_PLACEHOLDER}
          list={[...(industries ?? [])]}
          filterLabel={selectedIndustry?.name ?? ''}
          prefillData={[selectedIndustry]}
          onSelect={(industry) => handleSelectIndustry(industry[0])}
        />
      </div>
    </div>
  );

  return (
    <div className="addProjectContainer">
      {isSubmitted && <Loader />}
      <div className="addProjectContainer__body">
        {renderClientAndProject()}
        {renderProjectDetails()}
      </div>
      <div className="addProjectContainer__footer">
        <Button
          className="addProjectContainer__footer__btn-add-skill"
          isShowIcon
          iconClassName="icon-plus"
          onClick={handleAddProject}
          value={ADD_PROJECT_LABEL}
          disabled={disableAddProject}
          isSecondary
          data-testid="add-skills-button"
        />
      </div>

      <div className="addProjectContainer__projectList">
        <PageHeader
          title="Selected Projects"
          subtitle="All selected projects should have unique Project Name"
        />
        <div className="addProjectContainer__projectList__table">
          <table>
            <thead>
              <tr>
                <th>S No.</th>
                <th>Client Name</th>
                <th>Project Name</th>
                <th>Project Manager</th>
                <th>OZ link</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Industry</th>
                <th>Action</th>
              </tr>
            </thead>
            {projectList && projectList.length > 0 && (
              <tbody>
                {projectList.map((project) => (
                  <tr key={project.id}>
                    <td>{project.id}</td>
                    <td>{project.clientName}</td>
                    <td>{project.projectName}</td>
                    <td>{project.projectManager}</td>
                    <td>{project.ozLink}</td>
                    <td>
                      {project?.startDate
                        ?.toLocaleDateString('en-GB')
                        .toString()}
                    </td>
                    <td>
                      {project?.endDate?.toLocaleDateString('en-GB').toString()}
                    </td>
                    <td>{project.industry}</td>
                    <td>
                      <Button
                        className="addProjectContainer__projectList__table-botton"
                        isShowIcon
                        iconClassName="icon-cross"
                        onClick={() => handleRemoveProject(project.id)}
                        value="Remove"
                        isSecondary
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {projectList.length < 1 && (
            <div className="addProjectContainer__projectList__no-skills-selected">
              No Projects Added
            </div>
          )}
        </div>
      </div>
      <div className="addProjectContainer__footer">
        <Button
          className="addProjectContainer__footer__btn-cancel"
          value={CANCEL_BTN_LABEL}
          onClick={renderViewPage}
          isSecondary
          data-testid="card-body-buttons-cancel"
        />
        <Button
          className="addProjectContainer__footer__btn-next"
          value={SUBMIT_BTN_LABEL}
          onClick={handleSubmit}
          data-testid="card-body-buttons-submit"
          disabled={!(projectList.length > 0)}
        />
      </div>
    </div>
  );
};

export default AddProject;
