import { type FC } from 'react';
import LABELS from 'app/labels';
import SkillTile from 'components/SkillTile';
import { getSkillList } from 'utils/getSkillList';
import { getSkillsIcon } from 'utils/getSkillsIcon';
import { SkillProps } from './SkillType';
import './Skill.scss';

const Skill: FC<SkillProps> = ({ data, industries }) => {
  const {
    PROFILE: { INDUSTRY_EXPERIENCE },
  } = LABELS;
  const skillList = getSkillList(data);

  return (
    <div className="skill">
      {skillList?.skillCategoryList?.map(
        (skillCategory: SkillProps, index: number) => {
          const categoryName = Object.keys(skillCategory)[0];
          const skillsList = Object.values(skillCategory)[0];
          return (
            <div className="skill__base" key={index}>
              <div className="skill__base__heading">{categoryName}</div>
              <div
                className="skill__base__content"
                data-testid="front-end-skill"
              >
                {skillsList?.map((skill: any, index: number) => (
                  <SkillTile
                    key={index}
                    src={getSkillsIcon(skill.skillKey)}
                    alt={skill.name}
                    title={skill.name}
                    level={skill.skillLevel}
                    isSelected={skill.matched}
                  />
                ))}
              </div>
            </div>
          );
        }
      )}

      {industries && industries?.length > 0 && (
        <div className="skill__base">
          <div className="skill__base__heading">{INDUSTRY_EXPERIENCE}</div>
          <div
            className="skill__base__content"
            data-testid="industry-experience-skill"
          >
            {industries.map((industry, index) => (
              <SkillTile
                key={index}
                src={getSkillsIcon('industries')}
                alt={industry.name}
                title={industry.name}
                level={`${industry.experience} years`}
                isSelected={false}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Skill;
