import { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'types/hooks';
import {
  fetchSelectedEmployeeData,
  isFetchingEmployeeDetailData,
  selectedFilters,
  selectedviewProfileId,
  selectedViewProfileId,
  setEmployeeEditModeOn,
} from 'redux/slices/employeeDirectory/employeeDirectorySlice';
import LABELS from 'app/labels';
import EmployeeDetails from 'components/EmployeeDetails';
import EmployeeDetailsLoader from 'components/EmployeeDetails/EmployeeDetailsLoader';
import {
  getEmployeeProfileBios,
  getEmployeeProfileData,
} from 'redux/services/employeeDirectory/employeeDirectoryService';
import { getFilterByName } from 'utils/getFilterByName';
import { convertToArray } from 'utils/convertToArray';
import NoResultFound from 'components/common/NoResultFound';
import backIcon from 'assets/images/backbutton.svg';
import './EmployeeProfile.scss';

const EmployeeProfile = () => {
  const [openModal, setOpenModal] = useState(false);
  const viewProfileId = useAppSelector(selectedviewProfileId);
  const { searchString, skills } = useAppSelector(selectedFilters);
  const isFetchEmployeeDetails = useAppSelector(isFetchingEmployeeDetailData);
  const employeeProfile = useAppSelector(fetchSelectedEmployeeData);
  const dispatch = useAppDispatch();

  const {
    EMPLOYEE_PROFILE: { BACK },
  } = LABELS;

  useEffect(() => {
    if (viewProfileId) {
      setOpenModal(!openModal);
      dispatch(
        getEmployeeProfileData({
          employeeId: viewProfileId,
          skills: [...getFilterByName(skills), ...convertToArray(searchString)],
        })
      );
    }
  }, [viewProfileId, dispatch]);

  const handleDownloadEmployeeProfile = (url: string, name: string) => {
    dispatch(
      getEmployeeProfileBios({
        id: viewProfileId,
        url,
        name,
      })
    );
  };

  const onBackBtnClick = () => {
    setOpenModal(!openModal);
    dispatch(selectedViewProfileId(''));
  };

  const onEditEmployeeBtnClick = () => {
    setOpenModal(!openModal);
    dispatch(setEmployeeEditModeOn(true));
  };

  return (
    <div className={`employee-profile ${openModal ? 'active' : ''}`}>
      <div className={`employee-profile__container`}>
        <div className="employee-profile__header">
          <div
            className="employee-profile__header__btn-back"
            onClick={onBackBtnClick}
          >
            <img src={backIcon} alt={'backIcon'} width="20px" height="20px" />
            <div className="employee-profile__header__btn-back__text">
              {BACK}
            </div>
          </div>
        </div>
        {isFetchEmployeeDetails ? (
          <EmployeeDetailsLoader />
        ) : employeeProfile ? (
          <EmployeeDetails
            data={employeeProfile}
            fetchEmployeeBios={handleDownloadEmployeeProfile}
            onEditEmployeeProfile={onEditEmployeeBtnClick}
          />
        ) : (
          <NoResultFound />
        )}
      </div>
    </div>
  );
};

export default EmployeeProfile;
