import WORD from 'assets/images/word.png';
import PDF from 'assets/images/pdf.png';
import { IconMapping } from 'types/employeeDirectory';

const IMAGES: IconMapping = {
  WORD,
  PDF,
};

export const getImages = (key: string) => IMAGES[key];
