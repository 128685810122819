import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import employeeDirectoryReducer from 'redux/slices/employeeDirectory/employeeDirectorySlice';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  directory: employeeDirectoryReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const store = setupStore();
