export const fetchSessionInfo = () => {
  const token = sessionStorage.getItem('idToken');
  if (!token) {
    return null;
  }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const isAdmin = () => {
  const sessionInfo = fetchSessionInfo();
  return !!sessionInfo?.roles?.includes('ROLE_rp_staffing_admin');
};

export const enableFeature = (environment = 'dev') =>
  ['dev'].includes(environment);
