import React, { type FC } from 'react';
import { InputBoxProps } from './InputBoxType';
import LABELS from 'app/labels';
import './InputBox.scss';

const InputBox: FC<InputBoxProps> = ({
  type = 'text',
  name,
  className,
  disabled = false,
  id,
  onChange,
  onKeyPress,
  placeholder = '',
  inputVal = '',
  showIcon = false,
  iconClassName,
  showLabel = false,
  labelText,
  showError = false,
  errorText,
  isRequired = false,
}) => {
  const {
    DIRECTORY: { ENTER },
  } = LABELS;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == ENTER) {
      onKeyPress?.();
    }
  };

  return (
    <div id={id} className={`inputContainer ${className}`}>
      {showLabel && <div className="inputContainer___label">{labelText}</div>}
      <div className="inputContainer__input-wrapper">
        {showIcon && (
          <span
            className={`inputContainer__input-wrapper__icon icon-${iconClassName}`}
          />
        )}
        <input
          className={`inputContainer__input-wrapper__input ${
            showError && 'error-input'
          } ${showIcon && 'inputContainer__input-wrapper__input__icon'} ${
            disabled ? 'inputContainer__disabled' : ''
          }`}
          type={type}
          name={name}
          disabled={disabled}
          value={inputVal}
          autoComplete="off"
          data-testid="input"
          placeholder={placeholder}
          onChange={(e) => handleChange(e)}
          onKeyDown={(e) => handleKeyPress(e)}
          required={isRequired}
        />
      </div>
      {showError && (
        <div className="inputContainer__error-text">{errorText}</div>
      )}
    </div>
  );
};

export default InputBox;
