import { useEffect, type FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import Home from 'components/Home';
import PageNotFound from 'components/common/PageNotFound';
import EmployeeProfile from 'components/EmployeeProfile';
import { ToastContainer } from 'react-toastify';
import { loginRequest } from './authConfig';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/index.scss';

const App: FC = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleUserLogin = async () => {
    try {
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
        await instance.loginRedirect(loginRequest);
      }
      const accountResponse = await instance.handleRedirectPromise();
      if (accountResponse && accountResponse.account) {
        instance.setActiveAccount(accountResponse.account);
        sessionStorage.setItem('idToken', accountResponse.idToken);
      }
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });
      sessionStorage.setItem('accessToken', tokenResponse.accessToken);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handleUserLogin();
  });

  return (
    <>
      <AuthenticatedTemplate>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="about" element={<About />} /> */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <EmployeeProfile />
        <ToastContainer />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h2>Please sign-in to see your profile information.</h2>
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
