import { type FC } from 'react';
import { CardProps } from './CardType';
import Button from 'components/common/Button';
import Avatar from 'components/common/Avatar';
import './Card.scss';

const Card: FC<CardProps> = ({ data, onViewProfile }) => {
  const {
    name,
    designation,
    employeeProject,
    email,
    baseLocation,
    rp_id,
    manager_RpId,
    manager,
    photo,
    skillMatch,
    practice,
    level,
    country,
    availability,
  } = data;

  // const onClickWishlist = () => {
  //   alert('Button was clicked ');
  // };

  const viewProfile = (id: string) => {
    onViewProfile(id);
  };

  return (
    <div className="card" data-testid="card" key={rp_id}>
      <div className="card__header" data-testid="card-header">
        <Avatar src={photo} alt="as" data-testid="card-header-avatar" />
        <div className="card__header__info" data-testid="card-header-info">
          <span className="card__header__info_name">{name}</span>
          <span className="card__header__info_size">{designation}</span>
          <span className="card__header__info_size">{employeeProject}</span>
        </div>
      </div>
      <div className="card__body" data-testid="card-body">
        <div className="card__body__info" data-testid="card-body-info">
          <span className="icon icon-email" />
          <span className="card__header__info_size card__header__info_size-email">
            <a className="card__body__info-link" href={`mailto:${email}`}>
              {email}
            </a>
          </span>
        </div>
        <div className="card__body__info" data-testid="card-body-info">
          <span className="icon icon-place" />
          <span className="card__header__info_size card__header__info_size-location">
            {`${baseLocation}, ${country}`}
          </span>
        </div>
        {/* <div className="card__body__info" data-testid="card-body-info">
          <span className="icon icon-phone" />
          <span className="card__header__info_size">
            <a className="card__body__info-link" href={`tel:${phoneNumber}`}>
              {phoneNumber}
            </a>
          </span>
        </div> */}
        <div
          className="card__body__info card__body__info-reports"
          data-testid="card-body-info"
        >
          <span className="card__body__info-text">Reports to:</span>
          <span
            className="card__header__info_size card__body__info-link"
            onClick={() => viewProfile(manager_RpId)}
          >
            {manager}
          </span>
        </div>
        {/* <div className="card__body__info">
          <a className="card__body__info-link" href={ozLink}>
            OZ Link
          </a>
        </div>
        <div className="card__body__info">
          <a className="card__body__info-link" href={teamsLink}>
            Teams Link
          </a>
        </div> */}
        <div className="card__body__bullets">
          <div
            className="card__body__info card__body__bullets-badges"
            data-testid="card-body-info"
          >
            <span className="icon icon-checkmark" />
            <span className="card__header__info_size">{practice}</span>
          </div>
          <div
            className="card__body__info card__body__bullets-badges"
            data-testid="card-body-info"
          >
            {availability === 'Available' ? (
              <span className="icon icon-checkmark" />
            ) : availability === 'Planned' ? (
              <span className="icon icon-clock" />
            ) : (
              <span className="icon icon-cross_with_circle" />
            )}
            <span className="card__header__info_size">{availability}</span>
          </div>
          {level && (
            <div
              className="card__body__info card__body__bullets-badges"
              data-testid="card-body-info"
            >
              <span className="icon icon-checkmark" />
              <span className="card__header__info_size">{level}</span>
            </div>
          )}
          {skillMatch && (
            <div
              className="card__body__info card__body__bullets-badges"
              data-testid="card-body-info"
            >
              <span className="icon icon-checkmark" />
              <span className="card__header__info_size">{skillMatch}</span>
            </div>
          )}
        </div>
      </div>
      <div className="card__footer" data-testid="card-body-buttons">
        {/* <Button
            disabled={false}
            className="Card__Body__ButtonPanel__Wishlist"
            value="+Wishlist"
            onClick={onClickWishlist}
            isButtonLink={false}
            data-testid="card-body-buttons-add"
          /> */}
        <Button
          className="card__footer-btn-view"
          value="View"
          onClick={() => viewProfile(rp_id)}
          isSecondary
          data-testid="card-body-buttons-view"
        />
      </div>
    </div>
  );
};

export default Card;
