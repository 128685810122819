const labels = {
  COPY_RIGHT: '© 2023 Rightpoint. All Rights Reserved.',
  DIRECTORY: {
    WELCOME_TEXT: 'Welcome Back,',
    ACTIVE_EMPLOYEE: 'Active Employees',
    FILTER_BY_TEXT: 'Filter By:',
    PRACTICE: 'Practice',
    INDUSTRY: 'Industry',
    DEPARTMENT_FILTER_TEXT: 'department',
    INDUSTRY_FILTER_TEXT: 'industries',
    LOCATION: 'Location',
    LOCATION_FILTER_TEXT: 'location',
    EXPERIENCE_LEVEL: 'Experience Level',
    EXPERIENCE_LEVEL_FILTER_TEXT: 'experienceLevel',
    SKILLS: 'Skills',
    SKILLS_FILTER_TEXT: 'skills',
    SKILL_LEVEL_SELECT: 'Select their skill level:',
    SKILL_LEVEL: 'Skill Level',
    EMPLOYEE_AVAILABILITY: 'Availability',
    EMPLOYEE_STATUS: 'Status',
    EMPLOYEE_AVAILABILITY_FILTER_TEXT: 'employeeAvailablity',
    EMPLOYEE_STATUS_FILTER_TEXT: 'status',
    RESET: 'Reset',
    ENTER: 'Enter',
    SEARCH_STRING: 'searchString',
    HEADER: 'Employee Directory',
  },
  EMPLOYEE_PROFILE: {
    BACK: 'Back',
  },
  ADD_OR_EDIT_EMPLOYEE: {
    TAB_DETAILS: 'Employee Details',
    TAB_SKILLS: 'Skills & Industries',
    TAB_DOCS: 'Documents & Links',
    TAB_PROJECTS: 'Project Details',
    CANCEL: 'Cancel',
    PREVIOUS: 'Previous Section',
    SUBMIT: 'Submit',
    NEXT: 'Next Section',
    HEADER: 'Add Employee',
    EDIT_HEADER: 'Edit Employee',
    SUBHEADER:
      'Please add Employee details or upload preferred Excel sheet for bulk upload',
    EDIT_SUBHEADER: 'Please edit Employee details',
    DEFAULT_ERROR_MESSAGE:
      'Upload Failed: Server Error. Please try again later.',
    REQUIRED_FIELD: 'Please fill all required fields',
    REQUIRED_UPLOAD: 'Please upload all requed documents',
    EMPLOYEE_DETAIL: {
      HEADER: 'Employee Details',
      SUBHEADER: '(All * fields are mandatory)',
      RESUME_DETAILS: 'Resume Details*',
      PROFILE_PHOTO: 'Profile Photo*',
      EMPLOYEE_RESUME_TSG: 'Employee Resume (TSG)*',
      EMPLOYEE_RESUME_REGULAR: 'Employee Resume (regular)',
      RESUME_FILE_TEXT: 'PDF',
      RESUME_FILE_ERROR: 'Please upload employee resume',
      PHOTO_UPLOAD_ERROR: 'Please upload employee photo',
      PHOTO_FILE_TEXT: 'SVG, PNG, JPG',
      UPLOAD_RESUME: 'Upload Resume file',
      UPLOAD_PHOTO: 'Upload a picture',
      FULL_NAME: 'Full Name*',
      FULL_NAME_PLACEHOLDER: 'Enter Full Name',
      FULL_NAME_ERROR: 'Please enter full name of employee',
      EMAIL_ADDRESS: 'Email*',
      EMAIL_ADDRESS_PLACEHOLDER: 'Enter RP Email Id',
      EMAIL_ADDRESS_ERROR: 'Please enter email of employee',
      PHONE_NUMBER: 'Phone Number',
      PHONE_NUMBER_PLACEHOLDER: 'Enter Phone number with code',
      HOME_ADDRESS: 'Residential Address',
      HOME_ADDRESS_PLACEHOLDER: 'Enter Residential Address',
      EMPLOYEE_ID: 'Employee Id*',
      EMPLOYEE_ID_PLACEHOLDER: 'Enter RP Id',
      EMPLOYEE_ID_ERROR: 'Please enter id of employee',
      PRACTICE: 'Practice*',
      PRACTICE_PLACEHOLDER: 'Select Practice',
      PRACTICE_ERROR: 'Please enter practice of employee',
      JOIN_DATE: 'Date of Joining*',
      JOIN_DATE_PLACEHOLDER: 'Joining Date(mm/dd/yyyy)',
      JOIN_DATE_ERROR: 'Please enter join date of employee',
      OHR_ID: 'OHR Id*',
      OHR_ID_PLACEHOLDER: 'Enter OHR Id',
      OHR_ID_ERROR: 'Please enter OHR id of employee',
      LOCATION: 'Location*',
      LOCATION_PLACEHOLDER: 'Select Office Location',
      LOCATION_ERROR: 'Please enter location of employee',
      EMPLOYEE_DESIGNATION: 'Employee Designation*',
      EMPLOYEE_DESIGNATION_PLACEHOLDER: 'Enter Designation',
      EMPLOYEE_DESIGNATION_ERROR: 'Please enter designation of employee',
      LEVEL: 'Level*',
      LEVEL_PLACEHOLDER: 'Select Level',
      LEVEL_ERROR: 'Please enter level of employee',
      INDUSTRY: 'Industry',
      INDUSTRY_PLACEHOLDER: 'Select Industry',
      WORK_MODE: 'Work Mode*',
      WORK_MODE_PLACEHOLDER: 'Select Work Mode',
      WORK_MODE_ERROR: 'Please enter work mode of employee',
      REPORTING_MANAGER: 'Reporting Manager*',
      REPORTING_MANAGER_PLACEHOLDER: 'Enter Manager name',
      REPORTING_MANAGER_ERROR: 'Please enter reporting manager',
      MANAGER_ID: 'Manager Employee Id*',
      MANAGER_ID_PLACEHOLDER: 'Enter Manager RP Id',
      MANAGER_ID_ERROR: 'Please enter manager id',
      EDUCATION: 'Education*',
      EDUCATION_PLACEHOLDER: 'Select Education',
      EDUCATION_ERROR: 'Please enter education of employee',
      EXPERIENCE: 'Total years of experience (At the time of joining)*',
      EXPERIENCE_YEAR: 'Years',
      EXPERIENCE_MONTH: 'Months',
      EXPERIENCE_PLACEHOLDER_YEAR: 'Years',
      EXPERIENCE_PLACEHOLDER_MONTH: 'Months',
      EXPERIENCE_YEAR_ERROR: 'Years required',
      EXPERIENCE_MONTH_ERROR: 'Months required',
      REMARK: 'Remarks',
      REMARK_PLACEHOLDER: 'Enter Additional Remarks',
      LATTICE_LINK: 'Lattice Link',
      LATTICE_LINK_PLACEHOLDER: 'Enter Lattice Link',
    },
    EMPLOYEE_SKILLS: {
      SKILLS_SECTION_TITLE: 'Skill Details',
      SKILLS_SECTION_SUBTITLE: '(Atleast 1 skill is mandatory)',
      SELECTED_SKILLS_TITLE: 'Selected Skills',
      SELECTED_SKILLS_SUBTITLE: 'Your selected skills will appear below',
      INDUSTRY_SECTION_TITLE: 'Industry Details',
      INDUSTRY_SECTION_SUBTITLE: '(Atleast 1 industry experience is mandatory)',
      INDUSTRY_SECTION_PLACEHOLDER: 'Years of experience',
    },
    PROJECT_DETAILS: {
      PROJECT_TAB_LABEL: 'Select Project',
      PROJECT_TAB_LABEL_MSG:
        '(Select project name which is aligned with employee)',
      CLIENT_PROJECT_LABEL: 'Client | Project Name',
      CLIENT_LABEL: 'Client',
      PROJECT_LABEL: 'Project',
      CLIENT_PROJECT_PLACEHOLDER: 'eg. Client | Project Name',
      CLIENT_PLACEHOLDER: 'eg. Client',
      PROJECT_PLACEHOLDER: 'eg. Project Name',
      JOIN_DATE_LABEL: 'Date of Joining on Project',
      JOIN_DATE_PLACEHOLDER: 'eg. mm/dd/yyyy',
      LEAVING_DATE_LABEL: 'Date of Staffing Expiry',
      LEAVING_DATE_PLACEHOLDER: 'eg. mm/dd/yyyy',
      PROJECT_MANAGER_LABEL: 'Project Manager',
      PROJECT_MANAGER_PLACEHOLDER: 'eg. John McKinney',
      OZ_LINK_LABEL: 'OZ Link',
      OZ_LINK_PLACEHOLDER: 'eg. https://oz.rightpoint.com/',
      ADD_PROJECT: 'Add Project',
      SELECT_PROJECT_ERROR: 'Please select any new project name.',
      SELECT_PRJ_JOINING_DATE_ERROR:
        'Please select Date of Joining on Project.',
      ALREADY_SELECTED_PROJECT_ERROR:
        'project name already selected. Please select the different project name.',
    },
  },
  TOOLTIP: {
    SEARCH_CONDITION_TEXT: 'Search text should be greater than 2 letters.',
    SEARCH_PLACEHOLDER_TEXT: 'Search by Name, Skill or Practice',
  },
  PROFILE: {
    EMAIL_ADDRESS: 'Email',
    PHONE_NUMBER: 'Phone Number',
    TIME_ZONE: 'Time Zone',
    CURRENT_LOCATION: 'Current Location/ Time',
    JOINING_DATE: 'Joining Date',
    OFFICE_LOCATION: 'Office Location',
    OHR_ID: 'OHR ID',
    EMPLOYEE_ID: 'Employee ID',
    LEVEL: 'Level',
    INDUSTRY: 'Industry',
    WORK_MODE: 'Work Mode',
    EXPERIENCE: 'Experience',
    BUSINESS_UNIT: 'Business Unit',
    STATUS: 'Status',
    LAST_WORKING_DAY: 'LWD',
    PRACTICE: 'Practice',
    MANAGER: 'Manager',
    CURRENT_PROJECT: 'Current Project',
    AM: 'AM',
    PM: 'PM',
    OZ_LINK: 'Go to OZ',
    TEAMS_LINK: 'Message on Teams',
    OUTLOOK_LINK: 'Mail on Outlook',
    LATTICE_LINK: 'Go to Lattice',
    DOWNLOAD_PROFILE: 'Download Profile',
    DOWNLOAD_RESUME: 'Download Resume',
    AVAILABILITY: 'Availability',
    STAFFING_PERIOD: 'Staffing Period',
    STAFFING_RATE: 'Staffing Rate',
    TOTAL_HOURS: 'Total hours',
    REMARKS: 'Remarks',
    SKILLS_TYPE_DEVELOPMENT: 'Skills | Type Development',
    SKILLS_TAB_LABEL: 'Skills',
    SKILLS_AND_INDUSTRY_TAB_LABEL: 'Skills & Industries',
    INDUSTRY_EXPERIENCE: 'Industry Experience',
    STAFFING_TAB_LABEL: 'Staffing',
    PROJECTS_TAB_LABEL: 'Projects',
    RESUME_TAB_LABEL: 'Profile',
    BACK_BUTTON_LABEL: 'Back',
    TSG_PDF_URL: 'tsgProfile',
    TSG_DOCX_URL: 'tsgProfileDocx',
    RESUME_PDF_URL: 'resumePdf',
    EDIT_PROFILE: 'Edit Employee',
    ACTION: 'Action',
  },
  PROJECT_MANAGEMENT: {
    PROJECTS_LABEL: 'Projects',
    ADD_PROJECT_LABEL: 'Add Project',
    ADD_SKILL_LABEL: 'Add Skill',
    CANCEL_BTN_LABEL: 'Cancel',
    DELETE_SKILL_BTN_LABEL: 'Delete Skill',
    SUBMIT_BTN_LABEL: 'Submit',
    EDIT_PROJECT_BTN_LABEL: 'Edit Project',
    EDIT_SKILL_BTN_LABEL: 'Edit Skill',
    CLIENT: 'Client Name',
    CATEGORY: 'Category',
    PROJECT: 'Project Name',
    INDUSTRY: 'Industry',
    START_DATE: 'Start Date',
    SKILL: 'Skill Name',
    END_DATE: 'End Date',
    PROJECT_MANAGER: 'Project Manager',
    OZ_LINK: 'Oz Link',
    CATEGORY_PLACEHOLDER: 'Select Category',
    CLIENT_PLACEHOLDER: 'Select Client Name',
    CLIENT_ENTER_PLACEHOLDER: 'Enter Client Name',
    PROJECT_PLACEHOLDER: 'Select Project Name',
    PROJECT_ENTER_PLACEHOLDER: 'Enter Project Name',
    INDUSTRY_PLACEHOLDER: 'Select Industry',
    OZ_LINK_PLACEHOLDER: 'Enter Oz Link',
    PROJECT_MANAGER_PLACEHOLDER: 'Enter Manager Name',
    START_DATE_PLACEHOLDER: 'Select Start Date',
    UPLOAD_SKILL_ICON: 'Upload a Skill Icon',
    SKILL_ICON_TEXT: 'SVG, PNG, JPG',
    END_DATE_PLACEHOLDER: 'Select End Date',
    SKILL_PLACEHOLDER: 'Select Skill Name',
  },
};

export default labels;
