import React, { useState, useEffect, type FC } from 'react';
import Button from 'components/common/Button';
import Input from 'components/common/Input';
import { toast } from 'react-toastify';
import { SearchProps } from './SearchType';
import Tooltip from 'components/common/Tooltip';
import LABELS from 'app/labels';
import './Search.scss';

const Search: FC<SearchProps> = ({
  prefillData,
  onHandleSearch,
  onChangeHandler,
}) => {
  const [searchValue, setSearchValue] = useState('');

  enum TooltipPosition {
    Top = 'top',
    Bottom = 'bottom',
    Right = 'right',
    Left = 'left',
  }

  const {
    TOOLTIP: { SEARCH_CONDITION_TEXT, SEARCH_PLACEHOLDER_TEXT },
  } = LABELS;

  useEffect(() => {
    setSearchValue(prefillData);
  }, [prefillData]);

  useEffect(() => {
    onChangeHandler(searchValue);
  }, [searchValue]);

  const handleSearch = () => {
    if (searchValue.length >= 3) {
      onHandleSearch(searchValue);
    } else {
      toast.error(SEARCH_CONDITION_TEXT);
    }
  };

  return (
    <>
      <Input
        type="text"
        id="containsSearchBar"
        inputVal={searchValue}
        className="input__search"
        onChange={(e) => setSearchValue(e)}
        placeholder={SEARCH_PLACEHOLDER_TEXT}
        onKeyPress={handleSearch}
      />
      {searchValue.length < 3 ? (
        <Tooltip
          title={SEARCH_CONDITION_TEXT}
          position={TooltipPosition.Bottom}
        >
          <Button
            className="button__search"
            value="Search"
            isSecondary
            isShowIcon
            iconClassName="icon-search"
            onClick={handleSearch}
            disabled={searchValue.length < 3}
          />
        </Tooltip>
      ) : (
        <Button
          className="button__search"
          value="Search"
          isSecondary
          isShowIcon
          iconClassName="icon-search"
          onClick={handleSearch}
          disabled={searchValue.length < 3}
        />
      )}
    </>
  );
};

export default Search;
