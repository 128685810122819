import { type FC } from 'react';
import { AvatarProps } from './AvatarTypes';
import { getShortName } from 'utils/getShortName';
import './Avatar.scss';

const Avatar: FC<AvatarProps> = ({
  src,
  alt,
  height = 50,
  width = 50,
}: AvatarProps) => {
  const name: string = getShortName(alt) || '';

  return (
    <div className={`avatar h-${height}p w-${width}p`}>
      {src ? (
        <img src={src} alt={alt} className="avatar__img" loading="lazy" />
      ) : (
        <span className="avatar__name">{name}</span>
      )}
    </div>
  );
};

export default Avatar;
