import { type FC } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonProps } from './SkeletonLoaderType';
import './SkeletonLoader.scss';

const SkeletonLoader: FC<SkeletonProps> = ({
  skeletonWidth,
  skeletonHeight,
  skeletonCount,
}) => (
  <SkeletonTheme baseColor="#d9d9d9" highlightColor="#fefefe">
    <Skeleton
      duration={0.5}
      width={skeletonWidth}
      height={skeletonHeight}
      count={skeletonCount}
    />
  </SkeletonTheme>
);

export default SkeletonLoader;
