import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import CONFIG from 'app/config';
import LABELS from 'app/labels';
import DatePickerForm from 'components/common/DatePicker';
import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import InputBox from 'components/common/InputBox';
import PageHeader from 'components/AddOrEditEmployee/TabPages/PageHeader';
import { ProjectData } from 'components/ProjectManagement/Types/ProjectManagementTypes';
import {
  EmployeeProjectDetails,
  ProjectDetailsProps,
} from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import { getProjectData } from 'redux/services/projectManagement/projectManagementService';
import Loader from 'components/common/Loader';
import { Filter } from 'components/common/Dropdown/DropdownType';
import { initialProjectsDetails } from 'components/AddOrEditEmployee/constants/AddOrEditEmployeeConstants';
import { isObjectPresentInArray } from 'utils/isObjectPresentInArray';
import { findObjectPresentInArray } from 'utils/loadEmployeeDetailsFormDataFromStore';
import './ProjectDetails.scss';

const ProjectDetails: FC<ProjectDetailsProps> = ({
  projectDetailsList,
  clientProjectDetailsList,
  isLoading,
  setClientProjectDetailsList,
  onCancel,
  onPrevious,
  onSubmit,
}) => {
  const {
    ADD_OR_EDIT_EMPLOYEE: {
      CANCEL,
      PREVIOUS,
      SUBMIT,
      PROJECT_DETAILS: {
        PROJECT_TAB_LABEL,
        PROJECT_TAB_LABEL_MSG,
        CLIENT_LABEL,
        PROJECT_LABEL,
        PROJECT_PLACEHOLDER,
        CLIENT_PLACEHOLDER,
        JOIN_DATE_LABEL,
        JOIN_DATE_PLACEHOLDER,
        LEAVING_DATE_LABEL,
        LEAVING_DATE_PLACEHOLDER,
        PROJECT_MANAGER_LABEL,
        PROJECT_MANAGER_PLACEHOLDER,
        ADD_PROJECT,
        SELECT_PROJECT_ERROR,
        SELECT_PRJ_JOINING_DATE_ERROR,
        ALREADY_SELECTED_PROJECT_ERROR,
      },
    },
  } = LABELS;

  const [selectedProjects, setSelectedProjects] = useState<
    EmployeeProjectDetails[]
  >([...initialProjectsDetails]);
  const [isMount, setIsMount] = useState(false);
  const [isJoiningDateError, setIsJoiningDateError] = useState(false);
  const [projectNames, setProjectNames] = useState<Filter[]>();
  const [projectData, setProjectData] = useState<ProjectData[]>([]);

  useEffect(() => {
    if (!isMount && projectDetailsList && projectDetailsList.length > 0) {
      setSelectedProjects([...projectDetailsList, ...selectedProjects]);
      setIsMount(true);
    }

    const fetchClientProjectDetails = async () => {
      const response = await axios.get(`${CONFIG.BASE_URL}/projects`);
      const data = response.data;
      setClientProjectDetailsList(data);
      setIsMount(true);
    };

    if (clientProjectDetailsList?.length < 1) {
      fetchClientProjectDetails();
    }

    getProjectData().then((projectResponse) =>
      setProjectData([...projectResponse])
    );

    return () => {
      setIsMount(false);
    };
  }, []);

  const getClientDetails = () => {
    const clientDetails = clientProjectDetailsList.map(
      ({ clientName }) => clientName
    );

    return clientDetails.filter(
      (value, index) => clientDetails.indexOf(value) === index
    );
  };

  const onHandleChange = (
    id: string,
    changedValue: Filter[] | Date | string | null,
    currentIndex: number
  ) => {
    let updatedProjects: EmployeeProjectDetails[] = [];
    if (changedValue && id === 'projectDetails') {
      changedValue = changedValue as Filter[];
      if (changedValue && changedValue.length > 0) {
        const name = changedValue[0].name;

        const clientProjectDetails =
          clientProjectDetailsList &&
          clientProjectDetailsList.length > 0 &&
          findObjectPresentInArray(
            clientProjectDetailsList,
            'projectName',
            name
          );

        if (!isObjectPresentInArray(selectedProjects, 'name', name)) {
          updatedProjects = selectedProjects.map((projectDetails, index) =>
            index === currentIndex
              ? {
                  ...projectDetails,
                  ['name']: name,
                  ...(clientProjectDetails &&
                    clientProjectDetails.length > 0 && {
                      ['clientName']: clientProjectDetails[0].clientName,
                      ['managerName']: clientProjectDetails[0].projectManager,
                      ['ozLink']: clientProjectDetails[0].ozLink,
                    }),
                }
              : projectDetails
          );
        } else {
          toast.error(
            <>
              <div>{`${changedValue[0].name}.`}</div>
              <div>{ALREADY_SELECTED_PROJECT_ERROR}</div>
            </>
          );
        }
      }
    } else if (changedValue && id === 'clientName') {
      changedValue = changedValue as Filter[];
      const updatedName = changedValue[0].name;
      updatedProjects = selectedProjects.map((projectDetails, index) =>
        index === currentIndex
          ? { ...projectDetails, ['clientName']: updatedName }
          : projectDetails
      );

      const projects = projectData
        .filter((project) => project.clientName === updatedName)
        .map((filteredProject) => ({
          id: filteredProject.projectName,
          name: filteredProject.projectName,
        }));
      setProjectNames([...projects]);
    } else {
      updatedProjects = selectedProjects.map((projectDetails, index) =>
        index === currentIndex
          ? { ...projectDetails, [id]: changedValue }
          : projectDetails
      );
    }
    if (updatedProjects.length > 0) {
      setSelectedProjects(updatedProjects);
    }
  };

  const handleAddProjectDetails = () => {
    if (
      selectedProjects &&
      selectedProjects[selectedProjects.length - 1].name !== ''
    ) {
      if (selectedProjects[selectedProjects.length - 1].joiningDate !== '') {
        setIsJoiningDateError(false);
        setSelectedProjects([...selectedProjects, ...initialProjectsDetails]);
      } else {
        setIsJoiningDateError(true);
        toast.error(SELECT_PRJ_JOINING_DATE_ERROR);
      }
    } else {
      toast.error(SELECT_PROJECT_ERROR);
    }
  };

  const handleProjectDetailsSubmit = () => {
    const filteredProjects = selectedProjects.filter(
      (project) => project.name != ''
    );
    if (filteredProjects[filteredProjects.length - 1]?.joiningDate !== '') {
      setIsJoiningDateError(false);
      onSubmit(filteredProjects);
    } else {
      setIsJoiningDateError(true);
      toast.error(SELECT_PRJ_JOINING_DATE_ERROR);
    }
  };

  const renderProjectDetails = (
    projectDetails: EmployeeProjectDetails,
    index: number
  ) => (
    <div
      key={index}
      className="projectDetailsContainer__project-details__add-project-wrapper__input-wrapper"
    >
      <Dropdown
        id="clientDetails"
        inputName="client-details"
        disabledDropdown={
          projectDetailsList?.length > 0 &&
          isObjectPresentInArray(
            projectDetailsList,
            'name',
            projectDetails.name
          )
        }
        showLabel={index == 0}
        labelText={CLIENT_LABEL}
        placeholder={CLIENT_PLACEHOLDER}
        list={getClientDetails().map((clientDetail) => ({
          id: `${clientDetail}`,
          name: `${clientDetail}`,
        }))}
        filterLabel={
          projectDetails.clientName !== '' ? `${projectDetails.clientName}` : ''
        }
        prefillData={[
          {
            id: `${projectDetails.clientName}`,
            name: `${projectDetails.clientName}`,
          },
        ]}
        onSelect={(val) => onHandleChange('clientName', val, index)}
      />
      <Dropdown
        id="projectDetails"
        inputName="project-details"
        disabledDropdown={
          projectDetailsList?.length > 0 &&
          isObjectPresentInArray(
            projectDetailsList,
            'name',
            projectDetails.name
          )
        }
        showLabel={index == 0}
        labelText={PROJECT_LABEL}
        placeholder={PROJECT_PLACEHOLDER}
        list={[...(projectNames ?? [])]}
        filterLabel={projectDetails.name !== '' ? `${projectDetails.name}` : ''}
        prefillData={[
          {
            id: `${projectDetails.name}`,
            name: `${projectDetails.name}`,
          },
        ]}
        onSelect={(val) => onHandleChange('projectDetails', val, index)}
      />
      <DatePickerForm
        id="joinDate"
        name="joinDate"
        className="remove-margin"
        showLabel={index == 0}
        labelText={JOIN_DATE_LABEL}
        placeholder={JOIN_DATE_PLACEHOLDER}
        value={
          projectDetails.joiningDate
            ? new Date(projectDetails.joiningDate)
            : undefined
        }
        onChange={(val) => onHandleChange('joiningDate', val, index)}
        showError={isJoiningDateError && projectDetails.joiningDate == ''}
      />
      <DatePickerForm
        id="leavingDate"
        name="leavingDate"
        className="remove-margin"
        showLabel={index == 0}
        labelText={LEAVING_DATE_LABEL}
        placeholder={LEAVING_DATE_PLACEHOLDER}
        value={
          projectDetails.leavingDate
            ? new Date(projectDetails.leavingDate)
            : undefined
        }
        onChange={(val) => onHandleChange('leavingDate', val, index)}
      />
      <InputBox
        disabled={
          projectDetailsList?.length > 0 &&
          isObjectPresentInArray(
            projectDetailsList,
            'managerName',
            projectDetails.managerName
          )
        }
        type="text"
        id="managerName"
        name="managerName"
        className="remove-margin"
        showLabel={index == 0}
        labelText={PROJECT_MANAGER_LABEL}
        placeholder={PROJECT_MANAGER_PLACEHOLDER}
        showIcon
        iconClassName="user-circular"
        inputVal={projectDetails.managerName}
        onChange={(val) => onHandleChange('managerName', val, index)}
      />
      {/* <div className="ozLink">
        <InputBox
          disabled={
            projectDetailsList?.length > 0 &&
            isObjectPresentInArray(
              projectDetailsList,
              'ozLink',
              projectDetails.ozLink
            )
          }
          type="text"
          id="ozLink"
          name="ozLink"
          className="remove-margin"
          showLabel={index == 0}
          labelText={OZ_LINK_LABEL}
          placeholder={OZ_LINK_PLACEHOLDER}
          showIcon
          iconClassName="link"
          inputVal={projectDetails.ozLink}
          onChange={(val) => onHandleChange('ozLink', val, index)}
        />
      </div> */}
    </div>
  );

  return (
    <div className="projectDetailsContainer">
      {isLoading && <Loader />}
      <div className="projectDetailsContainer__project-details">
        <PageHeader
          title={PROJECT_TAB_LABEL}
          subtitle={PROJECT_TAB_LABEL_MSG}
        />
        <div className="projectDetailsContainer__project-details__add-project-wrapper">
          {selectedProjects.map((projectDetails, index) =>
            renderProjectDetails(projectDetails, index)
          )}
        </div>
        <Button
          className="projectDetailsContainer__project-details__add-project-wrapper__button"
          isShowIcon
          iconClassName="icon-plus"
          onClick={handleAddProjectDetails}
          value={ADD_PROJECT}
          isSecondary
          data-testid="add-projects-button"
        />
      </div>
      <div className="projectDetailsContainer__footer">
        <Button
          className="projectDetailsContainer__btn-cancel"
          value={CANCEL}
          onClick={onCancel}
          isSecondary
          data-testid="card-body-buttons-cancel"
        />
        <Button
          className="projectDetailsContainer__btn-previous"
          value={PREVIOUS}
          onClick={onPrevious}
          isSecondary
          data-testid="card-body-buttons-next"
        />
        <Button
          className="projectDetailsContainer__btn-next"
          value={SUBMIT}
          onClick={handleProjectDetailsSubmit}
          data-testid="card-body-buttons-submit"
        />
      </div>
    </div>
  );
};
export default ProjectDetails;
