import { FC } from 'react';
import DatePickerForm from 'components/common/DatePicker';
import { EmployeeStatusProps } from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import './AddOrEditHeader.scss';

const EmployeeStatus: FC<EmployeeStatusProps> = ({
  employeeStatusData,
  setEmployeeStatusData,
}) => (
  <div className="addoredit-header__subheader__rightside">
    <div className="addoredit-header__subheader__rightside__checkbox-container">
      <input
        type="checkbox"
        checked={employeeStatusData.inActive}
        onChange={() =>
          setEmployeeStatusData({
            ...employeeStatusData,
            inActive: !employeeStatusData.inActive,
          })
        }
      />
      <label> InActive</label>
    </div>
    <DatePickerForm
      id="lwd-details"
      name="lwd-details"
      disabled={!employeeStatusData.inActive}
      placeholder={'Last Working Day'}
      value={employeeStatusData.lastWorkingDay}
      onChange={(val) =>
        setEmployeeStatusData({
          ...employeeStatusData,
          lastWorkingDay: val || undefined,
        })
      }
    />
  </div>
);

export default EmployeeStatus;
