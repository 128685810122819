import { FC, useEffect, useState } from 'react';
import LABELS from 'app/labels';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import Dropdown from 'components/common/Dropdown';
import InputBox from 'components/common/InputBox';
import FileUploader from 'components/common/FileUploader';
import { ViewOrEditSkillProps } from 'components/ProjectManagement/Types/SkillManagementProps';
import { Filter } from 'components/common/Dropdown/DropdownType';
import { InitialSelectedDropDownvalue } from 'components/ProjectManagement/Constants/ProjectManagementConstants';
import {
  deleteSkill,
  editSkill,
} from 'redux/services/projectManagement/projectManagementService';
import './ViewOrEditSkill.scss';

const ViewOrEditSkill: FC<ViewOrEditSkillProps> = ({ skillData, onReload }) => {
  const {
    PROJECT_MANAGEMENT: {
      SUBMIT_BTN_LABEL,
      EDIT_SKILL_BTN_LABEL,
      DELETE_SKILL_BTN_LABEL,
      CANCEL_BTN_LABEL,
      CATEGORY,
      CATEGORY_PLACEHOLDER,
      SKILL,
      SKILL_PLACEHOLDER,
      UPLOAD_SKILL_ICON,
      SKILL_ICON_TEXT,
    },
  } = LABELS;
  const [skillCategory, setSkillCategory] = useState<Filter[]>();
  const [selectedSkillCategory, setSelectedSkillCategory] = useState<Filter>(
    InitialSelectedDropDownvalue
  );
  const [skillNames, setSkillNames] = useState<Filter[]>();
  const [selectedSkillName, setSelectedSkillName] = useState<Filter>(
    InitialSelectedDropDownvalue
  );
  const [selectedSkillIcon, setSelectedSkillIcon] = useState<File>();
  const [storedSkillIcon, setStoredSkillIcon] = useState<string>();
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const category = skillData
      .filter(
        (skill, index, self) =>
          index ===
          self.findIndex((item) => item.skillCategory === skill.skillCategory)
      )
      .map((filteredProject) => ({
        id: filteredProject.id,
        name: filteredProject.skillCategory,
      }));
    setSkillCategory(category);
  }, [skillData]);

  const clearSelections = () => {
    setSelectedSkillCategory(InitialSelectedDropDownvalue);
    setSelectedSkillName(InitialSelectedDropDownvalue);
  };

  const handleCancel = () => {
    clearSelections();
    setIsEditModeOn(false);
  };

  const titleCase = (keyName: string) =>
    keyName
      .toLowerCase()
      .split(' ')
      .map((word, index) => {
        if (index === 0) return word;

        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');

  const handleSubmit = () => {
    setIsSubmitted(true);
    const requestPayload = {
      id: selectedSkillCategory.id,
      name: selectedSkillName.name,
      skillCategory: selectedSkillCategory.name,
      skillKey: titleCase(selectedSkillName.name),
      skillIconImage: selectedSkillIcon,
    };

    const skillDataBlob = new Blob([JSON.stringify(requestPayload)], {
      type: 'application/json',
    });

    editSkill(skillDataBlob).then(() => {
      setIsSubmitted(false);
      handleCancel();
      onReload();
    });
  };

  const handleDelete = () => {
    setIsSubmitted(true);
    deleteSkill(selectedSkillCategory.id).then(() => {
      setIsSubmitted(false);
      handleCancel();
      onReload();
    });
  };

  const handleSelectSkillCategory = (skillCategoryName: Filter) => {
    setSelectedSkillCategory(skillCategoryName);

    const skills = skillData
      .filter((skill) => skill.skillCategory === skillCategoryName.name)
      .map((filteredSkill) => ({
        id: filteredSkill.id,
        name: filteredSkill.name,
      }));
    setSkillNames([...skills]);
  };

  const handleSelectSkill = (skillName: Filter) => {
    const selectedSkill = skillData.filter(
      (skill) =>
        skill.skillCategory === selectedSkillCategory.name &&
        skill.name === skillName.name
    )[0];

    setSelectedSkillName({ id: selectedSkill.id, name: selectedSkill.name });
    setStoredSkillIcon(selectedSkill.skillIconImage);
  };

  const renderSkillDetails = () => (
    <div className="viewOrEditSkillContainer__body__skill-section">
      {!isEditModeOn ? (
        <>
          <Dropdown
            id="category-dropdown"
            inputName="category-dropdown"
            disabledDropdown={false}
            showLabel
            showSelectedOptionsCount={false}
            useFormStyles
            labelText={CATEGORY}
            placeholder={CATEGORY_PLACEHOLDER}
            list={skillCategory}
            filterLabel={selectedSkillCategory?.name ?? ''}
            prefillData={[selectedSkillCategory]}
            onSelect={(category) => handleSelectSkillCategory(category[0])}
          />
          <Dropdown
            id="skills-dropdown"
            inputName="skillsdropdown"
            disabledDropdown={selectedSkillCategory.name === ''}
            showLabel
            showSelectedOptionsCount={false}
            useFormStyles
            labelText={SKILL}
            placeholder={SKILL_PLACEHOLDER}
            list={[...(skillNames ?? [])]}
            filterLabel={selectedSkillName?.name ?? ''}
            prefillData={[selectedSkillName]}
            onSelect={(skillName) => handleSelectSkill(skillName[0])}
          />
        </>
      ) : (
        <>
          <InputBox
            disabled={false}
            type="text"
            id="skillCategory"
            name="skillCategory"
            showLabel={true}
            labelText={CATEGORY + '*'}
            placeholder={CATEGORY_PLACEHOLDER}
            inputVal={selectedSkillCategory?.name ?? ''}
            onChange={(category) => {
              setSelectedSkillCategory({
                id: selectedSkillCategory.id,
                name: category,
              });
            }}
          />
          <InputBox
            disabled={false}
            type="text"
            id="skillName"
            name="skillName"
            showLabel={true}
            labelText={SKILL + '*'}
            placeholder={SKILL_PLACEHOLDER}
            inputVal={selectedSkillName?.name ?? ''}
            onChange={(skillName) => {
              setSelectedSkillName({
                id: selectedSkillName.id,
                name: skillName,
              });
            }}
          />
        </>
      )}
    </div>
  );

  const renderSkillIconBrowse = () => (
    <div className="viewOrEditSkillContainer__body__skill-icon">
      <FileUploader
        id="skillIcon"
        disabledFileUploader={!isEditModeOn}
        showIcon
        iconClassName="icon-image"
        showLabel
        labelTitleText="Skill Icon"
        labelFileText={SKILL_ICON_TEXT}
        description={UPLOAD_SKILL_ICON}
        alreadySelectedFile={selectedSkillIcon}
        handleFileSelection={(icon) => setSelectedSkillIcon(icon ?? undefined)}
      />
      <div className="viewOrEditSkillContainer__body__skill-icon__name">
        {selectedSkillIcon
          ? selectedSkillIcon.name
          : storedSkillIcon && `${selectedSkillName.name}.png`}
      </div>
    </div>
  );

  return (
    <div className="viewOrEditSkillContainer">
      {isSubmitted && <Loader />}
      <div className="viewOrEditSkillContainer__body">
        {renderSkillDetails()}
        {renderSkillIconBrowse()}
      </div>

      <div className="viewOrEditSkillContainer__footer">
        {!isEditModeOn ? (
          <>
            <Button
              disabled={selectedSkillName.name === ''}
              className="viewOrEditSkillContainer__footer__btn-next"
              value={EDIT_SKILL_BTN_LABEL}
              onClick={() => setIsEditModeOn(true)}
              isSecondary
              data-testid="card-body-buttons-submit"
            />
            <Button
              disabled={selectedSkillName.name === ''}
              className="viewOrEditSkillContainer__footer__btn-next"
              value={DELETE_SKILL_BTN_LABEL}
              onClick={() => handleDelete()}
              data-testid="card-body-buttons-delete"
            />
          </>
        ) : (
          <>
            <Button
              className="viewOrEditSkillContainer__footer__btn-cancel"
              value={CANCEL_BTN_LABEL}
              onClick={handleCancel}
              isSecondary
              data-testid="card-body-buttons-cancel"
            />
            <Button
              className="viewOrEditSkillContainer__footer__btn-next"
              disabled={isSubmitted}
              value={SUBMIT_BTN_LABEL}
              onClick={handleSubmit}
              data-testid="card-body-buttons-submit"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ViewOrEditSkill;
