import { useEffect, type FC, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'types/hooks';
import { useMsal } from '@azure/msal-react';
import Modal from 'react-modal';
import Dropdown from 'components/common/Dropdown';
import Button from 'components/common/Button';
import Search from 'components/Search';
import Card from 'components/common/Card';
import {
  getFiltersData,
  getEmployeesData,
  fetchUserInfo,
} from 'redux/services/employeeDirectory/employeeDirectoryService';
import {
  updateFilters,
  resetFilters,
  selectedViewProfileId,
  selectFilterData,
  selectEmpAvailibity,
  isLoadFilterData,
  selectedFilters,
  selectEmployeesData,
  isFetchingEmployeeData,
  fetchTotalEmployeesCount,
  isEmployeeEditModeOn,
  setEmployeeEditModeOn,
  selectSortbyOptions,
} from 'redux/slices/employeeDirectory/employeeDirectorySlice';
import { Filter } from 'components/common/Dropdown/DropdownType';
import NoResultFound from 'components/common/NoResultFound';
import {
  EmployeeDirectoryFilterLoader,
  EmployeeCardsLoader,
} from './EmployeeDirectoryLoader';
import AddorEditEmployee from 'components/AddOrEditEmployee';
import UserProfile from 'components/common/UserProfile';
import {
  ComponentPayload,
  FilterList,
  SkillsFilter,
} from './EmployeeDirectoryType';
import { getFilterByName } from 'utils/getFilterByName';
import LABELS from 'app/labels';
import { DEFAULT_PAYLOAD } from 'app/constants';
import { enableFeature, isAdmin } from 'utils/fetchSessionInfo';
import { skillLevelData } from 'data/mockSkillLevelData';
import './EmployeeDirectory.scss';

const EmployeeDirectory: FC = () => {
  const { instance } = useMsal();

  const { skill, location, experienceLevel, department, industries } =
    useAppSelector(selectFilterData) || {};
  const employeeAvailibity = useAppSelector(selectEmpAvailibity);
  const sortbyList = useAppSelector(selectSortbyOptions);
  const selectedFilterList = useAppSelector(selectedFilters);
  const employeesList = useAppSelector(selectEmployeesData);
  const isLoadFilters = useAppSelector(isLoadFilterData);
  const isFetchingEmployeeList = useAppSelector(isFetchingEmployeeData);
  const totalEmployeesCount = useAppSelector(fetchTotalEmployeesCount);
  const isEmployeeEditMode = useAppSelector(isEmployeeEditModeOn);
  const dispatch = useAppDispatch();

  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [isSkillLevelModalOpen, setIsSkillLevelModalOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([] as SkillsFilter[]);
  const [currentlySelectedSkillLevels, setCurrentlySelectedSkillLevels] =
    useState([] as FilterList[]);
  const [blobUrl, setBlobUrl] = useState('');
  const [sortbyValue, setSortbyValue] = useState<string>('');
  const userName = instance.getActiveAccount()?.name;
  const [checked, setChecked] = useState(false);
  const [disableResetBtn, setDisableResetBtn] = useState(true);

  const {
    DIRECTORY: {
      WELCOME_TEXT,
      ACTIVE_EMPLOYEE,
      FILTER_BY_TEXT,
      PRACTICE,
      INDUSTRY,
      DEPARTMENT_FILTER_TEXT,
      INDUSTRY_FILTER_TEXT,
      LOCATION,
      LOCATION_FILTER_TEXT,
      EXPERIENCE_LEVEL,
      EXPERIENCE_LEVEL_FILTER_TEXT,
      SKILLS,
      SKILLS_FILTER_TEXT,
      SKILL_LEVEL,
      SKILL_LEVEL_SELECT,
      EMPLOYEE_AVAILABILITY,
      EMPLOYEE_AVAILABILITY_FILTER_TEXT,
      EMPLOYEE_STATUS_FILTER_TEXT,
      RESET,
      SEARCH_STRING,
    },
  } = LABELS;

  const fetchUserInformation = async () => {
    const blobObj = await fetchUserInfo();
    if (blobObj) {
      const url = URL.createObjectURL(blobObj);
      setBlobUrl(url);
    }
  };

  useEffect(() => {
    const selectedOption = checked ? 'inactive' : 'active';
    handleSelectOption(selectedOption, EMPLOYEE_STATUS_FILTER_TEXT);
  }, [checked]);

  const handleChangeStatus = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    dispatch(getFiltersData());
    dispatch(getEmployeesData({ searchRequest: { ...DEFAULT_PAYLOAD } }));
    fetchUserInformation();
  }, []);

  useEffect(() => {
    if (
      selectedFilterList.department.length == 0 &&
      selectedFilterList.employeeAvailablity.length == 0 &&
      selectedFilterList.experienceLevel.length == 0 &&
      selectedFilterList.industries.length == 0 &&
      selectedFilterList.location.length == 0 &&
      selectedFilterList.searchString.length == 0 &&
      selectedFilterList.sortby.length == 0 &&
      selectedFilterList.status.length == 6
    )
      setDisableResetBtn(true);
    else {
      setDisableResetBtn(false);
    }
  }, [selectedFilterList]);

  const getRequestPayload = (
    selectedFiltersOptions: ComponentPayload,
    sortBy: string
  ) => ({
    searchRequest: {
      searchString: selectedFiltersOptions.searchString
        .trim()
        .split(' ')
        .join(','),
      skills: selectedFiltersOptions.skills,
      department: getFilterByName(selectedFiltersOptions.department),
      location: getFilterByName(selectedFiltersOptions.location),
      experienceLevel: getFilterByName(selectedFiltersOptions.experienceLevel),
      availability:
        selectedFiltersOptions?.employeeAvailablity.length > 0
          ? selectedFiltersOptions?.employeeAvailablity?.[0]?.id.toLowerCase()
          : 'all',
      status: checked ? 'inactive' : 'active',
      industries: getFilterByName(selectedFiltersOptions.industries),
    },
    sortBy: sortBy,
  });

  const handleSelectOption = (
    selectedFilter: Array<Filter> | Array<SkillsFilter> | string,
    filterName: string,
    sortBy = '',
    isSearch = true
  ) => {
    dispatch(updateFilters({ selectedFilter, filterName }));
    if (isSearch) {
      const selectedFiltersOptions = {
        ...selectedFilterList,
        [filterName]: selectedFilter,
      };
      const payload = getRequestPayload(selectedFiltersOptions, sortBy);
      dispatch(getEmployeesData(payload));
    }
  };

  const handleSearch = (
    searchText: string,
    filterName = SEARCH_STRING,
    sortBy = ''
  ) => {
    handleSelectOption(searchText, filterName, sortBy);
  };

  const handleOnSearchChange = (
    searchText: string,
    filterName = SEARCH_STRING,
    sortBy = ''
  ) => {
    handleSelectOption(searchText, filterName, sortBy, false);
  };

  const onResetFilter = () => {
    dispatch(resetFilters());
    dispatch(
      getEmployeesData({ searchRequest: { ...DEFAULT_PAYLOAD }, sortBy: '' })
    );
  };

  const onCancel = () => {
    setShowAddEmployee(false);
    if (isEmployeeEditMode) {
      dispatch(setEmployeeEditModeOn(false));
      dispatch(selectedViewProfileId(''));
    }
  };

  const onSkillSelect = (skills: Filter[]) => {
    let formattedSkills = [...selectedSkills];
    if (skills.length > formattedSkills.length) {
      const lastSkill = skills[skills.length - 1];
      formattedSkills.push({
        id: lastSkill.id,
        name: lastSkill.name,
        level: [],
      });
      setIsSkillLevelModalOpen(true);
    } else {
      if (formattedSkills.length > 1) {
        const selectedSkillNames = [] as string[];
        skills.forEach((skill) => {
          selectedSkillNames.push(skill.name);
        });
        const removedSkill = formattedSkills.filter(
          (skill) => !selectedSkillNames.includes(skill.name)
        );
        formattedSkills.splice(formattedSkills.indexOf(removedSkill[0]), 1);
      } else {
        formattedSkills = [];
      }
      handleSelectOption(formattedSkills, SKILLS_FILTER_TEXT);
    }
    setSelectedSkills(formattedSkills);
  };

  const onSkillLevelSelect = (skillLevel: Filter[]) => {
    const formattedSkills = selectedSkills;
    formattedSkills[formattedSkills.length - 1].level = skillLevel.map(
      (skill) => skill.name
    );
    setCurrentlySelectedSkillLevels(skillLevel);
    setSelectedSkills(selectedSkills);
  };

  const onSkillLevelModalClose = () => {
    handleSelectOption(selectedSkills, SKILLS_FILTER_TEXT);
    setCurrentlySelectedSkillLevels([]);
    setIsSkillLevelModalOpen(false);
  };

  const renderSkillLevelModal = () => (
    <Modal
      isOpen={isSkillLevelModalOpen}
      onRequestClose={() => onSkillLevelModalClose()}
      ariaHideApp={false}
      className="skillLevelModalContainer"
      overlayClassName="skillLevelModalOverlay"
    >
      <div>{SKILL_LEVEL_SELECT}</div>
      <Dropdown
        className="skillLevelModalDropdown"
        list={skillLevelData}
        prefillData={currentlySelectedSkillLevels}
        filterLabel={SKILL_LEVEL}
        isMultiSelect
        onSelect={(selectedOption) => {
          onSkillLevelSelect(selectedOption);
        }}
      />
      <Button value="Close" onClick={() => onSkillLevelModalClose()} />
    </Modal>
  );

  return (
    <>
      {showAddEmployee || isEmployeeEditMode ? (
        <AddorEditEmployee
          onCancel={onCancel}
          blobUrl={blobUrl}
          userName={userName}
          isEditMode={isEmployeeEditMode}
        />
      ) : (
        <div className="directory">
          <div className="directory__header">
            <div className="directory__header__leftblock">
              <div className="directory__header-title">Employee Directory</div>
              <div className="directory__header-active-emp">
                <span>{totalEmployeesCount}</span>
                {ACTIVE_EMPLOYEE}
              </div>
              {(enableFeature(process.env.REACT_APP_ENV) || isAdmin()) && (
                <Button
                  className="directory__add-employee"
                  value="Add Employee"
                  isShowIcon
                  onClick={() => setShowAddEmployee(true)}
                />
              )}
            </div>
            <UserProfile blobUrl={blobUrl} userName={userName} />
          </div>

          <div className="directory__subheader">
            {WELCOME_TEXT} <span>{instance.getActiveAccount()?.name}</span>!
          </div>
          <div className="directory__searchbar">
            <Search
              onHandleSearch={(searchText) => handleSearch(searchText)}
              onChangeHandler={(searchText) => handleOnSearchChange(searchText)}
              prefillData={selectedFilterList.searchString}
            />
          </div>
          {isLoadFilters ? (
            <EmployeeDirectoryFilterLoader />
          ) : (
            <div className="directory__filters">
              {renderSkillLevelModal()}
              <span>{FILTER_BY_TEXT}</span>
              <Dropdown
                className="directory__filters__practice"
                list={department}
                filterLabel={PRACTICE}
                prefillData={selectedFilterList.department}
                isMultiSelect
                onSelect={(selectedOption) =>
                  handleSelectOption(selectedOption, DEPARTMENT_FILTER_TEXT)
                }
              />
              <Dropdown
                className="directory__filters__industry"
                list={industries}
                filterLabel={INDUSTRY}
                prefillData={selectedFilterList.industries}
                isMultiSelect
                onSelect={(selectedOption) =>
                  handleSelectOption(selectedOption, INDUSTRY_FILTER_TEXT)
                }
              />
              <Dropdown
                className="directory__filters__location"
                list={location}
                filterLabel={LOCATION}
                prefillData={selectedFilterList.location}
                isMultiSelect
                onSelect={(selectedOption) =>
                  handleSelectOption(selectedOption, LOCATION_FILTER_TEXT)
                }
              />
              <Dropdown
                list={experienceLevel}
                prefillData={selectedFilterList.experienceLevel}
                isMultiSelect
                filterLabel={EXPERIENCE_LEVEL}
                onSelect={(selectedOption) =>
                  handleSelectOption(
                    selectedOption,
                    EXPERIENCE_LEVEL_FILTER_TEXT
                  )
                }
              />
              <Dropdown
                className="directory__filters__skills"
                list={skill}
                filterLabel={SKILLS}
                prefillData={selectedFilterList.skills}
                isMultiSelect
                onSelect={(selectedOption) => onSkillSelect(selectedOption)}
              />
              <Dropdown
                list={employeeAvailibity}
                filterLabel={EMPLOYEE_AVAILABILITY}
                prefillData={selectedFilterList.employeeAvailablity}
                disabledSearchBar
                onSelect={(selectedOption) =>
                  handleSelectOption(
                    selectedOption,
                    EMPLOYEE_AVAILABILITY_FILTER_TEXT
                  )
                }
              />
              <Button
                value={RESET}
                onClick={onResetFilter}
                isButtonLink
                disabled={disableResetBtn}
              />
            </div>
          )}
          {isFetchingEmployeeList ? (
            <EmployeeCardsLoader />
          ) : (
            <div className="directory__cardsection">
              {employeesList?.length ? (
                <>
                  <div className="directory__cardsection__header">
                    <div className="directory__cardsection-title">
                      {employeesList.length} results found
                    </div>
                    <div className="directory__cardsection__filters">
                      <div className="directory__cardsection__status">
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={handleChangeStatus}
                        />
                        <label> InActive</label>
                      </div>
                      <div className="directory__cardsection-sortby">
                        <span className="directory__cardsection-sortby-label">
                          Sort By:
                        </span>
                        <Dropdown
                          className="directory__filters__practice"
                          list={sortbyList}
                          filterLabel={
                            sortbyValue !== '' ? sortbyValue : 'Select'
                          }
                          showSelectedOptionsCount={false}
                          onSelect={(selectedOption) => {
                            handleSelectOption(
                              selectedOption,
                              selectedOption[0].name,
                              selectedOption[0].id
                            );
                            setSortbyValue(selectedOption[0].name);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="directory__cardsection-cardslist">
                    {employeesList?.length > 0 &&
                      employeesList.map((employee) => (
                        <Card
                          key={employee.rp_id}
                          data={employee}
                          onViewProfile={(id) =>
                            dispatch(selectedViewProfileId(id))
                          }
                        />
                      ))}
                  </div>
                </>
              ) : (
                <NoResultFound />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EmployeeDirectory;
