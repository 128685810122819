import { FC } from 'react';
import Avatar from 'components/common/Avatar';
import { UserProfileProps } from './UserProfileType';
import './UserProfile.scss';

const UserProfile: FC<UserProfileProps> = ({ blobUrl, userName }) => (
  <div className="directory__header__rightblock">
    <div className="directory__header__rightblock-info">
      <Avatar
        src={
          blobUrl ||
          'https://t4.ftcdn.net/jpg/02/29/75/83/360_F_229758328_7x8jwCwjtBMmC6rgFzLFhZoEpLobB6L8.jpg'
        }
        alt="employee-image"
      />
      <span>{userName}</span>
    </div>
  </div>
);
export default UserProfile;
