import { useEffect, useState, type FC } from 'react';
import { CheckboxProps } from './CheckboxType';
import './Checkbox.scss';

const Checkbox: FC<CheckboxProps> = ({
  checked = false,
  className,
  disabled = false,
  id,
  label,
  onChecked,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>, id: string) => {
    setIsChecked(e.target.checked);
    onChecked(id, e.target.checked);
  };

  return (
    <div
      className={`checkbox ${className ? className : ''} ${
        disabled ? 'checkbox__disabled' : ''
      }`}
    >
      <input
        type="checkbox"
        id={id}
        data-testid="checkbox"
        checked={isChecked}
        disabled={disabled}
        onChange={(e) => handleCheck(e, id)}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Checkbox;
