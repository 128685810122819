import { FC, useState, ChangeEvent, useEffect } from 'react';
import { ResumeProps, ResumeData, Assignment } from './ResumeType';
import Button from 'components/common/Button';
import DatePicker from 'components/common/DatePicker';
import './Resume.scss';

const Resume: FC<ResumeProps> = ({
  data,
  shouldEditEnabled = false,
  onSubmit,
}) => {
  const [summary, setSummary] = useState('');
  const [highlightItems, setHighlightItems] = useState<Assignment[]>([]);
  const [educationItems, setEducationItems] = useState<string[]>([]);
  const [trainingItems, setTrainingItems] = useState<string[]>([]);
  const [awardItems, setAwardItems] = useState<string[]>([]);
  const [descriptionItems, setDescriptionItems] = useState<string[][]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSummaryEditing, setIsSummaryEditing] = useState(false);
  const [isHighlightEditing, setIsHighlightEditing] = useState(false);
  const [isEducationEditing, setIsEducationEditing] = useState(false);
  const [isTrainingEditing, setIsTrainingEditing] = useState(false);
  const [isAwardEditing, setIsAwardEditing] = useState(false);

  useEffect(() => {
    setSummary(data?.experienceSummary || '');
    setHighlightItems(data?.significantAssignments || []);
    setEducationItems(data?.education || []);
    setTrainingItems(data?.trainingsAndCertification || []);
    setAwardItems(data?.awardsAndRecognition || []);
    setDescriptionItems(
      data?.significantAssignments?.map((item) => item.description) || []
    );
  }, [data]);

  const handleSummaryChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setSummary(event.target.value);
  };

  const validateEditResume = () => {
    let isValid = true;
    if (
      summary.length <= 0 ||
      highlightItems.length <= 0 ||
      educationItems.length <= 0
    ) {
      isValid = false;
    }

    highlightItems.map((item) => {
      if (
        !item.client ||
        !item.role ||
        item.description.length <= 0 ||
        !item.startDate ||
        !item.endDate
      ) {
        isValid = false;
      }

      item.description.map((description) => {
        if (description.length <= 0) isValid = false;
      });
    });

    educationItems.map((item) => {
      if (item.length <= 0) isValid = false;
    });
    return isValid;
  };

  console.log(validateEditResume());
  const handleAddDescription = (index: number) => {
    const updatedDescriptions = [...descriptionItems];

    if (highlightItems[index].description.length < 3) {
      const newDescriptionArray = [...highlightItems[index].description, ''];
      updatedDescriptions[index] = [...newDescriptionArray];

      setDescriptionItems(updatedDescriptions);
      setHighlightItems([
        ...highlightItems.map((item, hlIndex) => {
          if (hlIndex == index) {
            return { ...item, description: newDescriptionArray };
          }
          return item;
        }),
      ]);
    }
  };

  //TODO: remove once dates are standardized across the application
  const formatDate = (date: string | undefined) => {
    if (date) {
      //Convert from DD/MM/YYYY | MM/YYYY string to date
      const dateResult = date.toString().split('/');
      if (dateResult.length < 2) return new Date();
      if (dateResult.length < 3) {
        const [month, year] = dateResult;
        return new Date(+year, +month - 1, 1);
      }
      const [day, month, year] = dateResult;
      return new Date(+year, +month - 1, +day);
    }

    return undefined;
  };

  const handleDateChange = (
    index: number,
    field: keyof Assignment,
    date: Date | null
  ) => {
    const updatedAssignments = highlightItems.map((item) => ({ ...item }));

    //TODO: update once dates are standardized across the application
    updatedAssignments[index] = {
      ...updatedAssignments[index],
      [field]: date?.toLocaleDateString('en-GB', {
        month: '2-digit',
        year: 'numeric',
      }),
    };
    setHighlightItems(updatedAssignments);
  };

  const handleHighlightChange = (
    index: number,
    field: keyof Assignment,
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    descIndex?: number
  ) => {
    const updatedAssignments = highlightItems.map((item) => ({ ...item }));
    const value = event.target.value;

    if (field === 'description' && typeof descIndex !== 'undefined') {
      const updatedDescriptions = descriptionItems.map((descArray) =>
        descArray ? [...descArray] : []
      );
      updatedDescriptions[index] = updatedDescriptions[index] || [];
      updatedDescriptions[index][descIndex] = value;

      updatedAssignments[index] = {
        ...updatedAssignments[index],
        description: updatedDescriptions[index],
      };

      setDescriptionItems(updatedDescriptions);
    } else {
      updatedAssignments[index] = {
        ...updatedAssignments[index],
        [field]: value,
      };
    }

    setHighlightItems(updatedAssignments);
  };

  const handleEducationChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const updatedItems = [...educationItems];
    const value = event.target.value;

    updatedItems[index] = value ? value : '';
    setEducationItems(updatedItems);
  };

  const handleTrainingChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedItems = [...trainingItems];
    const value = event.target.value;

    updatedItems[index] = value ? value : '';
    setTrainingItems(updatedItems);
  };

  const handleAwardChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedItems = [...awardItems];
    const value = event.target.value;

    updatedItems[index] = value ? value : '';
    setAwardItems(updatedItems);
  };

  const handleEditSummary = () => {
    setIsSummaryEditing(true);
  };

  const handleEditHighlight = () => {
    setIsHighlightEditing(true);
  };

  const handleAddAssignment = () => {
    if (highlightItems.length < 4) {
      setHighlightItems([
        ...highlightItems,
        {
          client: '',
          role: '',
          description: [''],
          startDate: '',
          endDate: '',
        },
      ]);
    }
  };

  const handleAddEducation = () => {
    if (educationItems.length < 4) {
      setEducationItems([...educationItems, '']);
    }
  };

  const handleAddTraining = () => {
    if (trainingItems.length < 4) {
      setTrainingItems([...trainingItems, '']);
    }
  };

  const handleAddAward = () => {
    if (awardItems.length < 4) {
      setAwardItems([...awardItems, '']);
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    const updatedSignificantAssignments = highlightItems
      .map((item) => ({
        ...item,
        description: item?.description?.filter((desc) => desc.trim()),
      }))
      .filter((item) => item?.client);

    const updatedData: ResumeData = {
      experienceSummary: summary,
      significantAssignments: updatedSignificantAssignments,
      education: educationItems.filter((item) => item.trim()),
      trainingsAndCertification: trainingItems.filter((item) => item.trim()),
      awardsAndRecognition: awardItems.filter((item) => item.trim()),
    };

    onSubmit(updatedData);

    setIsSummaryEditing(false);
    setIsHighlightEditing(false);
    setIsEducationEditing(false);
    setIsTrainingEditing(false);
    setIsAwardEditing(false);
    setIsSubmitting(false);
  };

  const Header: FC<{
    title: string;
    onEdit: () => void;
    isEditing: boolean;
  }> = ({ title, onEdit, isEditing }) => (
    <div className="bioContainer__content-wrapper__header">
      <div className="bioContainer__content-wrapper__header__title">
        {title}
      </div>
      {shouldEditEnabled && !isEditing && (
        <Button
          className="bioContainer__content-wrapper__header__edit-button"
          onClick={onEdit}
          value={'Edit'}
          isSecondary
          isShowIcon
          iconClassName="icon-edit"
        />
      )}
    </div>
  );

  return (
    <div className="bioContainer">
      <div className="bioContainer__content-wrapper">
        <Header
          title="EXPERIENCE SUMMARY"
          onEdit={handleEditSummary}
          isEditing={isSummaryEditing}
        />
        <div className="bioContainer__content-wrapper__summary">
          {isSummaryEditing ? (
            <>
              <textarea
                placeholder="Enter your experience summary..."
                value={summary}
                onChange={handleSummaryChange}
                disabled={!isSummaryEditing || isSubmitting}
                rows={Math.max(3, Math.ceil((summary?.length || 0) / 150))}
                maxLength={1200}
                className={
                  isSummaryEditing
                    ? 'editableField editableFieldSummary'
                    : 'editableFieldReadOnly'
                }
              />
              {summary.length >= 1200 && (
                <div className="error-message">
                  Summary can not be more than 1200 characters
                </div>
              )}
            </>
          ) : (
            <div
              className={
                summary
                  ? 'editableFieldReadOnly'
                  : 'editableFieldReadOnly placeholder'
              }
            >
              {summary || ''}
            </div>
          )}
        </div>
      </div>
      <div className="bioContainer__content-wrapper">
        <Header
          title="SIGNIFICANT ASSIGNMENTS"
          onEdit={handleEditHighlight}
          isEditing={isHighlightEditing}
        />
        {highlightItems.map((highlight, index) => {
          const {
            role = '',
            startDate = undefined,
            endDate = undefined,
            client = '',
            description = [],
          } = highlight;

          return (
            <li
              key={index}
              className="bioContainer__content-wrapper__highlights"
            >
              <div className="bioContainer__content-wrapper__highlights__role-date">
                <div className="bioContainer__content-wrapper__highlights__role-date__role">
                  <input
                    type="text"
                    maxLength={31}
                    value={role}
                    onChange={(event) =>
                      handleHighlightChange(index, 'role', event)
                    }
                    disabled={!isHighlightEditing || isSubmitting}
                    className={
                      isHighlightEditing
                        ? 'editableField'
                        : 'editableFieldReadOnly'
                    }
                    placeholder={isHighlightEditing ? 'Enter Role' : undefined}
                  />
                  {isHighlightEditing && role.length >= 31 && (
                    <div className="error-message">
                      Role name can not be more than 30 characters.
                    </div>
                  )}
                </div>
                <div className="bioContainer__content-wrapper__highlights__role-date__date">
                  <span className="bioContainer__content-wrapper__highlights__role-date__date__start-date">
                    {isHighlightEditing ? (
                      <DatePicker
                        value={formatDate(startDate)}
                        onChange={(date) =>
                          handleDateChange(index, 'startDate', date)
                        }
                        disabled={!isHighlightEditing || isSubmitting}
                        className={'editableField'}
                        placeholder={'Start date'}
                        dateFormat="MM/yyyy"
                      />
                    ) : (
                      <div className="startDate-text">
                        {formatDate(startDate)?.toLocaleDateString('en-GB', {
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </div>
                    )}
                  </span>
                  <span
                    className={
                      isHighlightEditing
                        ? 'bioContainer__content-wrapper__highlights__role-date__date__separator'
                        : `bioContainer__content-wrapper__highlights__role-date__date__separator ${
                            !startDate && !endDate
                              ? 'editableFieldReadOnly separator-empty'
                              : ''
                          }`
                    }
                  >
                    -
                  </span>
                  <span className="bioContainer__content-wrapper__highlights__role-date__date__end-date">
                    {isHighlightEditing ? (
                      <DatePicker
                        value={formatDate(endDate)}
                        onChange={(date) =>
                          handleDateChange(index, 'endDate', date)
                        }
                        disabled={!isHighlightEditing || isSubmitting}
                        className={
                          isHighlightEditing
                            ? 'editableField'
                            : 'editableFieldReadOnly'
                        }
                        placeholder={'End date'}
                        dateFormat="MM/yyyy"
                      />
                    ) : (
                      <div className="endDate-text">
                        {formatDate(endDate)?.toLocaleDateString('en-GB', {
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="bioContainer__content-wrapper__highlights__client">
                <input
                  type="text"
                  value={client}
                  maxLength={31}
                  onChange={(event) =>
                    handleHighlightChange(index, 'client', event)
                  }
                  disabled={!isHighlightEditing || isSubmitting}
                  className={
                    isHighlightEditing
                      ? 'editableField'
                      : 'editableFieldReadOnly'
                  }
                  placeholder={isHighlightEditing ? 'Enter Client' : undefined}
                />
                {isHighlightEditing && client.length >= 31 && (
                  <div className="error-message">
                    Client name can not be more than 30 characters.
                  </div>
                )}
              </div>
              <ul className="bioContainer__content-wrapper__highlights__descriptions">
                {description.map((desc, descIndex) => (
                  <li
                    key={descIndex}
                    className={
                      !desc
                        ? 'no-bullet'
                        : `${!isHighlightEditing ? 'descriptionText' : ''}`
                    }
                  >
                    {isHighlightEditing ? (
                      <input
                        type="text"
                        maxLength={150}
                        value={desc}
                        onChange={(event) =>
                          handleHighlightChange(
                            index,
                            'description',
                            event,
                            descIndex
                          )
                        }
                        disabled={!isHighlightEditing || isSubmitting}
                        className={
                          isHighlightEditing
                            ? 'editableField'
                            : 'editableFieldReadOnly'
                        }
                        placeholder={
                          isHighlightEditing
                            ? 'Enter description...'
                            : undefined
                        }
                      />
                    ) : (
                      desc
                    )}
                    {isHighlightEditing && desc.length >= 150 && (
                      <div className="error-message">
                        Description can not be more than 150 characters
                      </div>
                    )}
                  </li>
                ))}
                {isHighlightEditing && description.length < 3 && (
                  <li className="no-bullet">
                    <Button
                      onClick={() => handleAddDescription(index)}
                      disabled={isSubmitting || description.length >= 3}
                      className="bioContainer__content-wrapper__list__addButton"
                      value="Add Description"
                      isSecondary
                      isShowIcon
                      iconClassName="icon-plus"
                    />
                  </li>
                )}
              </ul>
            </li>
          );
        })}
        {highlightItems.length < 3 && isHighlightEditing && (
          <div className="bioContainer__content-wrapper__add-button-container">
            <Button
              onClick={handleAddAssignment}
              disabled={isSubmitting}
              className="bioContainer__content-wrapper__add-button"
              value="Add Assignment"
              isSecondary
              isShowIcon
              iconClassName="icon-plus"
            />
          </div>
        )}
      </div>
      <div className="bioContainer__content-wrapper">
        <Header
          title="EDUCATION"
          onEdit={() => setIsEducationEditing(true)}
          isEditing={isEducationEditing}
        />
        <div className="bioContainer__content-wrapper__list">
          <ul>
            {educationItems.map((education, index) => (
              <li key={index} className={!education ? 'no-bullet' : ''}>
                <input
                  type="text"
                  maxLength={100}
                  value={education}
                  onChange={(event) => handleEducationChange(index, event)}
                  disabled={!isEducationEditing || isSubmitting}
                  className={
                    isEducationEditing
                      ? 'editableField'
                      : 'editableFieldReadOnly'
                  }
                  placeholder={
                    isEducationEditing ? 'Enter Education' : undefined
                  }
                />
                {isEducationEditing && education.length >= 100 && (
                  <div className="error-message">
                    Education can not be more than 100 characters
                  </div>
                )}
              </li>
            ))}
            {isEducationEditing && educationItems.length < 3 && (
              <li className="no-bullet">
                <Button
                  onClick={handleAddEducation}
                  disabled={isSubmitting || educationItems.length >= 3}
                  className="bioContainer__content-wrapper__list__addButton"
                  value="Add Education"
                  isSecondary
                  isShowIcon
                  iconClassName="icon-plus"
                />
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="bioContainer__content-wrapper">
        <Header
          title="Trainings & Certifications"
          onEdit={() => setIsTrainingEditing(true)}
          isEditing={isTrainingEditing}
        />
        <div className="bioContainer__content-wrapper__list">
          <ul>
            {trainingItems.map((training, index) => (
              <li key={index} className={!training ? 'no-bullet' : ''}>
                <input
                  type="text"
                  maxLength={100}
                  value={training}
                  onChange={(event) => handleTrainingChange(index, event)}
                  disabled={!isTrainingEditing || isSubmitting}
                  className={
                    isTrainingEditing
                      ? 'editableField'
                      : 'editableFieldReadOnly'
                  }
                  placeholder={
                    isTrainingEditing
                      ? 'Enter Training or Certification'
                      : undefined
                  }
                />
                {isTrainingEditing && training.length >= 100 && (
                  <div className="error-message">
                    Training can not be more than 100 characters
                  </div>
                )}
              </li>
            ))}
            {isTrainingEditing && trainingItems.length < 3 && (
              <li className="no-bullet">
                <Button
                  onClick={handleAddTraining}
                  disabled={isSubmitting || trainingItems.length >= 3}
                  className="bioContainer__content-wrapper__list__addButton"
                  value="Add Training or Certificate"
                  isSecondary
                  isShowIcon
                  iconClassName="icon-plus"
                />
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="bioContainer__content-wrapper">
        <Header
          title="Awards & Recognitions"
          onEdit={() => setIsAwardEditing(true)}
          isEditing={isAwardEditing}
        />
        <div className="bioContainer__content-wrapper__list">
          <ul>
            {awardItems.map((award, index) => (
              <li key={index} className={!award ? 'no-bullet' : ''}>
                <input
                  type="text"
                  value={award}
                  onChange={(event) => handleAwardChange(index, event)}
                  disabled={!isAwardEditing || isSubmitting}
                  className={
                    isAwardEditing ? 'editableField' : 'editableFieldReadOnly'
                  }
                  placeholder={
                    isAwardEditing ? 'Enter Award or Recognition' : undefined
                  }
                />
              </li>
            ))}
            {isAwardEditing && awardItems.length < 3 && (
              <li className="no-bullet">
                <Button
                  onClick={handleAddAward}
                  disabled={isSubmitting || awardItems.length >= 3}
                  className="bioContainer__content-wrapper__list__addButton"
                  value="Add Award or Recognition"
                  isSecondary
                  isShowIcon
                  iconClassName="icon-plus"
                />
              </li>
            )}
          </ul>
        </div>
      </div>

      {shouldEditEnabled && (
        <Button
          onClick={handleSubmit}
          value={'Submit'}
          disabled={!validateEditResume() || isSubmitting}
        />
      )}
    </div>
  );
};

export default Resume;
