import { useEffect, useState, useRef, type FC } from 'react';
import Checkbox from 'components/common/Checkbox';
import Input from 'components/common/Input';
import { Filter, DropdownProps, KeyName } from './DropdownType';
import useOnClickOutside from 'hooks/useOnClickOutside';
import './Dropdown.scss';

const Dropdown: FC<DropdownProps> = ({
  className,
  disabledSearchBar = false,
  filterLabel,
  isMultiSelect = false,
  list = [],
  id = 'containsFilter',
  name = 'name',
  placeholder,
  inputName = 'inputName',
  onSelect,
  prefillData = [],
  labelText = '',
  showLabel = false,
  useFormStyles = false,
  showSelectedOptionsCount = true,
  showError = false,
  errorText,
  disabledDropdown = false,
}) => {
  const [filterList, setFilterList] = useState<Filter[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<Filter[]>([]);
  const [showList, setShowList] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => setShowList(false));

  useEffect(() => {
    if (list.length > 0) {
      setFilterList(list);
    }
  }, [list]);

  useEffect(() => {
    if (!initialized && list.length > 0) {
      setFilterList(list);
      setSelectedOptions(prefillData);
      setInitialized(true);
    }

    if (selectedOptions.length !== 0 && initialized && prefillData.length < 1) {
      setSelectedOptions([]);
    }
  }, [list, prefillData, initialized]);

  const checkedOption = (id: string) =>
    selectedOptions.some((el) => el.id === id);

  const handleSingleSelect = (option: Filter) => {
    setSelectedOptions([option]);
    onSelect([option]);
    setShowList((prevState) => !prevState);
  };

  const handleMultiSelect = (
    id: string,
    isChecked: boolean,
    option: Filter
  ) => {
    if (isChecked && !checkedOption(id)) {
      onSelect([...selectedOptions, option]);
      setSelectedOptions((prevState) => [...prevState, option]);
    } else {
      onSelect(selectedOptions.filter((el) => el.id !== id));
      setSelectedOptions((prevState) => prevState.filter((el) => el.id !== id));
    }
  };

  const handleInputChange = (inputVal: string) => {
    setSearchValue(inputVal);
    const filteredList = list.filter(
      (elm) =>
        elm[name as KeyName].toLowerCase().indexOf(inputVal.toLowerCase()) !==
        -1
    );
    setFilterList(filteredList);
  };

  const optionsList = filterList.map((elm) => {
    if (isMultiSelect) {
      return (
        <li key={elm.id} className="dropdown__list-elm-multi">
          <Checkbox
            label={elm[name as KeyName]}
            id={elm.id}
            checked={checkedOption(elm.id)}
            data-testid="dropdown__list-elm-multi"
            onChecked={(id, isChecked) => handleMultiSelect(id, isChecked, elm)}
          />
        </li>
      );
    } else {
      return (
        <li
          key={elm.id}
          className={`dropdown__list-elm ${
            checkedOption(elm.id) ? 'selected' : ''
          }`}
          data-testid="dropdown-list-elm"
          onClick={() => handleSingleSelect(elm)}
        >
          {elm[name as KeyName]}
        </li>
      );
    }
  });

  return (
    <div
      className={`dropdown ${showList ? 'dropdown__toggle' : ''} ${
        className ? className : ''
      }`}
      ref={ref}
      data-testid="dropdown"
    >
      {showLabel && <div className="dropdown__label-text">{labelText}</div>}
      <div
        className={`dropdown__title ${
          useFormStyles ? 'dropdown__form' : 'dropdown__bubble'
        } ${showError && 'error-input'} ${
          disabledDropdown ? 'dropdown__disabled' : ''
        }`}
        data-testid="dropdown-label"
        onClick={() => setShowList((prevState) => !prevState)}
      >
        <span
          className={`dropdown__label ${filterLabel ? '' : 'has-placeholder'} ${
            showError && 'error-text'
          }`}
        >
          {filterLabel || placeholder}
        </span>
        {showSelectedOptionsCount && selectedOptions.length > 0 && (
          <span
            className="dropdown__options-count"
            data-testid="dropdown__options-count"
          >
            {selectedOptions.length}
          </span>
        )}
      </div>
      {showError && <div className="dropdown__error-text">{errorText}</div>}
      <div
        className={`dropdown__block ${
          showLabel ? 'dropdown__block-label' : ''
        }`}
        data-testid="dropdown-list-with-input"
      >
        {!disabledSearchBar && (
          <Input
            type="text"
            id={`${id}__dropdown-input`}
            name={`${inputName}__dropdown-input`}
            inputVal={searchValue}
            placeholder="Search"
            data-testid="dropdown--input"
            className="dropdown__block-search"
            onChange={(inputVal) => handleInputChange(inputVal)}
          />
        )}
        <ul className="dropdown__list" data-testid="dropdown__list">
          {optionsList}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
