import { type FC } from 'react';
import { SkillTileProps } from './SkillTileType';
import skillDefaultIcon from 'assets/images/SkillDefault.svg';
import './SkillTile.scss';

const SkillTile: FC<SkillTileProps> = ({
  title,
  level,
  src = skillDefaultIcon,
  alt = '',
  isSelected,
}) => (
  <div className={`skilltile ${isSelected ? 'skilltile__selected' : ''}`}>
    <div className="skilltile__image">
      <img src={src} alt={alt} width="24px" height="24px" />
    </div>
    <div className="skilltile__column">
      <span className="skilltile__column__label">{title}</span>
      <span className="skilltile__column__level">{level}</span>
    </div>
  </div>
);

export default SkillTile;
