import {
  EmployeeDetailsErrorMap,
  EmployeeProjectDetails,
} from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import { Filter } from 'components/common/Dropdown/DropdownType';

export const defaultEmployeeStatusData = {
  inActive: false,
  lastWorkingDay: undefined,
};

export const defaultEmployeeDetailsFields = {
  fullName: '',
  email: '',
  phoneNumber: '',
  homeAddress: '',
  employeeId: '',
  practice: undefined,
  joinDate: undefined,
  ohrId: '',
  location: undefined,
  employeeDesignation: '',
  level: undefined,
  reportingManager: '',
  managerId: '',
  education: undefined,
  experienceInYears: '',
  experienceInMonths: '',
  remark: '',
  resumeRegular: undefined,
  profilePhoto: undefined,
  businessUnit: '',
  photo: null,
  resume: null,
  workMode: undefined,
  latticeLink: '',
};

export const defaultEmployeeDetailsErrorMap: EmployeeDetailsErrorMap = {
  fullName: false,
  email: false,
  employeeId: false,
  practice: false,
  joinDate: false,
  ohrId: false,
  location: false,
  employeeDesignation: false,
  level: false,
  reportingManager: false,
  managerId: false,
  education: false,
  experienceInYears: false,
  experienceInMonths: false,
  workMode: false,
};

export const initialProjectsDetails: EmployeeProjectDetails[] = [
  {
    name: '',
    joiningDate: '',
    leavingDate: '',
    managerName: '',
    ozLink: '',
    clientName: '',
  },
];

export const workModeList: Filter[] = [
  {
    id: 'Office',
    name: 'Office',
  },
  {
    id: 'Hybrid',
    name: 'Hybrid',
  },
  {
    id: 'Remote',
    name: 'Remote',
  },
];
