import { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import CONFIG from 'app/config';
import Button from 'components/common/Button';
import Dropdown from 'components/common/Dropdown';
import Loader from 'components/common/Loader';
import PageHeader from 'components/AddOrEditEmployee/TabPages/PageHeader';
import InputBox from 'components/common/InputBox';
import LABELS from 'app/labels';
import {
  SkillDetailsProps,
  SkillsProps,
} from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import { IndustryExperience } from 'components/AddOrEditEmployee/Types/SelectedEmployeeDataType';
import { Filter } from 'components/common/Dropdown/DropdownType';
import { isObjectPresentInArray } from 'utils/isObjectPresentInArray';
import './SkillDetails.scss';
import { useAppSelector } from 'types/hooks';
import { selectFilterData } from 'redux/slices/employeeDirectory/employeeDirectorySlice';

const SkillDetails: FC<SkillDetailsProps> = ({
  skillsList,
  setSkillsList,
  onCancel,
  onPrevious,
  onNextOrSubmit,
  skillOptions,
  setSkillOptions,
  industryExperienceList,
  setIndustryExperienceList,
  isEditMode = false,
  isLoading,
}) => {
  const {
    ADD_OR_EDIT_EMPLOYEE: {
      CANCEL,
      PREVIOUS,
      NEXT,
      SUBMIT,
      EMPLOYEE_SKILLS: {
        SKILLS_SECTION_TITLE,
        SKILLS_SECTION_SUBTITLE,
        SELECTED_SKILLS_TITLE,
        SELECTED_SKILLS_SUBTITLE,
        INDUSTRY_SECTION_TITLE,
        INDUSTRY_SECTION_SUBTITLE,
        INDUSTRY_SECTION_PLACEHOLDER,
      },
    },
  } = LABELS;

  const experienceList = [
    {
      id: 'Beginner',
      name: 'Beginner',
    },
    {
      id: 'Intermediate',
      name: 'Intermediate',
    },
    {
      id: 'Expert',
      name: 'Expert',
    },
    {
      id: 'Master',
      name: 'Master',
    },
  ];
  const skillTypeList = [
    {
      id: 'Primary',
      name: 'Primary',
    },
    {
      id: 'Secondary',
      name: 'Secondary',
    },
  ];

  const [selectedSkill, setSelectedSkill] = useState<SkillsProps | null>(null);
  const [selectedLevel, setSelectedLevel] = useState('Beginner');
  const [selectedCategory, setSelectedCategory] = useState('Secondary');
  const [filterLabel, setFilterLabel] = useState<string>(
    'Try searching for React'
  );
  const [industryExp, setIndustryExp] = useState<IndustryExperience[]>([]);
  const [showIndustryError, setShowIndustryError] = useState(false);

  const { industries } = useAppSelector(selectFilterData) || {};

  useEffect(() => {
    const fetchSkills = async () => {
      const response = await axios.get(`${CONFIG.BASE_URL}/skills`);
      const data = response.data;
      setSkillOptions(data);
    };
    if (skillOptions?.length < 1) {
      fetchSkills();
    }
    if (industries && industries.length > 0) {
      const initialIndustryExp = industries.map(({ name }) => ({
        name,
        experience: '',
      }));
      setIndustryExp(
        [...initialIndustryExp]
          .slice()
          .sort((a, b) => a.name.localeCompare(b.name))
      );
      if (
        isEditMode &&
        industryExperienceList &&
        industryExperienceList.length > 0
      ) {
        setIndustryExp((prevIndustryExp) =>
          [...industryExperienceList, ...prevIndustryExp]
            .filter(
              (industry, index, self) =>
                index === self.findIndex((item) => item.name === industry.name)
            )
            .slice()
            .sort((a, b) => a.name.localeCompare(b.name))
        );
      }
    }
  }, []);

  const handleSkillChange = (selectedOption: Filter[]) => {
    const convertedOption: SkillsProps | null =
      selectedOption.length > 0
        ? {
            id: selectedOption[0].id,
            name: selectedOption[0].name,
            level: 'Beginner',
            category: 'Primary',
          }
        : null;
    setSelectedSkill(convertedOption);
    setFilterLabel(
      selectedOption.length > 0
        ? selectedOption[0].name
        : 'Try searching for React'
    );
  };

  const handleLevelChange = (value: Filter[]) => {
    setSelectedLevel(value[0].name);
  };

  const handleCategoryChange = (value: Filter[]) => {
    setSelectedCategory(value[0].name);
    setIndustryExp((prevIndustryExp) => [...prevIndustryExp]);
  };

  const handleAddSkill = () => {
    if (selectedSkill && selectedLevel && selectedCategory) {
      const newSkill: SkillsProps = {
        id: (skillsList.length + 1).toString(),
        name: selectedSkill.name,
        level: selectedLevel,
        category: selectedCategory,
      };

      if (!isObjectPresentInArray(skillsList, 'name', newSkill.name)) {
        setSkillsList([...skillsList, newSkill]);
      } else {
        toast.error(
          <>
            <div>{`${newSkill.name} is already selected.`}</div>
            <div>{`If you want to update it, please remove it from the Selected Skills list then update it.`}</div>
          </>
        );
      }
      setSelectedSkill(null);
      setFilterLabel('Try searching for React');
      setSelectedLevel('Beginner');
      setSelectedCategory('Secondary');
    }
  };

  const handleRemoveSkill = (id: string) => {
    const updatedSkills = skillsList.filter((skill) => skill.id !== id);
    setSkillsList(updatedSkills);
  };

  const handleNextOrSubmit = () => {
    if (skillsList?.length > 0) {
      if (
        industryExp.some(
          (industry) =>
            industry.experience !== null && industry.experience !== ''
        )
      ) {
        const filteredIndustry = industryExp.filter(
          (industry) => industry.experience !== ''
        );
        setIndustryExperienceList(filteredIndustry);
        onNextOrSubmit();
        return;
      }
      setShowIndustryError(true);
      toast.error('Please add atleast one industry experience.');
      return;
    }
    toast.error('Please select atleast one skill.');
  };

  const handleOnPrevious = () => {
    setIndustryExperienceList(industryExp);
    onPrevious();
  };

  const handleIndustry = (name: string, experience: string, index: number) => {
    if (isNaN(+experience) || +experience < 0 || +experience > 99) {
      return;
    }
    const updatedIndustryExperience = [...industryExp];
    updatedIndustryExperience[index] = {
      name,
      experience,
    };
    setIndustryExp(updatedIndustryExperience);
    setShowIndustryError(false);
  };

  return (
    <div className="skillsSectionContainer">
      {isLoading && <Loader />}
      <div className="skillsSectionContainer__body">
        <PageHeader
          title={SKILLS_SECTION_TITLE}
          subtitle={SKILLS_SECTION_SUBTITLE}
        />
        <div className="skillsSectionContainer__input-wrapper">
          <div className="skillsSectionContainer__input-wrapper__dropdown">
            <div className="skillsSectionContainer__input-wrapper__dropdown__label">
              Select Skill
            </div>
            <Dropdown
              className="skillsSectionContainer__input-wrapper__dropdown__input"
              list={skillOptions}
              filterLabel={filterLabel}
              name="name"
              onSelect={handleSkillChange}
            />
            <div className="skillsSectionContainer__label">
              Search Skill in the field and assign Level & Type.
            </div>
          </div>
          <div className="skillsSectionContainer__input-wrapper__dropdown set-margin">
            <div className="skillsSectionContainer__input-wrapper__dropdown__label">
              Experience Level
            </div>
            <Dropdown
              className="skillsSectionContainer__input-wrapper__dropdown__input"
              disabledSearchBar
              list={experienceList}
              filterLabel={selectedLevel}
              name="name"
              onSelect={(val) => handleLevelChange(val)}
            />
          </div>
          <div className="skillsSectionContainer__input-wrapper__dropdown set-margin">
            <div className="skillsSectionContainer__input-wrapper__dropdown__label">
              Skill Type
            </div>
            <Dropdown
              className="skillsSectionContainer__input-wrapper__dropdown__input"
              disabledSearchBar
              list={skillTypeList}
              filterLabel={selectedCategory}
              name="name"
              onSelect={(val) => handleCategoryChange(val)}
            />
          </div>
          <Button
            className="skillsSectionContainer__input-wrapper__button"
            isShowIcon
            iconClassName="icon-plus"
            onClick={handleAddSkill}
            value="Add Skill"
            isSecondary
            data-testid="add-skills-button"
          />
        </div>

        <div className="skillsSectionContainer__selected-skills">
          <PageHeader
            title={SELECTED_SKILLS_TITLE}
            subtitle={SELECTED_SKILLS_SUBTITLE}
          />
          <div className="skillsSectionContainer__table">
            <table>
              <thead>
                <tr>
                  <th>S No.</th>
                  <th>Skill Name</th>
                  <th>Level</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              {skillsList.length > 0 && (
                <tbody>
                  {skillsList.map((skill, index) => (
                    <tr key={skill.id}>
                      <td>{index + 1}</td>
                      <td>{skill.name}</td>
                      <td>{skill.level}</td>
                      <td>{skill.category}</td>
                      <td>
                        <Button
                          className="skillsSectionContainer__table-botton"
                          isShowIcon
                          iconClassName="icon-cross"
                          onClick={() => handleRemoveSkill(skill.id)}
                          value="Remove"
                          isSecondary
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
        {industryExp && industryExp?.length > 0 && (
          <div className="skillsSectionContainer__industry-section">
            <PageHeader
              title={INDUSTRY_SECTION_TITLE}
              subtitle={INDUSTRY_SECTION_SUBTITLE}
            />
            <div className="skillsSectionContainer__industry-section__industry">
              {industryExp.map((industry, index) => (
                <InputBox
                  type="number"
                  key={industry.name}
                  id={industry.name}
                  name="experienceMonths"
                  showLabel
                  labelText={`${industry.name} (Years)`}
                  placeholder={INDUSTRY_SECTION_PLACEHOLDER}
                  inputVal={industry.experience}
                  className={`skillsSectionContainer__industry-section___industry__${industry.name}`}
                  onChange={(val) => handleIndustry(industry.name, val, index)}
                  showError={showIndustryError && !industry.experience}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="skillsSectionContainer__footer">
        <Button
          className="skillsSectionContainer__btn-cancel"
          value={CANCEL}
          onClick={onCancel}
          isSecondary
          data-testid="card-body-buttons-cancel"
        />
        <Button
          className="skillsSectionContainer__btn-previous"
          value={PREVIOUS}
          onClick={handleOnPrevious}
          isSecondary
          data-testid="card-body-buttons-next"
        />
        <Button
          className="skillsSectionContainer__btn-next"
          value={isEditMode ? NEXT : SUBMIT}
          onClick={handleNextOrSubmit}
          data-testid="card-body-buttons-next-or-submit"
        />
      </div>
    </div>
  );
};

export default SkillDetails;
