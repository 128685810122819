import { SelectedEmployeeData } from 'components/AddOrEditEmployee/Types/SelectedEmployeeDataType';
import { FilterList } from 'types/employeeDirectory';
import { educationDropdownData } from 'data/educationDropdownData';
import { workModeList } from 'components/AddOrEditEmployee/constants/AddOrEditEmployeeConstants';

function findNumbersFromString(inputString: string): number[] {
  const regex = /\d+/g;
  const numbersFound = inputString.match(regex);

  if (numbersFound) {
    return numbersFound.map(Number);
  } else {
    return [0, 0];
  }
}

export const findObjectPresentInArray = <T>(
  array: T[],
  objectKey: keyof T,
  objectValue: string
) => {
  const selectedObject = array.find(
    ({ [objectKey]: key }: T) => key === objectValue
  );
  return selectedObject ? [selectedObject] : undefined;
};

export const loadEmployeeDetailsFormDataFromStore = (
  selectedEmployeeData: SelectedEmployeeData,
  location: FilterList[] | undefined,
  experienceLevel: FilterList[] | undefined,
  department: FilterList[] | undefined
) => {
  const NAME = 'name';
  const expYearsAndMonths = findNumbersFromString(
    selectedEmployeeData.previousExperience
  );
  const selectedlocation = findObjectPresentInArray(
    location ?? [],
    NAME,
    selectedEmployeeData.baseLocation
  );
  const selectedLevel = findObjectPresentInArray(
    experienceLevel ?? [],
    NAME,
    selectedEmployeeData.level
  );
  const selectedPractice = findObjectPresentInArray(
    department ?? [],
    NAME,
    selectedEmployeeData.practice
  );

  const selectedEducation = findObjectPresentInArray(
    educationDropdownData,
    NAME,
    selectedEmployeeData.qualification
  );

  const selectedWorkMode = findObjectPresentInArray(
    workModeList ?? [],
    NAME,
    selectedEmployeeData.workMode
  );

  const employeeDetailsFormData: any = {
    fullName: selectedEmployeeData.name,
    email: selectedEmployeeData.email,
    phoneNumber: selectedEmployeeData.phoneNumber,
    homeAddress: '',
    employeeId: selectedEmployeeData.rpId.toString(),
    practice: selectedPractice,
    joinDate: new Date(selectedEmployeeData.joiningDate),
    ohrId: selectedEmployeeData.ohrId.toString(),
    location: selectedlocation,
    employeeDesignation: selectedEmployeeData.designation,
    level: selectedLevel,
    reportingManager: selectedEmployeeData.manager,
    managerId: selectedEmployeeData.manager_rpId.toString(),
    education: selectedEducation,
    experienceInYears: expYearsAndMonths[0].toString(),
    experienceInMonths: expYearsAndMonths[1].toString(),
    remark: selectedEmployeeData.remarks,
    resume: selectedEmployeeData.resume,
    photo: selectedEmployeeData.photo,
    latticeLink: selectedEmployeeData.latticeLink,
    // resumeRegular: undefined,
    // profilePhoto: undefined,
    workMode: selectedWorkMode,
  };
  return employeeDetailsFormData;
};

export const loadEmployeeSkillDataFromStore = (
  selectedEmployeeData: SelectedEmployeeData
) =>
  selectedEmployeeData.employeeSkill.map((employeeSkill) => ({
    id: employeeSkill.name,
    name: employeeSkill.name,
    level: employeeSkill.skillLevel,
    category: employeeSkill.skillType,
  }));
