import { FC, useState } from 'react';
import AddOrEditHeader from './AddOrEditHeader';
import AddOrEditEmployeeForm from './AddOrEditEmployeeForm';
import {
  AddOrEditEmployeeProps,
  EmployeeStatusData,
} from './Types/AddOrEditEmployeeType';
import { defaultEmployeeStatusData } from './constants/AddOrEditEmployeeConstants';
import 'components/AddOrEditEmployee/AddOrEditEmployee.scss';

const AddorEditEmployee: FC<AddOrEditEmployeeProps> = ({
  onCancel,
  blobUrl,
  userName,
  isEditMode,
}) => {
  const [employeeStatusData, setEmployeeStatusData] =
    useState<EmployeeStatusData>(defaultEmployeeStatusData);

  return (
    <>
      <AddOrEditHeader
        blobUrl={blobUrl}
        userName={userName}
        isEditMode={isEditMode}
        employeeStatusData={employeeStatusData}
        setEmployeeStatusData={setEmployeeStatusData}
      />
      <AddOrEditEmployeeForm
        onCancel={onCancel}
        isEditMode={isEditMode}
        employeeStatusData={employeeStatusData}
        setEmployeeStatusData={setEmployeeStatusData}
      />
    </>
  );
};

export default AddorEditEmployee;
