export const DEFAULT_PAYLOAD = {
  searchString: '',
  skills: [],
  department: [],
  location: [],
  experienceLevel: [],
  availablity: 'all',
  status: 'active',
};

export const EMPLOYEE_STATUS_LIST = [
  { id: 'active', name: 'Active' },
  { id: 'inactive', name: 'InActive' },
];
