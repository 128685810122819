import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';
import {
  getFiltersData,
  getEmployeesData,
  getEmployeeProfileData,
  fetchEmployeeResumeData,
} from 'redux/services/employeeDirectory/employeeDirectoryService';
import {
  FilterResponse,
  EmployeeDirectoryAction,
  Employee,
  EmployeeDirectoryState,
  Builder,
  ActionType,
} from 'types/employeeDirectory';
import { Assignment } from 'components/Resume/ResumeType';

const initialState: EmployeeDirectoryState = {
  filtersData: {},
  empAvailablity: [
    { id: 'true', name: 'On Bench' },
    { id: 'false', name: 'On Project' },
    { id: 'all', name: 'Both' },
  ],
  sortbyOptions: [
    { id: 'practice', name: 'Practice' },
    {
      id: 'location',
      name: 'Location',
    },
    {
      id: 'availability',
      name: 'Availability',
    },
    {
      id: 'experience',
      name: 'Experience Level',
    },
    {
      id: 'industry',
      name: 'Industry',
    },
  ],
  selectedFilters: {
    searchString: '',
    skills: [],
    department: [],
    location: [],
    experienceLevel: [],
    employeeAvailablity: [],
    status: [],
    industries: [],
    sortby: '',
  },
  employeesList: [],
  totalEmployeesCount: 0,
  isLoad: false,
  isEmployeesDataFetching: false,
  viewProfileId: '',
  selectedEmployeeData: {},
  isEmployeeProfileDataFetching: false,
  isEmployeeResumeDataFetching: false,
  isEmployeeEditModeOn: false,
  employeeResumeData: {
    experienceSummary: '',
    significantAssignments: [],
    education: [],
    trainingsAndCertification: [],
    awardsAndRecognition: [],
  },
};

export const filterDataSuccess = (
  state: { filtersData: FilterResponse; isLoad: boolean },
  action: EmployeeDirectoryAction
) => {
  state.filtersData = action.payload;
  state.isLoad = false;
};

export const filterDataPending = (state: { isLoad: boolean }) => {
  state.isLoad = true;
};

export const filterDataRejected = (state: { isLoad: boolean }) => {
  state.isLoad = false;
};

export const fetchEmployeesDataSuccess = (
  state: {
    employeesList: Employee[];
    totalEmployeesCount: number;
    isEmployeesDataFetching: boolean;
  },
  action: EmployeeDirectoryAction
) => {
  state.employeesList = action.payload?.searchResponseList;
  state.totalEmployeesCount = action.payload?.totalEmployee;
  state.isEmployeesDataFetching = false;
};

export const fetchEmployeesDataPending = (state: {
  isEmployeesDataFetching: boolean;
}) => {
  state.isEmployeesDataFetching = true;
};

export const fetchEmployeesDataRejected = (state: {
  isEmployeesDataFetching: boolean;
}) => {
  state.isEmployeesDataFetching = false;
};

export const fetchEmployeeResumeDataSuccess = (
  state: {
    employeeResumeData: {
      experienceSummary: string;
      significantAssignments: Assignment[];
      education: string[];
      trainingsAndCertification: string[];
      awardsAndRecognition: string[];
    };
    isEmployeeResumeDataFetching: boolean;
  },
  action: EmployeeDirectoryAction
) => {
  const {
    experienceSummary,
    significantAssignments,
    education,
    trainingsAndCertification,
    awardsAndRecognition,
  } = action.payload || {
    experienceSummary: '',
    significantAssignments: [],
    education: [],
    trainingsAndCertification: [],
    awardsAndRecognition: [],
  };

  state.employeeResumeData.experienceSummary = experienceSummary;
  state.employeeResumeData.significantAssignments = significantAssignments;
  state.employeeResumeData.education = education;
  state.employeeResumeData.trainingsAndCertification =
    trainingsAndCertification;
  state.employeeResumeData.awardsAndRecognition = awardsAndRecognition;
  state.isEmployeeResumeDataFetching = false;
};

export const fetchEmployeeResumeDataPending = (state: {
  isEmployeeResumeDataFetching: boolean;
}) => {
  state.isEmployeeResumeDataFetching = true;
};

export const fetchEmployeeResumeDataRejected = (state: {
  isEmployeeResumeDataFetching: boolean;
}) => {
  state.isEmployeeResumeDataFetching = false;
};

export const fetchEmployeeProfileDataPending = (state: {
  isEmployeeProfileDataFetching: boolean;
}) => {
  state.isEmployeeProfileDataFetching = true;
};

export const fetchEmployeeProfileDataSuccess = (
  state: {
    selectedEmployeeData: object;
    isEmployeeProfileDataFetching: boolean;
  },
  action: EmployeeDirectoryAction
) => {
  state.selectedEmployeeData = action.payload;
  state.isEmployeeProfileDataFetching = false;
};

export const fetchEmployeeProfileDataRejected = (state: {
  selectedEmployeeData: object;
  isEmployeeProfileDataFetching: boolean;
}) => {
  state.selectedEmployeeData = {};
  state.isEmployeeProfileDataFetching = false;
};

export const employeeDirectorySlice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    updateFilters: (state, action: PayloadAction<ActionType>) => {
      const { filterName, selectedFilter } = action.payload;
      state.selectedFilters = {
        ...state.selectedFilters,
        [filterName]: selectedFilter,
      };
    },
    resetFilters: (state) => {
      state.selectedFilters = {
        searchString: '',
        skills: [],
        department: [],
        location: [],
        experienceLevel: [],
        employeeAvailablity: [],
        status: [],
        industries: [],
        sortby: '',
      };
    },
    selectedViewProfileId: (state, action: PayloadAction<string>) => {
      state.viewProfileId = action.payload;
      state.selectedEmployeeData = {};
      state.employeeResumeData = {
        experienceSummary: '',
        significantAssignments: [],
        education: [],
        trainingsAndCertification: [],
        awardsAndRecognition: [],
      };
    },
    setEmployeeEditModeOn: (state, action: PayloadAction<boolean>) => {
      state.isEmployeeEditModeOn = action.payload;
    },
  },
  extraReducers: (builder: Builder) => {
    builder.addCase(getFiltersData.fulfilled, filterDataSuccess);
    builder.addCase(getFiltersData.pending, filterDataPending);
    builder.addCase(getFiltersData.rejected, filterDataRejected);
    builder.addCase(getEmployeesData.fulfilled, fetchEmployeesDataSuccess);
    builder.addCase(getEmployeesData.pending, fetchEmployeesDataPending);
    builder.addCase(getEmployeesData.rejected, fetchEmployeesDataRejected);
    builder.addCase(
      fetchEmployeeResumeData.fulfilled,
      fetchEmployeeResumeDataSuccess
    );
    builder.addCase(
      fetchEmployeeResumeData.pending,
      fetchEmployeeResumeDataPending
    );
    builder.addCase(
      fetchEmployeeResumeData.rejected,
      fetchEmployeeResumeDataRejected
    );
    builder.addCase(
      getEmployeeProfileData.fulfilled,
      fetchEmployeeProfileDataSuccess
    );
    builder.addCase(
      getEmployeeProfileData.pending,
      fetchEmployeeProfileDataPending
    );
    builder.addCase(
      getEmployeeProfileData.rejected,
      fetchEmployeeProfileDataRejected
    );
  },
});

export const {
  updateFilters,
  resetFilters,
  selectedViewProfileId,
  setEmployeeEditModeOn,
} = employeeDirectorySlice.actions;

export const selectFilterData = (state: RootState) =>
  state.directory.filtersData;
export const selectEmpAvailibity = (state: RootState) =>
  state.directory.empAvailablity;
export const selectSortbyOptions = (state: RootState) =>
  state.directory.sortbyOptions;
export const selectedFilters = (state: RootState) =>
  state.directory.selectedFilters;
export const selectedviewProfileId = (state: RootState) =>
  state.directory.viewProfileId;
export const isLoadFilterData = (state: RootState) => state.directory.isLoad;
export const selectEmployeesData = (state: RootState) =>
  state.directory.employeesList;
export const isFetchingEmployeeData = (state: RootState) =>
  state.directory.isEmployeesDataFetching;
export const fetchTotalEmployeesCount = (state: RootState) =>
  state.directory.totalEmployeesCount;
export const fetchSelectedEmployeeData = (state: RootState) =>
  state.directory.selectedEmployeeData;
export const isFetchingEmployeeDetailData = (state: RootState) =>
  state.directory.isEmployeeProfileDataFetching;
export const isFetchingEmployeeResumeData = (state: RootState) =>
  state.directory.isEmployeeResumeDataFetching;
export const getEmployeeResumeData = (state: RootState) =>
  state.directory.employeeResumeData;
export const isEmployeeEditModeOn = (state: RootState) =>
  state.directory.isEmployeeEditModeOn;

export default employeeDirectorySlice.reducer;
