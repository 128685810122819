export const educationDropdownData = [
  {
    id: 'Bachelor',
    name: "Bachelor's degree",
  },
  {
    id: 'Master',
    name: "Master's degree",
  },
  {
    id: 'Doctorate',
    name: 'Doctorate or higher',
  },
];
