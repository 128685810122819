import { useState, type FC, useEffect } from 'react';
import { Tab, TabPanel } from 'react-tabs';
import { useAppSelector } from 'types/hooks';
import {
  fetchSelectedEmployeeData,
  selectFilterData,
} from 'redux/slices/employeeDirectory/employeeDirectorySlice';
import {
  AddOrEditEmployeeFormProps,
  SkillsProps,
  EmployeeDetailsFormInput,
  EmployeeProjectDetails,
  ClientProjectDetails,
} from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import {
  IndustryExperience,
  SelectedEmployeeData,
} from 'components/AddOrEditEmployee/Types/SelectedEmployeeDataType';
import LABELS from 'app/labels';
import TabNav from 'components/common/TabNav';
import SkillsSection from 'components/AddOrEditEmployee/TabPages/SkillDetails';
import EmployeeDetails from 'components/AddOrEditEmployee/TabPages/EmployeeDetails';
import ProjectDetails from 'components/AddOrEditEmployee/TabPages/ProjectDetails';
import {
  addNewEmployee,
  editEmployee,
} from 'redux/services/employeeDirectory/employeeDirectoryService';
import { buildEmployeeDetailsPayload } from 'utils/addEmployeeUtils';
import {
  loadEmployeeDetailsFormDataFromStore,
  loadEmployeeSkillDataFromStore,
} from 'utils/loadEmployeeDetailsFormDataFromStore';
import { defaultEmployeeDetailsFields } from 'components/AddOrEditEmployee/constants/AddOrEditEmployeeConstants';
import 'components/AddOrEditEmployee/AddOrEditEmployee.scss';
import { Filter } from 'components/common/Dropdown/DropdownType';

const AddOrEditEmployeeForm: FC<AddOrEditEmployeeFormProps> = ({
  onCancel,
  employeeStatusData,
  setEmployeeStatusData,
  isEditMode,
}) => {
  const {
    ADD_OR_EDIT_EMPLOYEE: { TAB_DETAILS, TAB_SKILLS, TAB_PROJECTS },
  } = LABELS;
  const tabCount = isEditMode ? 3 : 2;
  const [tabIndex, setTabIndex] = useState(0);
  const showPreviousButton = tabIndex > 0;
  const showNextButton = tabIndex < tabCount - 1;

  const [isValidTabArray, setIsValidTabArray] = useState(
    new Array(tabCount).fill(false)
  );
  const [employeeDetailsFormData, setEmployeeDetailsFormData] = useState(
    defaultEmployeeDetailsFields
  );
  const [skillsList, setSkillsList] = useState<SkillsProps[]>([]);
  const [skillOptions, setSkillOptions] = useState<Filter[]>([]);
  const [projectDetailsList, setProjectDetailsList] = useState<
    EmployeeProjectDetails[]
  >([]);
  const [clientProjectDetailsList, setClientProjectDetailsList] = useState<
    ClientProjectDetails[]
  >([]);
  const [industryExperienceList, setIndustryExperienceList] = useState<
    IndustryExperience[]
  >([]);
  const [isMount, setIsMount] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { location, experienceLevel, department } =
    useAppSelector(selectFilterData) || {};
  const selectedEmployeeData: SelectedEmployeeData = {
    ...useAppSelector(fetchSelectedEmployeeData),
  } as SelectedEmployeeData;

  useEffect(() => {
    if (!isMount && isEditMode) {
      // get selected employee details and set initial form fields
      const loadEmployeeForm = loadEmployeeDetailsFormDataFromStore(
        selectedEmployeeData,
        location,
        experienceLevel,
        department
      );
      setEmployeeDetailsFormData((prevEmployeeDetailsFormData) => ({
        ...prevEmployeeDetailsFormData,
        ...loadEmployeeForm,
      }));
      const loadEmployeeSkillForm =
        loadEmployeeSkillDataFromStore(selectedEmployeeData);
      setSkillsList(loadEmployeeSkillForm);
      setProjectDetailsList(selectedEmployeeData.employeeProject);
      if (selectedEmployeeData?.status?.toLowerCase() !== 'active') {
        setEmployeeStatusData({
          inActive: true,
          lastWorkingDay: selectedEmployeeData?.lastWorkingDate
            ? new Date(selectedEmployeeData.lastWorkingDate)
            : undefined,
        });
      }
      setIndustryExperienceList(selectedEmployeeData.employeeIndustry);
      setIsMount(true);
    }
    return () => {
      setIsMount(false);
      onCancel();
    };
  }, []);

  const validateTabForm = () => {
    if (!isValidTabArray[tabIndex]) {
      const updatedTabArray = isValidTabArray;
      updatedTabArray[tabIndex] = true;
      setIsValidTabArray(updatedTabArray);
    }
  };

  const unvalidateTabForm = () => {
    if (isValidTabArray[tabIndex]) {
      const updatedTabArray = isValidTabArray;
      updatedTabArray[tabIndex] = false;
      setIsValidTabArray(updatedTabArray);
    }
  };

  const setEmployeeDetailsTabFormData = (
    name: string,
    value: EmployeeDetailsFormInput
  ) => {
    const newState = { ...employeeDetailsFormData };
    (newState as { [key: string]: EmployeeDetailsFormInput })[name] = value;
    unvalidateTabForm();
    setEmployeeDetailsFormData(newState);
  };

  const onTabClick = (index: number) => {
    setTabIndex(index);
  };

  const onPrevious = () => {
    if (showPreviousButton) {
      setTabIndex(tabIndex - 1);
    }
  };

  const onNext = () => {
    if (showNextButton) {
      validateTabForm();
      setTabIndex(tabIndex + 1);
    }
  };

  const handleAddorEditSubmit = async (
    selectedProjects?: EmployeeProjectDetails[]
  ) => {
    setIsLoading(true);
    const empDetailsPayload = buildEmployeeDetailsPayload(
      employeeDetailsFormData,
      skillsList,
      industryExperienceList,
      selectedProjects,
      employeeStatusData,
      isEditMode
    );
    const response = isEditMode
      ? await editEmployee(empDetailsPayload)
      : await addNewEmployee(empDetailsPayload);

    if (response?.status === 201 || response?.status === 200) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    setIsLoading(false);
  };

  const renderTabList = () => (
    <>
      <Tab>
        <span className="icon icon-checkmark" hidden={!isValidTabArray[0]} />
        {TAB_DETAILS}
      </Tab>
      <Tab disabled={!isValidTabArray[0]}>
        <span className="icon icon-checkmark" hidden={!isValidTabArray[1]} />
        {TAB_SKILLS}
      </Tab>
      {isEditMode && (
        <Tab disabled={!isValidTabArray[1]}>
          <span className="icon icon-checkmark" hidden={!isValidTabArray[1]} />
          {TAB_PROJECTS}
        </Tab>
      )}
    </>
  );

  const renderTabPanel = () => (
    <>
      <TabPanel>
        <EmployeeDetails
          employeeDetailsFormData={employeeDetailsFormData}
          setEmployeeDetailsFormData={setEmployeeDetailsTabFormData}
          onCancel={onCancel}
          onNext={onNext}
          isEditMode={isEditMode}
        />
      </TabPanel>
      <TabPanel>
        <SkillsSection
          skillsList={skillsList}
          setSkillsList={setSkillsList}
          onCancel={onCancel}
          onPrevious={onPrevious}
          onNextOrSubmit={isEditMode ? onNext : handleAddorEditSubmit}
          skillOptions={skillOptions}
          setSkillOptions={setSkillOptions}
          industryExperienceList={industryExperienceList}
          setIndustryExperienceList={setIndustryExperienceList}
          isEditMode={isEditMode}
          isLoading={isLoading}
        />
      </TabPanel>
      {isEditMode && (
        <TabPanel>
          <ProjectDetails
            projectDetailsList={projectDetailsList}
            onCancel={onCancel}
            onPrevious={onPrevious}
            onSubmit={handleAddorEditSubmit}
            isLoading={isLoading}
            clientProjectDetailsList={clientProjectDetailsList}
            setClientProjectDetailsList={setClientProjectDetailsList}
          />
        </TabPanel>
      )}
    </>
  );

  return (
    <TabNav
      isControlled
      onTabClick={onTabClick}
      tabIndex={tabIndex}
      tabListChildren={renderTabList()}
      tabPanelChildren={renderTabPanel()}
    />
  );
};

export default AddOrEditEmployeeForm;
