import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'axios';
import { graphConfig } from 'authConfig';
import { ApiError } from 'types/global';
import CONFIG from 'app/config';
import LABELS from 'app/labels';
import {
  EmployeeDocumentsPayload,
  EmployeeProfileRequestPayload,
  FetchResumePayload,
  RequestPayload,
} from 'types/employeeDirectory';
import { UpdatedResumeData } from 'components/Resume/ResumeType';

export const getData = async () => {
  try {
    const { data } = await axios.get(`${CONFIG.BASE_URL}/filters`);
    return data;
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const fetchEmployeesData = async (payload: RequestPayload) => {
  try {
    const { data } = await axios.post(
      `${CONFIG.BASE_URL}/users/filtered-users`,
      payload
    );
    return data;
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const fetchEmployeeProfileData = async (
  payload: EmployeeProfileRequestPayload
) => {
  try {
    const { data } = await axios.post(
      `${CONFIG.BASE_URL}/users/${payload.employeeId}`,
      { skills: payload.skills }
    );
    return data;
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const fetchEmployeeBios = async (payload: EmployeeDocumentsPayload) => {
  try {
    const { data } = await axios({
      url: `${CONFIG.BASE_URL}/users/employees/${payload.id}/${payload.url}`,
      method: 'GET',
      responseType: 'blob',
    });
    const fileURL = window.URL.createObjectURL(data);
    // Setting various property values
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download =
      payload.url == LABELS.PROFILE.RESUME_PDF_URL
        ? `${payload.name}.pdf`
        : payload.url == LABELS.PROFILE.TSG_PDF_URL
        ? `${payload.name}_Profile.pdf`
        : `${payload.name}_Profile.docx`;
    alink.click();
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const downloadExcelTemplate = async () => {
  try {
    const { data } = await axios({
      url: `${CONFIG.BASE_URL}/admin/excelTemplate`,
      method: 'GET',
      responseType: 'blob',
    });
    const fileURL = window.URL.createObjectURL(data);
    const alink = document.createElement('a');
    alink.href = fileURL;
    alink.download = 'Template.xlsx';
    alink.click();
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const postEmployeeExcelSheet = async (file: FormData) => {
  const { data } = await axios.post(
    `${CONFIG.BASE_URL}/admin/employee/uploadExcelFile`,
    file,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
  return data;
};

export const addNewEmployee = async (payload: FormData) => {
  try {
    const response = await axios.post(
      `${CONFIG.BASE_URL}/admin/employee`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    toast.success(response.data);
    return response;
  } catch (err) {
    const error = err as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const editEmployee = async (payload: FormData) => {
  try {
    const response = await axios.put(
      `${CONFIG.BASE_URL}/admin/employee`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    toast.success(response.data.message);
    return response;
  } catch (err) {
    const error = err as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const postEmployeeResume = async (payload: UpdatedResumeData) => {
  try {
    const { data } = await axios.post(
      `${CONFIG.BASE_URL}/users/v2/pdf/generate`,
      payload
    );
    toast.success('Resume information updated successfully.');
    return data;
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const fetchEmployeeResume = async (payload: FetchResumePayload) => {
  try {
    const { data } = await axios.get(
      `${CONFIG.BASE_URL}/users/bio/${payload.ohrId}`
    );
    return data;
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const fetchUserInfo = async () => {
  try {
    const { data } = await axios({
      url: graphConfig.graphMeEndpoint,
      method: 'GET',
      responseType: 'blob',
    });
    return data;
  } catch (e) {
    // toast.error('Profile photo is not available with Microsoft');
    return null;
  }
};

export const getEmployeesData = createAsyncThunk(
  'directory/getEmployeesData',
  fetchEmployeesData
);

export const getFiltersData = createAsyncThunk(
  'directory/getFiltersData',
  getData
);

export const getEmployeeProfileData = createAsyncThunk(
  'directory/getEmployeeProfileData',
  fetchEmployeeProfileData
);

// export const postEmployeeResumeData = createAsyncThunk('', postEmployeeResume);

export const fetchEmployeeResumeData = createAsyncThunk(
  'directory/fetchEmployeeResumeData',
  fetchEmployeeResume
);

export const getEmployeeProfileBios = createAsyncThunk('', fetchEmployeeBios);

export const getEmployeesExcelTemplate = createAsyncThunk(
  '',
  downloadExcelTemplate
);
