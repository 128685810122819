export const skillLevelData = [
  {
    id: 'Beginner',
    name: 'Beginner',
  },
  {
    id: 'Intermediate',
    name: 'Intermediate',
  },
  {
    id: 'Expert',
    name: 'Expert',
  },
  {
    id: 'Master',
    name: 'Master',
  },
];
