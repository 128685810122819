import React, { type FC } from 'react';
import { InputProps } from './InputType';
import './Input.scss';
import LABELS from 'app/labels';

const Input: FC<InputProps> = ({
  className,
  disabled = false,
  id,
  name,
  onChange,
  onKeyPress,
  placeholder,
  type,
  inputVal,
  isRequired = false,
}) => {
  const {
    DIRECTORY: { ENTER },
  } = LABELS;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == ENTER) {
      onKeyPress?.();
    }
  };

  return (
    <div
      className={`input ${className ? className : ''} ${
        disabled ? 'input__disabled' : ''
      }`}
    >
      <input
        type={type}
        id={id}
        name={name}
        data-testid="input"
        placeholder={placeholder}
        disabled={disabled}
        value={inputVal}
        autoComplete="off"
        onChange={(e) => handleChange(e)}
        onKeyDown={(e) => handleKeyPress(e)}
        required={isRequired}
      />
    </div>
  );
};

export default Input;
