import { IconMapping } from 'types/employeeDirectory';
import adobeAem from 'assets/images/Adobe.svg';
import agile from 'assets/images/agile.svg';
import alibabaCloud from 'assets/images/alibaba.svg';
import angularJs from 'assets/images/Angular.svg';
import angular from 'assets/images/Angular.svg';
import animation from 'assets/images/animation.svg';
import aws from 'assets/images/aws.svg';
import azure from 'assets/images/Azure.svg';
import bootstrap from 'assets/images/bootstrap.svg';
import chash from 'assets/images/Csharp.svg';
import cassandra from 'assets/images/cassandra.svg';
import couchdb from 'assets/images/couch.svg';
import css from 'assets/images/css.svg';
import dbTwo from 'assets/images/db2.svg';
import django from 'assets/images/django.svg';
import docker from 'assets/images/docker.svg';
import amazondynamodb from 'assets/images/dynamodb.svg';
import expressjs from 'assets/images/expressjs.svg';
import firebaseGoogle from 'assets/images/firebase.svg';
import flask from 'assets/images/flask.svg';
import git from 'assets/images/git.svg';
import googleCloud from 'assets/images/GoogelCloud.svg';
import gradle from 'assets/images/Gradle.svg';
import heroku from 'assets/images/heroku.svg';
import hibernate from 'assets/images/hibernate.svg';
import html from 'assets/images/HTML.svg';
import ibmcloud from 'assets/images/ibmcloud.svg';
import industries from 'assets/images/Industries.svg';
import java from 'assets/images/Java.svg';
import jenkins from 'assets/images/jenkins.svg';
import jira from 'assets/images/jira.svg';
import javascript from 'assets/images/JS.svg';
import kafka from 'assets/images/kafka.svg';
import kotlin from 'assets/images/kotlin.svg';
import laravel from 'assets/images/laravel.svg';
import magento from 'assets/images/magento.svg';
import mariadb from 'assets/images/mariadb.svg';
import maven from 'assets/images/Maven.svg';
import mongodb from 'assets/images/mongodb.svg';
import mySql from 'assets/images/mysqp.svg';
import neo4j from 'assets/images/neo4j.svg';
import nginx from 'assets/images/nginx.svg';
import nodeJs from 'assets/images/NodeJS.svg';
import openstack from 'assets/images/openstack.svg';
import oracle from 'assets/images/oracle.svg';
import php from 'assets/images/PHP.svg';
import postgreSql from 'assets/images/Postgres.svg';
import postman from 'assets/images/postman.svg';
import python from 'assets/images/Python.svg';
import rabbitmq from 'assets/images/rabbitMQ.svg';
import reactJs from 'assets/images/react.svg';
import render from 'assets/images/render.svg';
import ruby from 'assets/images/ruby.svg';
import scaleway from 'assets/images/scaleway.svg';
import sitecore from 'assets/images/sitecore.svg';
import springBoot from 'assets/images/springBoot.svg';
import swagger from 'assets/images/Swagger.svg';
import terraform from 'assets/images/terraform.svg';
import apachetomcat from 'assets/images/tomcat.svg';
import vultr from 'assets/images/vultr.svg';
import carbondatastructured from 'assets/images/carbon_data-structured.svg';
import c from 'assets/images/c.svg';
import ibmdbtwo from 'assets/images/ibmdb2.svg';
import oauth from 'assets/images/oauth.svg';

const SKILLS_MAPPING: IconMapping = {
  java,
  python,
  chash,
  magento,
  springBoot,
  swagger,
  hibernate,
  adobeAem,
  agile,
  alibabaCloud,
  angularJs,
  angular,
  animation,
  aws,
  azure,
  bootstrap,
  cassandra,
  couchdb,
  css,
  django,
  docker,
  amazondynamodb,
  expressjs,
  firebaseGoogle,
  flask,
  git,
  googleCloud,
  gradle,
  heroku,
  html,
  ibmcloud,
  industries,
  jenkins,
  jira,
  javascript,
  kafka,
  kotlin,
  laravel,
  mariadb,
  maven,
  mongodb,
  mySql,
  neo4j,
  nginx,
  nodeJs,
  openstack,
  oracle,
  php,
  postgreSql,
  postman,
  rabbitmq,
  reactJs,
  render,
  ruby,
  scaleway,
  sitecore,
  terraform,
  apachetomcat,
  vultr,
  oauth,
  ibmdbtwo,
  c,
  carbondatastructured,
  cssThree: css,
  j2ee: java,
  java8: java,
  html5: html,
  db2: dbTwo,
};

export const getSkillsIcon = (key: string) => SKILLS_MAPPING[key];
