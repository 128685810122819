import { toast } from 'react-toastify';
import axios from 'axios';
import { ApiError } from 'types/global';
import CONFIG from 'app/config';

export const getProjectData = async () => {
  try {
    const { data } = await axios.get(`${CONFIG.BASE_URL}/projects/all`);
    return data;
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const getSkillData = async () => {
  try {
    const { data } = await axios.get(`${CONFIG.BASE_URL}/skills`);
    return data;
  } catch (e) {
    const error = e as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const addProject = async (payload: Blob) => {
  try {
    const response = await axios.post(
      `${CONFIG.BASE_URL}/admin/project`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.data.errors) {
      toast.error(response.data.errors[0].message);
    } else {
      toast.success(response.data);
    }
    return response;
  } catch (err) {
    const error = err as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const editProject = async (payload: Blob) => {
  try {
    const response = await axios.put(
      `${CONFIG.BASE_URL}/admin/project`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    toast.success(response.data);
    return response;
  } catch (err) {
    const error = err as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const addSkill = async (payload: Blob) => {
  try {
    const response = await axios.post(
      `${CONFIG.BASE_URL}/admin/skill`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    if (response.data.errors) {
      toast.error(response.data.errors[0].message);
    } else {
      toast.success(response.data);
    }
    return response;
  } catch (err) {
    const error = err as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const deleteSkill = async (skillKey: string) => {
  try {
    const response = await axios.delete(
      `${CONFIG.BASE_URL}/admin/skill/${skillKey}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    toast.success(response.data);
    return response;
  } catch (err) {
    const error = err as ApiError;
    toast.error(error.message);
    return null;
  }
};

export const editSkill = async (payload: Blob) => {
  try {
    const response = await axios.put(
      `${CONFIG.BASE_URL}/admin/skill `,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    toast.success(response.data);
    return response;
  } catch (err) {
    const error = err as ApiError;
    toast.error(error.message);
    return null;
  }
};
