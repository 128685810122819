import { SyntheticEvent, useState, type FC, useEffect } from 'react';
import { useAppSelector } from 'types/hooks';
import { selectFilterData } from 'redux/slices/employeeDirectory/employeeDirectorySlice';
import {
  EmployeeDetailsProps,
  EmployeeDetailsFormInput,
} from 'components/AddOrEditEmployee/Types/AddOrEditEmployeeType';
import {
  defaultEmployeeDetailsErrorMap,
  workModeList,
} from 'components/AddOrEditEmployee/constants/AddOrEditEmployeeConstants';
import LABELS from 'app/labels';
import Button from 'components/common/Button';
import InputBox from 'components/common/InputBox';
import TextArea from 'components/common/TextArea';
import Dropdown from 'components/common/Dropdown';
import FileUploader from 'components/common/FileUploader';
import PageHeader from 'components/AddOrEditEmployee/TabPages/PageHeader';
import { educationDropdownData } from 'data/educationDropdownData';
import DatePickerForm from 'components/common/DatePicker';
import './EmployeeDetails.scss';

const EmployeeDetails: FC<EmployeeDetailsProps> = ({
  employeeDetailsFormData,
  setEmployeeDetailsFormData,
  onCancel,
  onNext,
  isEditMode,
}) => {
  const {
    ADD_OR_EDIT_EMPLOYEE: {
      CANCEL,
      NEXT,
      EMPLOYEE_DETAIL: {
        HEADER,
        SUBHEADER,
        PROFILE_PHOTO,
        EMPLOYEE_RESUME_REGULAR,
        RESUME_DETAILS,
        UPLOAD_PHOTO,
        RESUME_FILE_TEXT,
        RESUME_FILE_ERROR,
        PHOTO_UPLOAD_ERROR,
        PHOTO_FILE_TEXT,
        UPLOAD_RESUME,
        FULL_NAME,
        LATTICE_LINK,
        LATTICE_LINK_PLACEHOLDER,
        FULL_NAME_PLACEHOLDER,
        FULL_NAME_ERROR,
        EMAIL_ADDRESS,
        EMAIL_ADDRESS_PLACEHOLDER,
        EMAIL_ADDRESS_ERROR,
        PHONE_NUMBER,
        PHONE_NUMBER_PLACEHOLDER,
        HOME_ADDRESS,
        HOME_ADDRESS_PLACEHOLDER,
        EMPLOYEE_ID,
        EMPLOYEE_ID_PLACEHOLDER,
        EMPLOYEE_ID_ERROR,
        PRACTICE,
        PRACTICE_PLACEHOLDER,
        PRACTICE_ERROR,
        JOIN_DATE,
        JOIN_DATE_PLACEHOLDER,
        JOIN_DATE_ERROR,
        OHR_ID,
        OHR_ID_PLACEHOLDER,
        OHR_ID_ERROR,
        LOCATION,
        LOCATION_PLACEHOLDER,
        LOCATION_ERROR,
        EMPLOYEE_DESIGNATION,
        EMPLOYEE_DESIGNATION_PLACEHOLDER,
        EMPLOYEE_DESIGNATION_ERROR,
        LEVEL,
        LEVEL_PLACEHOLDER,
        LEVEL_ERROR,
        WORK_MODE,
        WORK_MODE_PLACEHOLDER,
        WORK_MODE_ERROR,
        REPORTING_MANAGER,
        REPORTING_MANAGER_PLACEHOLDER,
        REPORTING_MANAGER_ERROR,
        MANAGER_ID,
        MANAGER_ID_PLACEHOLDER,
        MANAGER_ID_ERROR,
        EDUCATION,
        EDUCATION_PLACEHOLDER,
        EDUCATION_ERROR,
        EXPERIENCE,
        EXPERIENCE_YEAR,
        EXPERIENCE_MONTH,
        EXPERIENCE_PLACEHOLDER_YEAR,
        EXPERIENCE_PLACEHOLDER_MONTH,
        EXPERIENCE_YEAR_ERROR,
        EXPERIENCE_MONTH_ERROR,
      },
    },
  } = LABELS;

  const [errorMap, setErrorMap] = useState<any>(defaultEmployeeDetailsErrorMap);

  useEffect(() => {
    if (isEditMode) {
      if (!employeeDetailsFormData?.resume) {
        setErrorMap({
          ...errorMap,
          resumeRegular: false,
        });
      } else {
        setErrorMap(defaultEmployeeDetailsErrorMap);
      }
      if (employeeDetailsFormData?.photo?.includes('default_image.png')) {
        setErrorMap({
          ...errorMap,
          profilePhoto: false,
        });
      }
    }
  }, [employeeDetailsFormData]);

  const { location, experienceLevel, department } =
    useAppSelector(selectFilterData) || {};

  const handleExperienceChange = (type: string, value: number) => {
    if (type === 'months' && (value < 0 || value > 11)) {
      return;
    }
    if (type === 'years' && value < 0) {
      return;
    }
    if (type === 'months') {
      onFormChange('experienceInMonths', value.toString());
    } else {
      onFormChange('experienceInYears', value.toString());
    }
  };

  const handleFileUpload = (fileName: string, val: File | null) => {
    setEmployeeDetailsFormData(fileName, val);
  };

  const onFormChange = (id: string, val: EmployeeDetailsFormInput) => {
    setEmployeeDetailsFormData(id, val);

    if (errorMap[id]) {
      const newErrorMap = { ...errorMap };
      newErrorMap[id] = false;
      setErrorMap(newErrorMap);
    }
  };

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    let allFieldsValid = true;
    const newErrorMap = { ...errorMap };

    for (const [key] of Object.entries(errorMap)) {
      if (!employeeDetailsFormData[key as string]) {
        allFieldsValid = false;
        newErrorMap[key] = true;
      }
    }

    if (allFieldsValid) {
      onNext();
    } else {
      setErrorMap(newErrorMap);
    }
  };
  return (
    <form onSubmit={onSubmit} className="employeeDetailsContainer">
      <div className="employeeDetailsContainer__employee-details">
        <PageHeader title={HEADER} subtitle={SUBHEADER} />
        <div className="employeeDetailsContainer__employee-details__form">
          <div className="employeeDetailsContainer__employee-details__form-group">
            <InputBox
              type="text"
              id="fullName"
              name="fullName"
              showLabel
              labelText={FULL_NAME}
              placeholder={FULL_NAME_PLACEHOLDER}
              showIcon
              iconClassName="user-circular"
              inputVal={employeeDetailsFormData.fullName}
              className="employeeDetailsContainer__employee-details__full-name"
              onChange={(val) => onFormChange('fullName', val)}
              errorText={FULL_NAME_ERROR}
              showError={errorMap.fullName}
            />
            <InputBox
              type="email"
              id="email"
              name="email"
              showLabel
              labelText={EMAIL_ADDRESS}
              placeholder={EMAIL_ADDRESS_PLACEHOLDER}
              showIcon
              iconClassName="email"
              inputVal={employeeDetailsFormData.email}
              className="employeeDetailsContainer__employee-details__email"
              onChange={(val) => onFormChange('email', val)}
              errorText={EMAIL_ADDRESS_ERROR}
              showError={errorMap.email}
            />
            {
              <InputBox
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                showLabel
                labelText={PHONE_NUMBER}
                placeholder={PHONE_NUMBER_PLACEHOLDER}
                showIcon
                iconClassName="phone"
                inputVal={employeeDetailsFormData.phoneNumber}
                className="employeeDetailsContainer__employee-details__phone-number"
                onChange={(val) => onFormChange('phoneNumber', val)}
              />
            }
            {
              <TextArea
                id="homeAddress"
                name="homeAddress"
                maxLength={500}
                showLabel
                labelText={HOME_ADDRESS}
                placeholder={HOME_ADDRESS_PLACEHOLDER}
                inputVal={employeeDetailsFormData.homeAddress}
                className="employeeDetailsContainer__employee-details__home-address"
                onChange={(val) => onFormChange('homeAddress', val)}
              />
            }
          </div>
          <div className="employeeDetailsContainer__employee-details__form-group">
            <InputBox
              type="text"
              id="employeeId"
              name="employeeId"
              showLabel
              labelText={EMPLOYEE_ID}
              placeholder={EMPLOYEE_ID_PLACEHOLDER}
              showIcon
              iconClassName="id"
              inputVal={employeeDetailsFormData.employeeId}
              className="employeeDetailsContainer__employee-details__employee-id"
              onChange={(val) => onFormChange('employeeId', val)}
              errorText={EMPLOYEE_ID_ERROR}
              showError={errorMap.employeeId}
              disabled={isEditMode}
            />
            <Dropdown
              id="practice"
              inputName="practice"
              labelText={PRACTICE}
              placeholder={PRACTICE_PLACEHOLDER}
              list={department}
              filterLabel={
                employeeDetailsFormData.practice
                  ? employeeDetailsFormData.practice[0].name
                  : ''
              }
              prefillData={employeeDetailsFormData.practice}
              className="employeeDetailsContainer__employee-details__dropdown employeeDetailsContainer__employee-details__practice"
              onSelect={(val) => onFormChange('practice', val)}
              showSelectedOptionsCount={false}
              showLabel
              useFormStyles
              showError={errorMap.practice}
              errorText={PRACTICE_ERROR}
            />
            <DatePickerForm
              className="employeeDetailsContainer__employee-details__join-date"
              id="joinDate"
              name="joinDate"
              showLabel
              labelText={JOIN_DATE}
              placeholder={JOIN_DATE_PLACEHOLDER}
              value={employeeDetailsFormData.joinDate}
              onChange={(val) => onFormChange('joinDate', val)}
              showError={errorMap.joinDate}
              errorText={JOIN_DATE_ERROR}
            />
            <InputBox
              type="text"
              id="ohrId"
              name="ohrId"
              showLabel
              labelText={OHR_ID}
              placeholder={OHR_ID_PLACEHOLDER}
              iconClassName="ohr-id"
              inputVal={employeeDetailsFormData.ohrId}
              className="employeeDetailsContainer__employee-details__ohr-id"
              onChange={(val) => onFormChange('ohrId', val)}
              showError={errorMap.ohrId}
              errorText={OHR_ID_ERROR}
            />
            <Dropdown
              id="location"
              inputName="location"
              labelText={LOCATION}
              placeholder={LOCATION_PLACEHOLDER}
              list={location}
              filterLabel={
                employeeDetailsFormData.location
                  ? employeeDetailsFormData.location[0].name
                  : ''
              }
              prefillData={employeeDetailsFormData.location}
              className="employeeDetailsContainer__employee-details__dropdown employeeDetailsContainer__employee-details__location"
              onSelect={(val) => onFormChange('location', val)}
              showSelectedOptionsCount={false}
              showLabel
              useFormStyles
              showError={errorMap.location}
              errorText={LOCATION_ERROR}
            />
          </div>
          <div className="employeeDetailsContainer__employee-details__form-group">
            <InputBox
              type="text"
              id="employeeDesignation"
              name="employeeDesignation"
              showLabel
              labelText={EMPLOYEE_DESIGNATION}
              placeholder={EMPLOYEE_DESIGNATION_PLACEHOLDER}
              inputVal={employeeDetailsFormData.employeeDesignation}
              className="employeeDetailsContainer__employee-details__employee-designation"
              onChange={(val) => onFormChange('employeeDesignation', val)}
              showError={errorMap.employeeDesignation}
              errorText={EMPLOYEE_DESIGNATION_ERROR}
            />
            <Dropdown
              id="level"
              inputName="level"
              labelText={LEVEL}
              placeholder={LEVEL_PLACEHOLDER}
              list={experienceLevel}
              filterLabel={
                employeeDetailsFormData.level
                  ? employeeDetailsFormData.level[0].name
                  : ''
              }
              prefillData={employeeDetailsFormData.level}
              className="employeeDetailsContainer__employee-details__dropdown employeeDetailsContainer__employee-details__level"
              onSelect={(val) => onFormChange('level', val)}
              showSelectedOptionsCount={false}
              showLabel
              useFormStyles
              showError={errorMap.level}
              errorText={LEVEL_ERROR}
            />
            <InputBox
              type="text"
              id="reportingManager"
              name="reportingManager"
              showLabel
              labelText={REPORTING_MANAGER}
              placeholder={REPORTING_MANAGER_PLACEHOLDER}
              showIcon
              iconClassName="user-circular"
              inputVal={employeeDetailsFormData.reportingManager}
              className="employeeDetailsContainer__employee-details__reporting-manager"
              onChange={(val) => onFormChange('reportingManager', val)}
              showError={errorMap.reportingManager}
              errorText={REPORTING_MANAGER_ERROR}
            />
            <InputBox
              type="text"
              id="managerId"
              name="managerId"
              showLabel
              labelText={MANAGER_ID}
              placeholder={MANAGER_ID_PLACEHOLDER}
              showIcon
              iconClassName="id"
              inputVal={employeeDetailsFormData.managerId}
              className="employeeDetailsContainer__employee-details__manager-id"
              onChange={(val) => onFormChange('managerId', val)}
              showError={errorMap.managerId}
              errorText={MANAGER_ID_ERROR}
            />
            <Dropdown
              id="workMode"
              inputName="workMode"
              labelText={WORK_MODE}
              placeholder={WORK_MODE_PLACEHOLDER}
              list={workModeList}
              filterLabel={
                employeeDetailsFormData.workMode
                  ? employeeDetailsFormData.workMode[0].name
                  : ''
              }
              prefillData={employeeDetailsFormData.workMode}
              className="employeeDetailsContainer__employee-details__dropdown employeeDetailsContainer__employee-details__workMode"
              onSelect={(val) => onFormChange('workMode', val)}
              showSelectedOptionsCount={false}
              showLabel
              useFormStyles
              showError={errorMap.workMode}
              errorText={WORK_MODE_ERROR}
            />
          </div>
          <div className="employeeDetailsContainer__employee-details__form-group">
            <Dropdown
              id="education"
              inputName="education"
              labelText={EDUCATION}
              placeholder={EDUCATION_PLACEHOLDER}
              list={educationDropdownData}
              filterLabel={
                employeeDetailsFormData.education
                  ? employeeDetailsFormData.education[0].name
                  : ''
              }
              prefillData={employeeDetailsFormData.education}
              className="employeeDetailsContainer__employee-details__dropdown employeeDetailsContainer__employee-details__education"
              onSelect={(val) => onFormChange('education', val)}
              showSelectedOptionsCount={false}
              showLabel
              useFormStyles
              showError={errorMap.education}
              errorText={EDUCATION_ERROR}
            />
            <div className="employeeDetailsContainer__employee-details__employee-experience">
              <span>{EXPERIENCE}</span>
              <div className="employeeDetailsContainer__employee-details__employee-experience__input">
                <InputBox
                  type="number"
                  id="experienceYears"
                  name="experienceYears"
                  showLabel
                  labelText={EXPERIENCE_YEAR}
                  placeholder={EXPERIENCE_PLACEHOLDER_YEAR}
                  inputVal={employeeDetailsFormData.experienceInYears}
                  className="employeeDetailsContainer__employee-details__employee-experience__input__year"
                  onChange={(val) =>
                    handleExperienceChange('years', parseInt(val))
                  }
                  showError={errorMap.experienceInYears}
                  errorText={EXPERIENCE_YEAR_ERROR}
                />
                <InputBox
                  type="number"
                  id="experienceMonths"
                  name="experienceMonths"
                  showLabel
                  labelText={EXPERIENCE_MONTH}
                  placeholder={EXPERIENCE_PLACEHOLDER_MONTH}
                  inputVal={employeeDetailsFormData.experienceInMonths}
                  className="employeeDetailsContainer__employee-details__employee-experience__input__month"
                  onChange={(val) =>
                    handleExperienceChange('months', parseInt(val))
                  }
                  showError={errorMap.experienceInMonths}
                  errorText={EXPERIENCE_MONTH_ERROR}
                />
              </div>
            </div>
            <InputBox
              type="text"
              id="latticeLink"
              name="latticeLink"
              showLabel
              labelText={LATTICE_LINK}
              placeholder={LATTICE_LINK_PLACEHOLDER}
              iconClassName="user-circular"
              inputVal={employeeDetailsFormData.latticeLink}
              className="employeeDetailsContainer__employee-details__full-name"
              onChange={(val) => onFormChange('latticeLink', val)}
            />
          </div>
        </div>
        <div className="employeeDetailsContainer__employee-details__file-upload-section">
          <div className="employeeDetailsContainer__employee-details__file-upload-group">
            <span>{RESUME_DETAILS}</span>
            <FileUploader
              id="resumeRegular"
              showIcon
              iconClassName="icon-pdf-file"
              showLabel
              labelTitleText={
                isEditMode && !employeeDetailsFormData?.resume
                  ? `${EMPLOYEE_RESUME_REGULAR}*`
                  : EMPLOYEE_RESUME_REGULAR
              }
              labelFileText={RESUME_FILE_TEXT}
              description={UPLOAD_RESUME}
              showError={errorMap.resumeRegular}
              errorText={RESUME_FILE_ERROR}
              alreadySelectedFile={employeeDetailsFormData.resumeRegular}
              handleFileSelection={(val) =>
                handleFileUpload('resumeRegular', val)
              }
            />
          </div>
          <div className="employeeDetailsContainer__employee-details__file-upload-group">
            <span>
              {isEditMode &&
              (!employeeDetailsFormData?.photo ||
                employeeDetailsFormData?.photo?.includes('default_image.png'))
                ? `${PROFILE_PHOTO}*`
                : PROFILE_PHOTO}
            </span>
            <FileUploader
              id="profilePhoto"
              showIcon
              iconClassName="icon-image"
              showLabel
              labelTitleText=""
              labelFileText={PHOTO_FILE_TEXT}
              description={UPLOAD_PHOTO}
              showError={errorMap.profilePhoto}
              errorText={PHOTO_UPLOAD_ERROR}
              alreadySelectedFile={employeeDetailsFormData.profilePhoto}
              handleFileSelection={(val) =>
                handleFileUpload('profilePhoto', val)
              }
            />
          </div>
        </div>
        {isEditMode && (
          <div className="employeeDetailsContainer__employee-details__file-preview">
            <div className="employeeDetailsContainer__employee-details__file-preview__resume">
              {employeeDetailsFormData?.resume &&
                !employeeDetailsFormData.resumeRegular &&
                `${employeeDetailsFormData.fullName}.pdf`}
            </div>
            {employeeDetailsFormData?.photo &&
              !employeeDetailsFormData?.photo?.includes(
                'default_image.png'
              ) && (
                <div className="employeeDetailsContainer__employee-details__file-preview__photo">
                  <div className="employeeDetailsContainer__employee-details__file-preview__photo-wrapper">
                    <img
                      src={
                        employeeDetailsFormData.profilePhoto
                          ? URL.createObjectURL(
                              employeeDetailsFormData.profilePhoto
                            )
                          : employeeDetailsFormData.photo
                      }
                      alt={employeeDetailsFormData.fullName}
                    />
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
      <div className="employeeDetailsContainer__employee-details__footer">
        <Button
          className="employeeDetailsContainer__employee-details__btn-cancel"
          value={CANCEL}
          onClick={onCancel}
          isSecondary
          data-testid="card-body-buttons-cancel"
        />
        <Button
          type="submit"
          className="employeeDetailsContainer__employee-details__btn-next"
          value={NEXT}
          data-testid="card-body-buttons-next"
        />
      </div>
    </form>
  );
};

export default EmployeeDetails;
