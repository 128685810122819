import { FC } from 'react';
import './PageHeader.scss';
const PageHeader: FC<{
  className?: string;
  title: string;
  subtitle?: string;
}> = ({ className, title, subtitle }) => (
  <div className={`page-header ${className}`}>
    <strong>{title}</strong>
    <br />
    {subtitle && <div className="page-header__subtitle">{subtitle}</div>}
  </div>
);

export default PageHeader;
