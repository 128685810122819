import React, { type FC } from 'react';
import { TextAreaProps } from './TextAreaType';
import LABELS from 'app/labels';
import './TextArea.scss';

const TextArea: FC<TextAreaProps> = ({
  className,
  name,
  rows,
  cols,
  minLength,
  maxLength,
  disabled = false,
  id,
  onChange,
  onKeyPress,
  placeholder = '',
  inputVal = '',
  showIcon = false,
  iconClassName,
  showLabel = false,
  labelText,
  showError = false,
  errorText,
  isRequired = false,
}) => {
  const {
    DIRECTORY: { ENTER },
  } = LABELS;
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key == ENTER) {
      onKeyPress?.();
    }
  };

  return (
    <div id={id} className={`textArea__container ${className}`}>
      {showLabel && <div className="textArea___label">{labelText}</div>}
      <div className="textArea__input-wrapper">
        {showIcon && (
          <span
            className={`textArea__input-wrapper__icon icon-${iconClassName}`}
          />
        )}
        <textarea
          className={`textArea__input-wrapper__input ${
            showError && 'error-input'
          } ${showIcon && 'textArea__input-wrapper__input__icon'}`}
          name={name}
          rows={rows}
          cols={cols}
          minLength={minLength}
          maxLength={maxLength}
          disabled={disabled}
          value={inputVal}
          autoComplete="off"
          data-testid="input"
          placeholder={placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          required={isRequired}
        />
      </div>
      {showError && (
        <div className="inputContainer__error-text">{errorText}</div>
      )}
    </div>
  );
};

export default TextArea;
