import { type FC } from 'react';
import { ButtonProps } from './ButtonType';
import './Button.scss';

const Button: FC<ButtonProps> = ({
  className,
  type,
  disabled = false,
  value,
  isButtonLink = false,
  isSecondary = false,
  isShowIcon = false,
  iconClassName = 'icon-plus',
  onClick,
}) => (
  <button
    className={`button ${isButtonLink ? 'button__link' : ''} ${
      isSecondary ? 'button__secondary' : ''
    } ${className ? className : ''} ${disabled ? 'button__disabled' : ''}`}
    type={type}
    data-testid="button"
    disabled={disabled}
    onClick={onClick}
  >
    {isShowIcon && (
      <span className={`icon ${iconClassName ? iconClassName : ''}`} />
    )}
    <span className="button__label">{value}</span>
  </button>
);

export default Button;
