import { Skill } from 'types/employeeDirectory';

export const getSkillList = (skillList: Skill[]) => {
  if (!skillList.length) {
    return {
      frontendList: [],
      backendList: [],
      databaseList: [],
      cmsList: [],
      otherList: [],
      cloudserverList: [],
      designstrategyList: [],
      projectmanagementList: [],
    };
  }

  const categorylist = skillList.map((skill) => skill.skillCategory);

  const uniqueCategoryList = categorylist.filter(
    (value, index) => categorylist.indexOf(value) === index
  );

  const finalList = <any>[];
  uniqueCategoryList.map((category) => {
    const listOfMatchedSkills = <any>[];
    let cat;
    skillList.map((skill) => {
      if (skill.skillCategory === category) {
        cat = skill.skillCategory;
        listOfMatchedSkills.push(skill);
      }
    });
    const obj = { [cat as any]: listOfMatchedSkills };
    finalList.push(obj);
  });
  return {
    skillCategoryList: finalList,
  };
};
