import SkeletonLoader from 'components/common/SkeletonLoader';

export const EmployeeDirectoryFilterLoader = () => (
  <div className="directory__filters">
    <span>Filter By:</span>
    <div className="dropdown">
      <SkeletonLoader
        skeletonWidth={165}
        skeletonHeight={38}
        skeletonCount={1}
      />
    </div>
    <div className="dropdown">
      <SkeletonLoader
        skeletonWidth={137}
        skeletonHeight={38}
        skeletonCount={1}
      />
    </div>
    <div className="dropdown">
      <SkeletonLoader
        skeletonWidth={204}
        skeletonHeight={38}
        skeletonCount={1}
      />
    </div>
    <div className="dropdown">
      <SkeletonLoader
        skeletonWidth={109}
        skeletonHeight={38}
        skeletonCount={1}
      />
    </div>
    <div className="dropdown">
      <SkeletonLoader
        skeletonWidth={154}
        skeletonHeight={38}
        skeletonCount={1}
      />
    </div>
    <div className="reset-loader">
      <SkeletonLoader
        skeletonWidth={80}
        skeletonHeight={16}
        skeletonCount={1}
      />
    </div>
  </div>
);

export const EmployeeCardsLoader = () => (
  <div className="directory__cardsection">
    <div className="directory__cardsection-title">
      <SkeletonLoader
        skeletonWidth={130}
        skeletonHeight={16}
        skeletonCount={1}
      />
    </div>
    <div className="directory__cardsection-cardslist">
      <div className="card">
        <div className="card__header">
          <div className="avatar">
            <SkeletonLoader
              skeletonWidth={50}
              skeletonHeight={50}
              skeletonCount={1}
            />
          </div>
          <div className="card__header__info">
            <span className="card__header__info_name">
              <SkeletonLoader
                skeletonWidth={140}
                skeletonHeight={14}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={110}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="card__body">
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__bullets">
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info card__body__icons">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>

            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={90}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="card__footer">
          <SkeletonLoader
            skeletonWidth={200}
            skeletonHeight={18}
            skeletonCount={1}
          />
        </div>
      </div>
      <div className="card">
        <div className="card__header">
          <div className="avatar">
            <SkeletonLoader
              skeletonWidth={50}
              skeletonHeight={50}
              skeletonCount={1}
            />
          </div>
          <div className="card__header__info">
            <span className="card__header__info_name">
              <SkeletonLoader
                skeletonWidth={140}
                skeletonHeight={14}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={110}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="card__body">
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__bullets">
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info card__body__icons">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>

            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={90}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="card__footer">
          <SkeletonLoader
            skeletonWidth={200}
            skeletonHeight={18}
            skeletonCount={1}
          />
        </div>
      </div>
      <div className="card">
        <div className="card__header">
          <div className="avatar">
            <SkeletonLoader
              skeletonWidth={50}
              skeletonHeight={50}
              skeletonCount={1}
            />
          </div>
          <div className="card__header__info">
            <span className="card__header__info_name">
              <SkeletonLoader
                skeletonWidth={140}
                skeletonHeight={14}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={110}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="card__body">
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__bullets">
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info card__body__icons">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>

            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={90}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="card__footer">
          <SkeletonLoader
            skeletonWidth={200}
            skeletonHeight={18}
            skeletonCount={1}
          />
        </div>
      </div>
      <div className="card">
        <div className="card__header">
          <div className="avatar">
            <SkeletonLoader
              skeletonWidth={50}
              skeletonHeight={50}
              skeletonCount={1}
            />
          </div>
          <div className="card__header__info">
            <span className="card__header__info_name">
              <SkeletonLoader
                skeletonWidth={140}
                skeletonHeight={14}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={110}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="card__body">
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__bullets">
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info card__body__icons">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>

            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={90}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="card__footer">
          <SkeletonLoader
            skeletonWidth={200}
            skeletonHeight={18}
            skeletonCount={1}
          />
        </div>
      </div>
      <div className="card">
        <div className="card__header">
          <div className="avatar">
            <SkeletonLoader
              skeletonWidth={50}
              skeletonHeight={50}
              skeletonCount={1}
            />
          </div>
          <div className="card__header__info">
            <span className="card__header__info_name">
              <SkeletonLoader
                skeletonWidth={140}
                skeletonHeight={14}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={110}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="card__body">
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__bullets">
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info card__body__icons">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>

            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={90}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="card__footer">
          <SkeletonLoader
            skeletonWidth={200}
            skeletonHeight={18}
            skeletonCount={1}
          />
        </div>
      </div>
      <div className="card">
        <div className="card__header">
          <div className="avatar">
            <SkeletonLoader
              skeletonWidth={50}
              skeletonHeight={50}
              skeletonCount={1}
            />
          </div>
          <div className="card__header__info">
            <span className="card__header__info_name">
              <SkeletonLoader
                skeletonWidth={140}
                skeletonHeight={14}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={110}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="card__body">
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__info">
            <span className="card__header__info_size">
              <SkeletonLoader
                skeletonWidth={105}
                skeletonHeight={9}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="card__body__bullets">
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info card__body__icons">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>

            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={60}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={80}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
            <div className="card__body__info">
              <span className="card__header__info_size">
                <SkeletonLoader
                  skeletonWidth={90}
                  skeletonHeight={9}
                  skeletonCount={1}
                />
              </span>
            </div>
          </div>
        </div>
        <div className="card__footer">
          <SkeletonLoader
            skeletonWidth={200}
            skeletonHeight={18}
            skeletonCount={1}
          />
        </div>
      </div>
    </div>
  </div>
);
