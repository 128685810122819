import { type FC } from 'react';
import { TooltipProps } from './TooltipType';
import './Tooltip.scss';

const Tooltip: FC<TooltipProps> = ({
  title,
  children,
  position = 'bottom',
}) => (
  <div className={`tooltip`}>
    {children}
    <div className={`tooltip__tooltiptext tooltip__${position}`}>
      <span>{title}</span>
      <span className={`tooltip__arrow tooltip__arrow-${position}`} />
    </div>
  </div>
);

export default Tooltip;
