export const getShortName = (
  name: string,
  saperatedBy = ' ',
  joinedBy = '',
  length = 2
): string => {
  // Extracting the first letter of first name and last name eg, Amit Sharma = AS
  const shortName = name
    .trim()
    .split(saperatedBy)
    .map((word) => word[0]) // extracting first character of each word eg, from Amit get A.
    .join(joinedBy)
    .substring(0, length);

  return shortName.toUpperCase();
};
