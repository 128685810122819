import { type FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import EmployeeDirectory from 'components/EmployeeDirectory';
import Analytics from 'components/Analytics';
import ProjectManagement from 'components/ProjectManagement';
import Departments from 'components/Departments';
import Logo from 'assets/images/logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import LABELS from 'app/labels';
import './Home.scss';

const Home: FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="home">
        <Tabs className="home__tabs" selectedTabClassName="home__tab--selected">
          <div className="home__left-panel">
            <div className="home__left-panel__logo-and-tabs">
              <Link to="/">
                <img src={Logo} alt="logo" className="home__logo" />
              </Link>
              <TabList>
                <Tab className="home__tab">
                  <span className="home__item-directory">
                    <span className="icon icon-directory" />
                    <span
                      className="home__tab-text"
                      onClick={() => navigate('/')}
                    >
                      Employee Directory
                    </span>
                  </span>
                </Tab>
                <Tab className="home__tab">
                  <span className="home__item-analytic">
                    <span className="icon icon-analytics" />
                    <span className="home__tab-text">Analytics</span>
                  </span>
                </Tab>
                <Tab className="home__tab">
                  <span className="home__item-project">
                    <span className="icon icon-briefcase" />
                    <span className="home__tab-text">Projects and Skills</span>
                  </span>
                </Tab>
                <Tab className="home__tab">
                  <span className="home__item-deparment">
                    <span className="icon icon-department" />
                    <span className="home__tab-text">Departments</span>
                  </span>
                </Tab>
              </TabList>
            </div>
            <div className="home__left-panel__copyright">
              {LABELS.COPY_RIGHT}
            </div>
          </div>

          <div className="home__right-panel">
            <TabPanel>
              <EmployeeDirectory />
            </TabPanel>
            <TabPanel>
              <Analytics />
            </TabPanel>
            <TabPanel>
              <ProjectManagement />
            </TabPanel>
            <TabPanel>
              <Departments />
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </>
  );
};

export default Home;
