import SkeletonLoader from 'components/common/SkeletonLoader';

const EmployeeDetailsLoader = () => (
  <div
    className="employee__detail__page employee__detail__page__loader"
    data-testid="employee-details-loader"
  >
    <div className="employee__detail__page__details">
      <div className="employee__detail__page__details__avatar">
        <div className="avatar">
          <SkeletonLoader
            skeletonWidth={164}
            skeletonHeight={164}
            skeletonCount={1}
          />
        </div>
      </div>
      <div className="employee__detail__page__details__layout">
        <div className="employee__detail__page__details__column">
          <span className="employee__detail__page__details__column__name">
            <SkeletonLoader
              skeletonWidth={100}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </span>
          <span className="employee__detail__page__details__column__label">
            <SkeletonLoader
              skeletonWidth={130}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </span>
          <div className="spacing_12">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={160}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div>
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={130}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div>
            <span className="employee__detail__page__details__column__timezone">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="employee__detail__page__details__column">
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="employee__detail__page__details__column">
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
        <div className="employee__detail__page__detail__page__details__column">
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
          <div className="employee__detail__page__details__column__two">
            <span className="employee__detail__page__details__column__label">
              <SkeletonLoader
                skeletonWidth={100}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
            <span className="employee__detail__page__details__column__value">
              <SkeletonLoader
                skeletonWidth={70}
                skeletonHeight={16}
                skeletonCount={1}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div className="employee__detail__page__body">
      <div className="employee__detail__page__body__sidebar">
        <div className="employee__detail__page__body__sidebar__block">
          <div className="employee__detail__page__body__sidebar__item">
            <SkeletonLoader
              skeletonWidth={20}
              skeletonHeight={16}
              skeletonCount={1}
            />
            <SkeletonLoader
              skeletonWidth={170}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </div>
          <div className="employee__detail__page__body__sidebar__item">
            <SkeletonLoader
              skeletonWidth={20}
              skeletonHeight={16}
              skeletonCount={1}
            />
            <SkeletonLoader
              skeletonWidth={170}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </div>
          <div className="employee__detail__page__body__sidebar__item">
            <SkeletonLoader
              skeletonWidth={20}
              skeletonHeight={16}
              skeletonCount={1}
            />
            <SkeletonLoader
              skeletonWidth={170}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </div>
          <div className="employee__detail__page__body__sidebar__item">
            <SkeletonLoader
              skeletonWidth={20}
              skeletonHeight={16}
              skeletonCount={1}
            />
            <SkeletonLoader
              skeletonWidth={170}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </div>
          <div className="employee__detail__page__body__sidebar__item">
            <SkeletonLoader
              skeletonWidth={20}
              skeletonHeight={16}
              skeletonCount={1}
            />
            <SkeletonLoader
              skeletonWidth={170}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </div>
          <div className="employee__detail__page__body__sidebar__item">
            <SkeletonLoader
              skeletonWidth={20}
              skeletonHeight={16}
              skeletonCount={1}
            />
            <SkeletonLoader
              skeletonWidth={170}
              skeletonHeight={16}
              skeletonCount={1}
            />
          </div>
        </div>
      </div>
      <div className="employee__detail__page__body__tabs">
        <div className="employee__detail__page__body__tablist">
          <SkeletonLoader
            skeletonWidth={100}
            skeletonHeight={12}
            skeletonCount={1}
          />
        </div>

        <div>
          <div className="skill">
            <div className="skill__base">
              <div className="skill__base__heading">
                <SkeletonLoader
                  skeletonWidth={150}
                  skeletonHeight={16}
                  skeletonCount={1}
                />
              </div>
              <div className="skill__base__content">
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="skill__base">
              <div className="skill__base__heading">
                <SkeletonLoader
                  skeletonWidth={180}
                  skeletonHeight={16}
                  skeletonCount={1}
                />
              </div>
              <div className="skill__base__content">
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="skill__base">
              <div className="skill__base__heading">
                <SkeletonLoader
                  skeletonWidth={120}
                  skeletonHeight={16}
                  skeletonCount={1}
                />
              </div>
              <div className="skill__base__content">
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="skill__base">
              <div className="skill__base__heading">
                <SkeletonLoader
                  skeletonWidth={180}
                  skeletonHeight={16}
                  skeletonCount={1}
                />
              </div>
              <div className="skill__base__content">
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="skill__base">
              <div className="skill__base__heading">
                <SkeletonLoader
                  skeletonWidth={210}
                  skeletonHeight={16}
                  skeletonCount={1}
                />
              </div>
              <div className="skill__base__content">
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
                <div className="skilltile">
                  <SkeletonLoader
                    skeletonWidth={35}
                    skeletonHeight={35}
                    skeletonCount={1}
                  />
                  <div className="skilltile__column">
                    <span className="skilltile__column__label">
                      <SkeletonLoader
                        skeletonWidth={80}
                        skeletonHeight={14}
                        skeletonCount={1}
                      />
                    </span>
                    <span className="skilltile__column__level">
                      <SkeletonLoader
                        skeletonWidth={60}
                        skeletonHeight={12}
                        skeletonCount={1}
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{/* <Staffing data={employeeProject} /> */}</div>
      </div>
    </div>
  </div>
);

export default EmployeeDetailsLoader;
