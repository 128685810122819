import { type FC } from 'react';
import { DatePickerProps } from './DatePicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

const DatePickerForm: FC<DatePickerProps> = ({
  id,
  name,
  className,
  placeholder,
  value,
  onChange,
  labelText,
  showLabel = false,
  showError = false,
  errorText,
  isRequired = false,
  disabled = false,
  dateFormat = 'MM/dd/yyyy',
}) => (
  <div id={id} className={`datepickerContainer ${className} `}>
    {showLabel && <div className="datepickerContainer__label">{labelText}</div>}
    <DatePicker
      className={`datepickerContainer__input ${showError && 'error-input'} ${
        disabled ? 'datepickerContainer__disabled' : ''
      }`}
      showIcon
      placeholderText={placeholder}
      name={name}
      selected={value}
      onChange={(date) => onChange(date)}
      required={isRequired}
      dateFormat={dateFormat}
    />
    {showError && (
      <div className="datepickerContainer__error-text">{errorText}</div>
    )}
  </div>
);

export default DatePickerForm;
