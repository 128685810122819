import './Unauthorized.scss';

const Unauthorized = () => (
  <div className="unauthorized">
    <h1>Unauthorized Access</h1>
    <p>You do not have permission to access this page.</p>
  </div>
);

export default Unauthorized;
