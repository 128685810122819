import { type FC } from 'react';
import { TabNavProps } from './TabNavType';
import { Tabs, TabList } from 'react-tabs';
import './TabNav.scss';

const TabNav: FC<TabNavProps> = ({
  isControlled = false,
  className = '',
  tabIndex,
  onTabClick,
  tabListChildren,
  tabPanelChildren,
}) => {
  if (isControlled) {
    return (
      <Tabs
        className={`${className} tabs`}
        selectedIndex={tabIndex}
        onSelect={onTabClick ? (index) => onTabClick(index) : undefined}
      >
        <TabList className="tabslist">{tabListChildren}</TabList>
        {tabPanelChildren}
      </Tabs>
    );
  } else {
    return (
      <Tabs
        className={`${className} tabs`}
        onSelect={onTabClick ? (index) => onTabClick(index) : undefined}
      >
        <TabList className="tabslist">{tabListChildren}</TabList>
        {tabPanelChildren}
      </Tabs>
    );
  }
};

export default TabNav;
