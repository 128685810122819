import { FC } from 'react';
import { StaffingProps } from './StaffingType';
import './Staffing.scss';

const Staffing: FC<StaffingProps> = ({ data }) =>
  data.length > 0 ? (
    <div className="staffing__container">
      {data.map((project, index) => (
        <div className="staffing" key={index}>
          <div className="staffing__project">
            <div className="staffing__spacing">
              <div className="staffing__spacing__heading">
                Project {index + 1} :
              </div>
            </div>
            {/* <div>
                  AM :<span> Zelewski Martin</span>
                </div> */}
            <span>
              {project.clientName} | {project?.name}
            </span>
            <div>
              PM : <span>{project.managerName}</span>
            </div>
            <div className="staffing__spacing">
              <span>Project OZ link :</span>
              <a
                href={project?.ozLink}
                target="_blank"
                rel="noreferrer"
                className="staffing__project__ozlink"
              >
                External Link
              </a>
              <span className="icon-link icon staffing__project__icon" />
            </div>
          </div>
          <div className="staffing__utilization">
            {/* <div className="staffing__utilization__heading">
              Utilisation
              <span className="staffing__utilization__heading__sub-heading">
                MTD : 100% YTD : 100% Target : 92%
              </span>
            </div> */}
            <div className="staffing__utilization__heading">
              Staffing timeline :
              <span>
                {' '}
                {project.joiningDate} to {project.leavingDate || 'Present'}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div className="notStaffed">The member is not staffed on any project</div>
  );

export default Staffing;
